import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storeLogin: {},
  permittedStores: [],
  apiErrors: "",
  responseStatus: "",
  selectedStoreId: {},
};

export const storeLoginSlice = createSlice({
  name: "storeLogin",
  initialState,
  reducers: {
    setSelectedStoreId(state, action){
      localStorage.setItem("storeId", action.payload.storeId);
      return Object.assign({}, state, {
        selectedStoreId: action.payload,
      });
    },  
    setErrors(state, action) {
      return Object.assign({}, state, {
        apiErrors: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },
    getPermittedStore() {},
    setPermittedStore(state, action) {
      return Object.assign({}, state, {
        permittedStores: action.payload,
      });
    },
    setStoreLogin(state, action) {
      return Object.assign({}, state, {
        storeLogin: action.payload,
      });
    },
    getStoreLogin() {},
    getCurrentUser() {},
    
    clearStoreLogin(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getStoreLogin,
  setStoreLogin,
  setErrors,
  getPermittedStore,
  setPermittedStore,
  setSelectedStoreId,
  getCurrentUser,
  clearStoreLogin
} = storeLoginSlice.actions;

export default storeLoginSlice.reducer;
