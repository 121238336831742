import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  selectedProduct: {},
  multiSelectProduct: [],
  productDescription: {},
  filterData: {},
  searchData: "",
  productsList: [],
  isSkipped: false,
  pageNoLibrary: 0,
  hasMore: true,
  isCounterSale: false,
  barcodeProductData: {},
  counterSaleBarcode: "",
  glassDropDown: {},
  brandDropDown: []
};

export const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    getProductListData() { },
    setProductListData(state, action) {
      return Object.assign({}, state, {
        productsList:
          state.pageNoLibrary > 0
            ? [...state.productsList, ...action.payload]
            : _.isEmpty(action.payload)
              ? []
              : action.payload,
        hasMore: action.payload.length < 20 ? false : true,
      });
    },

    setPagenoLibrary(state, action) {
      return Object.assign({}, state, {
        pageNoLibrary: action.payload,
      });
    },

    setProductData(state, action) {
      return Object.assign({}, state, {
        productDescription: _.isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getSelectedProductData() { },
    setMultiSelectedProductsData(state, action) {
      const data = action.payload.isProduct
        ? Object.assign({}, state, {
          multiSelectProduct: _.isEmpty(action.payload)
            ? []
            : [...state.multiSelectProduct, action.payload],
        })
        : Object.assign({}, state, {
          multiSelectProduct: state.multiSelectProduct.map(
            (product, index) => {
              const mergedObject =
                product.productId === action.payload.productId &&
                  index === state.multiSelectProduct.length - 1
                  ? _.merge({}, product, action.payload)
                  : product;
              return mergedObject;
            }
          ),
        });
      return data;
    },
    setSelectedProductData(state, action) {
      return Object.assign({}, state, {
        selectedProduct: _.isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setFiltersData(state, action) {
      return Object.assign({}, state, {
        filterData: _.isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setSearchData(state, action) {
      return Object.assign({}, state, {
        searchData: _.isEmpty(action.payload) ? "" : action.payload,
      });
    },

    setSkipped(state, action) {
      return Object.assign({}, state, {
        isSkipped: action.payload,
      });
    },

    setCounterSale(state, action) {
      return Object.assign({}, state, {
        isCounterSale: action.payload,
      });
    },
    setBarcodeCounterSale(state, action) {
      return Object.assign({}, state, {
        counterSaleBarcode: [...state.counterSaleBarcode, action.payload]
      });
    },
    getProductByBarcode() { },
    setProductByBarcode(state, action) {
      return Object.assign({}, state, {
        barcodeProductData: _.isEmpty(action.payload) ? {} : action.payload,
      });
    },

    getGlassDropDowns() { },
    setGlassDropDowns(state, action) {
      return Object.assign({}, state, {
        glassDropDown: _.isEmpty(action.payload) ? {} : action.payload,
      });
    },

    getBrandDropDowns() { },
    setBrandDropDowns(state, action) {
      return Object.assign({}, state, {
        brandDropDown: _.isEmpty(action.payload) ? [] : action.payload,
      });
    },

    clearLibrary(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getSelectedProductData,
  setFiltersData,
  setProductListData,
  getProductListData,
  setProductData,
  setMultiSelectedProductsData,
  setSelectedProductData,
  setSkipped,
  setReponseStatus,
  clearLibrary,
  setPagenoLibrary,
  getProductByBarcode,
  setProductByBarcode,
  setCounterSale,
  setBarcodeCounterSale,
  getGlassDropDowns,
  setGlassDropDowns,
  getBrandDropDowns,
  setBrandDropDowns,
  setSearchData
} = librarySlice.actions;

export default librarySlice.reducer;
