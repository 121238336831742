import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Box, InputAdornment } from "@mui/material";
import { Typography, CssBaseline, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../components";
import {
  getLoginData,
  isLoggedIn,
  clearLoginData,
  clearLogin
} from "../../app/reducers/loginSlice";
import { getConsumerData } from "../../app/reducers/consumerSlice";

function Login() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [disabledNext, setDisabledNext] = useState(true);
  const [mobileError, setMobileError] = useState(false);
  const [mobileErrorMessage, setMobileErrorMessage] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [existingUser, setExistingUser] = useState(false);

  const nameSelector = useSelector(
    (state) => state.userReducer.loginData.customerName
  );
  const emailSelector = useSelector(
    (state) => state.userReducer.loginData.emailId
  );
  const existingUserSelector = useSelector(
    (state) => state.userReducer.existingUser
  );

  const isFromOrder = useSelector(
    (state) => state.checkoutReducer.isFromCustomer
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    setName(nameSelector);
    setEmail(emailSelector);
    setExistingUser(existingUserSelector);
    if (existingUser) {
      setDisabled(true);
      setDisabledNext(false);
    }
  }, [nameSelector, emailSelector, existingUserSelector, existingUser]);

  const handleText = (event, name) => {
    if (name === "email") {
      setEmail(event.target.value);
    } else {
      setName(event.target.value);
    }
  };

  const getLogin = async (mobileNumber, dispatch) => {
    await dispatch(getLoginData(mobileNumber));
  };

  const handleChange = async (event) => {
    const mobileNo = event.target.value;
    setMobileNumber(mobileNo);
    const reg = new RegExp('^[0-9]*$');
    if (reg.test(mobileNo)) {
      setMobileErrorMessage("");
      if (mobileNo.length === 10) {
        dispatch(clearLoginData());
        setMobileError(false);
        await getLogin(mobileNo, dispatch);
        setDisabled(false);
        setDisabledNext(false);
      } else {
        setMobileError(true);
        setDisabled(true);
        setDisabledNext(true);
      }
    }
    else {
      setMobileError(true);
      setMobileErrorMessage(" Enter Numbers please");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updatedFormData = {
      phoneNumber: mobileNumber,
      customerName: name,
      emailId: email,
    };

    dispatch(isLoggedIn(true));
    if (isFromOrder) {
      if (existingUser) {
        history("/check-out", {
          state: { heading: "Inventory", subHeader: "Library" },
        });
      } else {
        dispatch(getConsumerData(updatedFormData));
        history("/check-out", {
          state: { heading: "Inventory", subHeader: "Library" },
        });
      }
    } else {
      if (existingUser) {
        history("/library", {
          state: { heading: "Inventory", subHeader: "Library" },
        });
      } else {
        dispatch(getConsumerData(updatedFormData));
        history("/library", {
          state: { heading: "Inventory", subHeader: "Library" },
        });
      }
    }
  };

  const handleBackButton = () => {
    dispatch(clearLogin());
    history("/orders", { state: { heading: "Sales", subHeader: "Orders" } });
  };
  return (
    <Container
      sx={{ height: "100%", position: "relative" }}
      component="main"
      maxWidth="xs"
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            color: "#03A5A5",
          }}
        >
          New Order
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="mobile"
            label="Mobile Number"
            id="mobile"
            helperText={mobileErrorMessage || "Enter your mobile number to proceed further"}
            onChange={handleChange}
            error={mobileError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="name"
            label="Consumer Name"
            id="name"
            autoComplete="name"
            value={name || ""}
            onChange={(event) => handleText(event, "name")}
            disabled={disabled}
            InputLabelProps={{ shrink: true, disableAnimation: true }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email || ""}
            onChange={(event) => handleText(event, "email")}
            disabled={disabled}
            autoFocus
            InputLabelProps={{ shrink: true, disableAnimation: true }}
          />
        </Box>
      </Box>

      <Footer
        back={isFromOrder ? "" : "Cancel"}
        handleBackButton={handleBackButton}
        onContinue={"Next"}
        handleContinueButton={handleSubmit}
        disabled={disabledNext}
      />
    </Container>
  );
}

export default Login;
