import {
  Dialog,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  DialogTitle,
  IconButton,
  DialogContent
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ContinueButton, ListTable, Sidebar, NavBar } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getExchangeData,
  getShipmentUpdate,
  confirmDelivery,
  setPagenoExchange,
  clearExchange,
} from "../../app/reducers/exchangeSlice";
import { HeadTypography, LabelTypography, useStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NewExchange from "./NewExchange";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import _ from "lodash";

function Exchange(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );
  const exchangeList = useSelector(
    (state) => state.exchangeReducer.exchangeDetails
  );
  const deliveryStatus = useSelector(
    (state) => state.exchangeReducer.deliveryStatus
  );
  const shipmentUpdate = useSelector(
    (state) => state.exchangeReducer.shipmentUpdate
  );
  const pageNo = useSelector((state) => state.exchangeReducer.pageNoExchange);
  const hasMore = useSelector((state) => state.exchangeReducer.hasMoreExchange);

  const [openSideBar, setOpenSideBar] = useState(false);
  const [exchange, setExchange] = useState("ListExchange");
  const [openShipment, setOpenShipment] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [checked, setChecked] = useState(true);
  const [deliveryComments, setDeliveryComments] = useState("");
  const [selectedExchangeData, setSelectedExchangeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentExchange, setCurrentExchange] = useState([]);
  const [comments, setComments] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(deliveryStatus)) {
      setOpenDelivery(false);
    }

    if (!_.isEmpty(shipmentUpdate)) {
      setOpenStatus(false);
    }

    if (!_.isEmpty(storeChange)) {
      dispatch(clearExchange());
      dispatch(getExchangeData({ page: 0 }));
    }
  }, [storeChange, deliveryStatus, shipmentUpdate]);

  useEffect(() => {
    setLoading(false);
  }, [exchangeList]);

  let tableData = [];

  exchangeList?.map((val) => {
    const exchangeData = {
      source: val?.source.type,
      destination: val?.destination.type,
      reason: val?.reason,
      exchangeId: val?.transferId,
      exchangeDate: moment(val?._created_at).format("ll"),
      status: val?.status,
      data: val,
      exchangeStatusIcon:
        val?.source.type === "Store" && val?.status === "Shipped"
          ? "info"
          : val?.status === "Delivered" ||
            (val?.source.type === "Warehouse" && val?.status === "Created")
            ? ""
            : "refresh",
    };
    tableData.push(exchangeData);

    return tableData;
  });

  const handleDrawerOpen = () => {
    setOpenSideBar(true);
  };

  const handleDrawerClose = () => {
    setOpenSideBar(false);
  };

  const handleBack = () => {
    setExchange("ListExchange");
  };

  const handleNewExchange = () => {
    setExchange("NewExchange");
  };
  const handleExchangeStatus = (e, data) => {
 
    data?.status === "Created"
      ? setOpenStatus(true)
      : data?.source === "Store" && data?.status === "Shipped"
        ? setOpenShipment(true)
        : setOpenDelivery(true);
    setSelectedExchangeData(data.data);
  };

  const handleCloseDelivery = () => {
    setOpenDelivery(false);
  };

  const handleDeliveryComments = (event) => {
    setDeliveryComments(event.target.value);
  };

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const handleConfirmDelivery = () => {
    const date = new Date(Date.now()).toISOString();
    const obj = {
      deliveryDetails: {
        deliveryDate: date,
      },
      transferId: selectedExchangeData?.transferId,
    };
    dispatch(confirmDelivery(obj));
  };

  const [dateValue, setDateValue] = React.useState();

  const [value, setValue] = useState({});

  const handleChange = (e, name) => {
    setValue({ ...value, [name]: e.target.value });
  };

  const handleDateChange = (newValue, name) => {
    const date = new Date(newValue).toISOString();
    setDateValue(newValue);
    setValue({ ...value, [name]: date });
  };

  const handleConfirm = () => {
    const obj = {
      shippingDetails: value,
      transferId: selectedExchangeData?.transferId,
    };
    dispatch(getShipmentUpdate(obj));
  };

  const handleClose = () => {
    setOpen(false);
    setOpenStatus(false);
    setOpenDelivery(false);
    setOpenShipment(false);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoExchange(pageNo + 1));
    dispatch(getExchangeData({ page: pageNo + 1 }));
  };

  const handleView = (e, data) => {
    const mappedValue = data.data.inventoryDetails.map(inventory => {
      return { ...inventory.barcodeDetails }
    })

    setCurrentExchange(mappedValue);
    setExchange("ViewInventory")

  };

  const handleCommentClick = (e, data) => {
    setComments(
      data.data?.shippingDetails?.comments ||
      "User did'nt add comments"
    );
    setOpen(true);
  }

  return (
    <>
      <div>
        <NavBar
          handleDrawerOpen={handleDrawerOpen}
          header={"Exchange"}
          isBack={exchange === "NewExchange" || exchange === "ViewInventory"}
          handleBack={handleBack}
        />
        <Sidebar
          handleDrawerClose={handleDrawerClose}
          open={openSideBar}
          location={location}
        />
        {exchange === 'NewExchange' && (
          <NewExchange backToExchange={handleBack} />
        )}
        {exchange === 'ListExchange' && (
          <div className={classes.content}>
            <Grid
              container
              alignItems={"center"}
              sx={{
                position: "fixed",
                backgroundColor: "#f5f5f5",
                width: "calc(100% - 20px)",
              }}
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={6}
                sx={{
                  padding: "26px 24px 0px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ContinueButton onClick={handleNewExchange}>
                  New Exchange
                </ContinueButton>
              </Grid>
            </Grid>
            <div
              style={{
                padding: "90px 24px 40px",
                height: "calc(100vh - 194px)",
                overflow: "hidden",
                backgroundColor: "#f5f5f5",
              }}
            >
              <ListTable
                dataList={tableData}
                isLoading={loading}
                handleLoadmore={handleLoadmore}
                handleView={handleView}
                hasMore={hasMore}
                handleExchangeStatus={handleExchangeStatus}
                handleCommentClick={handleCommentClick}
                Header={[
                  "Sources",
                  "Destination",
                  "Reason",
                  "Exchange ID",
                  "Exchange Date",
                  "Status",
                  "Comments",
                  "Actions"
                ]}
                tableData={[
                  { type: ["TEXT"], name: "source" },
                  { type: ["TEXT"], name: "destination" },
                  { type: ["TEXT"], name: "reason" },
                  { type: ["TEXT"], name: "exchangeId" },
                  { type: ["TEXT"], name: "exchangeDate" },
                  { type: ["EXCHANGE_STATUS"], name: "status" },
                  { type: ["COMMENTS"], name: "" },
                  { type: ["VIEW"], name: "" },
                ]}
              />
            </div>
            {open && (
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{ borderRadius: "8px" }}
                >
                  <DialogTitle sx={{ fontWeight: 650 }}>
                    Comments
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <div style={{ padding: "20px 60px 50px" }}>
                      <div style={{ textAlign: "center" }}>
                        <Typography
                          id="modal-modal-description"
                          sx={{ mt: 2, mb: 3 }}
                        >
                          Shipment Comments - {comments}
                        </Typography>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            )}
            <Dialog open={openStatus} onClose={handleClose}>
              <div className={classes?.statusExchangeDialogBox}>
                <div className={classes?.alignFlex}>
                  <HeadTypography>Update Status</HeadTypography>
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </div>
                <Grid container alignItems="center" sx={{ mt: "16px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    sx={{ pr: "3%", pb: "1.5%" }}
                  >
                    <div>
                      <LabelTypography sx={{ pb: "8px" }}>
                        SHIPPING DATE
                      </LabelTypography>
                      <DatePicker
                        selected={dateValue}
                        autoComplete="off"
                        onChange={(date) =>
                          handleDateChange(date, "shippingDate")
                        }
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Shipping Date"
                        customInput={
                          <TextField
                            fullWidth
                            type="text"
                            placeholder="Shipping Date"
                            variant="outlined"
                          />
                        }
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    sx={{ pr: "5%", pb: "1.5%" }}
                  >
                    <div>
                      <LabelTypography sx={{ pb: "8px" }}>
                        SERVICE PROVIDER
                      </LabelTypography>
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        onChange={(e) => handleChange(e, "serviceProvider")}
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    sx={{ pr: "5%", pb: "1.5%" }}
                  >
                    <div>
                      <LabelTypography sx={{ pb: "8px" }}>
                        TRACKING ID
                      </LabelTypography>
                      <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        onChange={(e) => handleChange(e, "trackingId")}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={6}>
                    <div>
                      <LabelTypography sx={{ pb: "8px" }}>
                        COMMENTS
                      </LabelTypography>
                      <TextField
                        type="text"
                        fullWidth
                        multiline
                        rows={5}
                        variant="outlined"
                        onChange={(e) => handleChange(e, "comments")}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={4} sx={{ mt: "16px" }}>
                    <ContinueButton sx={{ ml: "0px" }} onClick={handleConfirm}>
                      Confirm
                    </ContinueButton>
                  </Grid>
                </Grid>
              </div>
            </Dialog>
            <Dialog open={openShipment} onClose={handleClose}>
              <div className={classes?.shipmentDialogBox}>
                <div className={classes?.alignFlex}>
                  <HeadTypography>Shipment Info</HeadTypography>
                  <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
                </div>
                <Grid container alignItems="center" sx={{ mt: "16px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ pr: "3%", pb: "1.5%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <LabelTypography sx={{ pb: "8px" }}>
                        Shipping Date
                      </LabelTypography>
                      <LabelTypography sx={{ pb: "8px" }}>{ }</LabelTypography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <LabelTypography sx={{ pb: "8px" }}>
                        Service Provider
                      </LabelTypography>
                      <LabelTypography sx={{ pb: "8px" }}>-</LabelTypography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <LabelTypography sx={{ pb: "8px" }}>
                        Tracking ID
                      </LabelTypography>
                      <LabelTypography sx={{ pb: "8px" }}>
                        34gh23
                      </LabelTypography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <LabelTypography sx={{ pb: "8px" }}>
                        Comments
                      </LabelTypography>
                      <LabelTypography sx={{ pb: "8px" }}>-</LabelTypography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Dialog>
            <Dialog
              open={openDelivery}
              onClose={handleCloseDelivery}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: "8px" }}
            >
              <div className={classes?.deliverDialogBox}>
                <div className={classes?.alignFlex}>
                  <HeadTypography>Update Status</HeadTypography>
                  <CloseIcon
                    sx={{ cursor: "pointer" }}
                    onClick={handleCloseDelivery}
                  />
                </div>
                <div>
                  <div style={{ margin: "10px 0px" }}>
                    <FormControlLabel
                      label="Delivered"
                      control={
                        <Radio
                          checked={checked}
                          classes={{ checked: classes?.radio }}
                          value={"Delivered"}
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <Grid container>
                      <Grid item xs={12} md={10}>
                        <LabelTypography sx={{ pb: "8px" }}>
                          COMMENTS
                        </LabelTypography>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          onChange={handleDeliveryComments}
                          placeholder="Type Here..."
                          disabled={!checked}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <ContinueButton
                      style={{ marginLeft: "0px" }}
                      onClick={handleConfirmDelivery}
                    >
                      Confirm
                    </ContinueButton>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
        )}
        {exchange === 'ViewInventory' && (
          <div style={{
            padding: "70px 24px 40px",
            backgroundColor: "#f5f5f5",
          }}>
            <Typography sx={{ fontSize: "18px", fontWeight: 600, margin: "20px 0px 10px" }}>
              Transfer Product
            </Typography>
            <div style={{
              height: "calc(100vh - 194px)",
              overflow: "hidden",
              backgroundColor: "#f5f5f5",
            }}>
              <ListTable
                dataList={currentExchange}
                Header={[
                  "Barcode Id",
                  "Brand",
                  "Model",
                  "Color",
                  "Color Code",
                  "Size Actual"
                ]}
                tableData={[
                  { type: ["TEXT"], name: "barcodeId" },
                  { type: ["TEXT"], name: "Brand" },
                  { type: ["TEXT"], name: "ModelNo" },
                  { type: ["TEXT"], name: "FrameColor" },
                  { type: ["TEXT"], name: "ColorCode" },
                  { type: ["TEXT"], name: "SizeActual" }
                ]}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Exchange;
