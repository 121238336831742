import React, { useState, useEffect } from "react";
import { Footer, NavBar } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  Autocomplete,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from "@mui/material";
import {
  getConsumerData,
  setConsumerDataError,
} from "../../app/reducers/consumerSlice";
import { State, City } from "country-state-city";
import { RoutePath } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { AlertComp } from "../../components/Alert";

const totalChecks = [
  {
    header: "COMMUNICATION PREFERENCES",
    forState: "communication",
    checkBoxes: [
      { value: "SMS", label: "SMS" },
      { value: "Whatsapp", label: "Whatsapp" },
      { value: "Email", label: "Email" },
    ],
  },
  {
    header: "MEDICAL HISTORY",
    forState: "medicalHistory",
    checkBoxes: [
      { value: "BP", label: "BP" },
      { value: "Diabatic", label: "Diabatic" },
    ],
  },
];

const AddConsumer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [showAlertphone, setShowAlertphone] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessagephone, setAlertMessagephone] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const [value, setValue] = useState({});
  const history = useNavigate();

  const states = State.getStatesOfCountry("IN");
  const cities = City.getCitiesOfState("IN", selectedState);
  const consumerAdded = useSelector(
    (state) => state.consumerReducer.consumerAdded
  );

  const consumerAddedError = useSelector(
    (state) => state.consumerReducer.consumerAddedError
  );

  const handleChange = (event, incomingValue, name) => {
    if (name === "state") {
      setSelectedState(incomingValue.isoCode);
    }
    if (incomingValue) {
      setValue({ ...value, [name]: incomingValue.name });
    } else {
      setValue({ ...value, [name]: event.target.value });
    }
  };

  useEffect(() => {
  }, [value])

  useEffect(() => {
    if (!_.isEmpty(consumerAdded)) {
      history(RoutePath?.consumers, {
        state: { heading: "Services", subHeader: "Consumers" },
      });
    }
  });

  const validation = async () => {
    let isValid = false;
    setShowAlert(false);
    setShowAlertphone(false);

    if (_.isEmpty(value?.customerName)) {
      setShowAlert(true);
      setAlertMessage("Customer name is mandatory");
      isValid = false;
    } else if (_.isEmpty(value?.phoneNumber)) {
      setShowAlertphone(true);
      setAlertMessagephone("Mobile number is mandatory");
      isValid = false;
    } else if(value?.phoneNumber?.length !== 10) {
      setShowAlertphone(true);
      setAlertMessagephone("Mobile number should be 10 digit");
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = await validation();
    if (isValid) {
      dispatch(getConsumerData(value)).then();
    }
  };

  const handleBack = () => {
    history(RoutePath?.consumers, {
      state: { heading: "Services", subHeader: "Consumers" },
    });
  };

  const handlefilterCheckbox = (event, forState) => {
    const insertValue = event.target.value;
    if (_.isEmpty(value?.[forState])) {
      setValue({ ...value, [forState]: [event.target.value] });
    } else {
      if (!value?.[forState].includes(insertValue)) {
        value?.[forState].push(insertValue);
      } else {
        value?.[forState].splice(value?.[forState].indexOf(insertValue), 1);
      }
      setValue({ ...value });
    }
  };

  const [dateValue, setDateValue] = React.useState({
    dob: "",
    anniversaryDate: "",
  });

  const handleDateChange = (newValue, name) => {
    const date = new Date(newValue).toISOString();
    setDateValue({ ...dateValue, [name]: newValue });
    setValue({ ...value, [name]: date });
  };

  const handleAlertClose = () => {
    dispatch(setConsumerDataError(""));
  };

  return (
    <>
      <div style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
        {consumerAddedError ? (
          <AlertComp
            type="error"
            message={consumerAddedError}
            handleAlertClose={handleAlertClose}
          />
        ) : (
          ""
        )}
        <NavBar header={"New Consumer"} isBack={true} handleBack={handleBack} />
      </div>
      <div style={{ padding: "90px 28px" }}>
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <div>
              <Typography sx={{ pb: "8px" }}>CONSUMER NAME</Typography>
              <TextField
                fullWidth
                type="text"
                placeholder="Enter you Name"
                variant="outlined"
                error={showAlert}
                onChange={(e) => handleChange(e, "", "customerName")}
                helperText={showAlert ? alertMessage : ""}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            sx={{ pr: "5%", pb: "1.5%" }}
          >
            <div>
              <Typography sx={{ pb: "8px" }}>MOBILE NUMBER</Typography>
              <TextField
                fullWidth
                type="text"
                placeholder="Mobile Number"
                variant="outlined"
                error={showAlertphone}
                onChange={(e) => handleChange(e, "", "phoneNumber")}
                helperText={showAlertphone ? alertMessagephone : ""}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container alignItems={"center"}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <div>
              <Typography sx={{ pb: "8px" }}>EMAIL ID</Typography>
              <TextField
                fullWidth
                type="text"
                placeholder="email"
                variant="outlined"
                onChange={(e) => handleChange(e, "", "emailId")}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <div>
              <Typography sx={{ pb: "8px" }}>DOB</Typography>
              <DatePicker
                selected={dateValue?.dob}
                autoComplete="off"
                onChange={(date) => handleDateChange(date, "dob")}
                dateFormat="dd/MM/yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="DOB"
                customInput={
                  <TextField
                    fullWidth
                    type="text"
                    placeholder="Dob"
                    variant="outlined"
                  />
                }
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            sx={{ pr: "3%", pb: "1.5%" }}
          >
            <div>
              <Typography sx={{ pb: "8px" }}>ANNIVERSARY DATE</Typography>
              <DatePicker
                selected={dateValue?.anniversaryDate}
                onChange={(date) => handleDateChange(date, "anniversaryDate")}
                dateFormat="dd/MM/yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                autoComplete="off"
                dropdownMode="select"
                placeholderText="Anniversary"
                customInput={
                  <TextField
                    fullWidth
                    type="text"
                    placeholder="Dob"
                    variant="outlined"
                  />
                }
              />
            </div>
          </Grid>
        </Grid>

        <div style={{ paddingBottom: "1.5%" }}>
          <Typography sx={{ pb: "4px" }}>GENDER</Typography>
          <FormControl>
            <RadioGroup
              row
              onChange={(e) => handleChange(e, "", "gender")}
              value={value?.gender}
            >
              <FormControlLabel
                sx={{ mr: "40px" }}
                value="Male"
                control={<Radio classes={{ checked: classes?.radio }} />}
                label="Male"
              />
              <FormControlLabel
                sx={{ mr: "40px" }}
                value="Female"
                control={<Radio classes={{ checked: classes?.radio }} />}
                label="Female"
              />
              <FormControlLabel
                sx={{ mr: "40px" }}
                value="Others"
                control={<Radio classes={{ checked: classes?.radio }} />}
                label="Others"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <Grid container alignItems={"center"}>
          <Grid item xs={12} md={3} lg={3} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <Typography sx={{ pb: "8px" }}>STATE</Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                getOptionLabel={(option) => option.name}
                options={states}
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "state");
                }}
                renderInput={(params) => (
                  <TextField
                    className={classes.outline}
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3} lg={3} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <Typography sx={{ pb: "8px" }}>CITY</Typography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={cities}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "city");
                }}
                renderInput={(params) => (
                  <TextField
                    className={classes.outline}
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <Typography sx={{ pb: "8px" }}>ADDRESS</Typography>
              <TextField
                fullWidth
                type="text"
                placeholder="Address"
                variant="outlined"
                onChange={(e) => handleChange(e, "", "address")}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div>
            {totalChecks.map((item, i) => (
              <div style={{ paddingBottom: "1.5%" }}>
                <Typography sx={{ pb: "2px" }}>{item?.header}</Typography>

                <FormControl component="fieldset" variant="standard">
                  <FormGroup
                    onChange={(event) =>
                      handlefilterCheckbox(event, item?.forState)
                    }
                    row
                  >
                    {item?.checkBoxes?.map((val, i) => (
                      <FormControlLabel
                        sx={{ mr: "40px" }}
                        value={val?.value}
                        control={
                          <Checkbox
                            classes={{ checked: classes.checkBox }}
                            size="small"
                            checked={
                              _.isEmpty(value?.[item.forState])
                                ? false
                                : value?.[item?.forState].includes(val?.value)
                            }
                            name={val?.name}
                          />
                        }
                        label={val?.label}
                        classes={{ label: classes.checkBoxLabel }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </div>
            ))}
          </div>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5} lg={5}>
            <div>
              <TextField
                type="text"
                fullWidth
                multiline
                rows={5}
                placeholder="Any other medical history "
                variant="outlined"
                onChange={(e) => handleChange(e, "", "otherMedicalHistory")}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer
        back={""}
        onContinue={"Submit"}
        handleContinueButton={handleSubmit}
      />
    </>
  );
};

export default AddConsumer;
