import React from "react";
import {
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { LensDialog, LensDialogHeader } from "./style";
import { VerifyBulletIcon } from "../../../components";
import _ from "lodash";

const features = [
  "Blue Block & Anti-Glare",
  "Double-Side Anti-Glare",
  "Water & Dust Repellent",
  "UV 420 - Production",
];

const LensCatalogDialog = (props) => {
  return (
    <LensDialog
      fullScreen
      open={props.isOpen}
      onClose={props.handleClose}
    >
      <Box>
        <Grid container>
          <Grid item xs={12} md={7.5} lg={7.5} sx={{ p: "50px" }}>
            <Typography sx={{ fontWeight: 600 }}>Photo Chromatic</Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "4px 0px 22px",
              }}
            >
              <Typography>
                <CurrencyRupeeIcon style={{ fontSize: "14px" }} />
                {props?.sellPrice}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={props?.lensImage}
                alt="specs"
                width={"100%"}
                style={{ borderRadius: "6px" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3.5} lg={3.5} sx={{ p: "118px 0px 0px 40px" }}>
            <LensDialogHeader>Features</LensDialogHeader>
            {props?.features.map((val) => (
              <ListItem disableGutters>
                <VerifyBulletIcon />
                <ListItemText sx={{ pl: "14px" }} primary={val} />
              </ListItem>
            ))}
            {_.isEmpty(props?.features) ? (
              <ListItem disableGutters>
                <VerifyBulletIcon />
                <ListItemText sx={{ pl: "14px" }} primary="No features" />
              </ListItem>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={1} lg={1} sx={{ pt: "50px" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </LensDialog>
  );
};

export default LensCatalogDialog;
