import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { RoutePath } from "../../router/routes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedStoreId,
  clearStoreLogin,
} from "../../app/reducers/storeLoginSlice";
import {  ExpandMore } from "@mui/icons-material";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { clearCheckout } from "../../app/reducers/checkoutSlice";
import { clearFrame } from "../../app/reducers/frameSlice";
import { clearCatalog } from "../../app/reducers/lenscatalogSlice";
import { clearLibrary } from "../../app/reducers/librarySlice";
import { clearPrescription } from "../../app/reducers/prescriptionSlice";
import { clearCashDeposits } from "../../app/reducers/cashdepositSlice";
import { clearConsumer } from "../../app/reducers/consumerSlice";
import { clearDemo } from "../../app/reducers/demoSlice";
import { clearExchange } from "../../app/reducers/exchangeSlice";
import { clearInvoice } from "../../app/reducers/invoiceSlice";
import { clearLogin } from "../../app/reducers/loginSlice";
import { clearReminders } from "../../app/reducers/reminderSlice";
import { clearReturns } from "../../app/reducers/returnsSlice";
import { clearSales } from "../../app/reducers/salesSlice";


export default function NavBar(props) {
  const dispatch = useDispatch();
  const history = useNavigate();

  const header = props?.header;
  const path = window.location.pathname;
  const createHeader = () => {
    if (RoutePath?.library === path) {
      return "Library";
    } else if (RoutePath?.demo === path) {
      return "Demo";
    } else if (RoutePath?.sales === path) {
      return "Orders";
    } else if (RoutePath?.invoices === path) {
      return "Invoices";
    } else {
      return header;
    }
  };

  const permittedStores = useSelector(
    (state) => state.storeLoginReducer.permittedStores
  );
  const selectedStore = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );

  const [storeData, setStoreData] = useState(selectedStore);
  const [user, setUser] = useState("");

  useEffect(() => {
    createHeader();
    if (!_.isEmpty(selectedStore)) {
      setStoreData(selectedStore);
      const userName = localStorage.getItem("user-name");
      setUser(userName);
    }
  }, [selectedStore]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearReducers = async (label) => {
    dispatch(clearCheckout());
    dispatch(clearFrame());
    dispatch(clearCatalog());
    dispatch(clearLibrary());
    dispatch(clearPrescription());
    dispatch(clearCashDeposits());
    dispatch(clearConsumer());
    dispatch(clearDemo());
    dispatch(clearExchange());
    dispatch(clearInvoice());
    dispatch(clearLogin());
    dispatch(clearReminders());
    dispatch(clearReturns());
    dispatch(clearSales());
    dispatch(clearReturns());
    if(label === "logout"){
      dispatch(clearStoreLogin());
    }
  };

  
  const handleMenuItemClick = async (event, option, index) => {
    await clearReducers();
    dispatch(setSelectedStoreId(option));
    setStoreData(option);
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await clearReducers("logout");
    localStorage.removeItem("eyePlay-auth-token");
    localStorage.removeItem("eyePlay-refresh-token");
    localStorage.removeItem("user-name");
    localStorage.removeItem("storeId");
    history("/");
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#fff",
        borderBottom: "1px solid #e4e4e4",
        height: "64px",
      }}
    >
      <Toolbar>
        {props.isBack ? (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={props?.handleBack}
          >
            <ArrowBackIcon sx={{ color: "#000" }} />
          </IconButton>
        ) : (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={props?.handleDrawerOpen}
          >
            <MenuIcon sx={{ color: "#000" }} />
          </IconButton>
        )}
        <Typography
          sx={{ color: "#000", fontWeight: 600, textTransform: "capitalize" }}
        >
          {createHeader()}
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <div
          style={{
            width: "800px",
            padding: "20px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#000", fontWeight: 600 }}>
            Current Store:{" "}
          </Typography>
          <List component="nav" aria-label="Device settings">
            <ListItem
              button
              id="lock-button"
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-label="when device is locked"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickListItem}
            >
              <ListItemText
                sx={{ color: "#000", fontWeight: "bolder" }}
                primary={storeData?.storeName}
              />
              <ExpandMore sx={{ color: "#827e7e" }} />
            </ListItem>
          </List>
          <Menu
            id="lock-menu"
            sx={{ color: "#000" }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
          >
            {permittedStores.map((option, index) => (
              <MenuItem
                key={option.storeId}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, option, index)}
              >
                {option.storeName}
              </MenuItem>
            ))}
          </Menu>
          <Typography
            sx={{ color: "#000", fontWeight: 400, marginRight: "5px" }}
          >
            {user}
          </Typography>
          <PowerSettingsNewIcon
            onClick={handleLogout}
            sx={{ fill: "#03a5a5" }}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}
