import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Badge,
} from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { FilterIcon, Card, SearchIcon } from "../../components";
import {
  setProductData,
  getProductListData,
  setIsDemo,
  setPagenoDemo,
  setProductListData,
} from "../../app/reducers/demoSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FilterComponent } from "./FilterComponent";
import { withNavBars } from "../../common/hocs";
import BarScanner from "../../assets/barCode.svg";
import { AlertComp } from "../../components/Alert";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import _ from "lodash";
import { useIsMount } from "../../common/hooks/customHooks";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

const Demo = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const isMount = useIsMount();


  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );

  const filterData = useSelector((state) => state.demoReducer.filterDataDemo);
  const searchData = useSelector((state) => state.demoReducer.searchDataDemo);
  const pageNo = useSelector((state) => state.demoReducer.pageNoDemo);
  const hasMore = useSelector((state) => state.demoReducer.hasMoreDemo);

  const productsList = useSelector(
    (state) => state.demoReducer.productsListDemo
  );

  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productDataLoaded, setProductDataLoaded] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [searchStr, setSearchStr] = useState(searchData);

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }
    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    setLoading(false);
    if (!_.isEmpty(productsList)) {
      setProductDataLoaded(true);
    }
  }, [productsList]);

  useEffect(() => {
    if (!_.isEmpty(storeChange)) {
      dispatch(setPagenoDemo(0));
      dispatch(setProductListData([]));
      dispatch(setIsDemo(true));
      setLoading(true);
      if (_.isEmpty(filterData)) {
        setFilterCount(0);
      } else {
        setFilterCount(Object.keys(filterData).length);
      }
      dispatch(
        getProductListData({ page: 0, filter: filterData, search: searchStr })
      );
    }
  }, [storeChange]);

  useEffect(() => {

    if (!isMount) {
      dispatch(setPagenoDemo(0));
      dispatch(setProductListData([]));
      dispatch(setIsDemo(true));
      setLoading(true);
      if (_.isEmpty(filterData)) {
        setFilterCount(0);
      } else {
        setFilterCount(Object.keys(filterData).length);
      }
      dispatch(
        getProductListData({ page: 0, filter: filterData, search: searchStr })
      );
    }
  }, [filterData]);

  useEffect(() => {
    const timeOut = setTimeout(
      () =>
        !isMount
          ? dispatch(
              getProductListData({
                page: 0,
                filter: filterData,
                search: searchStr,
              })
            )
          : "",
      1000
    );
    return () => clearTimeout(timeOut);
  }, [searchStr]);

  const cardStyles = {
    width: "160px",
  };

  const handleSearch = (e) => {
    setSearchStr(e.target.value);
  };

  const handleFilter = () => {
    setOpenFilter(true);
  };

  const handleFilterClick = () => {
    setOpenFilter(false);
  };

  const handleDrawerClose = () => {
    setOpenFilter(false);
  };

  const handleProducts = (product) => {
    dispatch(setProductData(product));
    history("/productDescription");
  };

  /* Infinite Scroll*/

  const observer = useRef();

  const cardBottomRef = useCallback(
    (node) => {
      if (!productDataLoaded) return;
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLoading(true);
          dispatch(setPagenoDemo(pageNo + 1));
          dispatch(
            getProductListData({
              page: pageNo + 1,
              filter: filterData,
              search: searchStr,
            })
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, productDataLoaded]
  );

  return (
    <div>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
        }}
      >
        <Grid item xs={9} sx={{ padding: "12px 24px" }}>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search Brand Name/Model Number"
            fullWidth
            variant="outlined"
            value={searchStr}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon width={"16"} height={"16"} />
                </InputAdornment>
              ),
              classes: {
                root: classes.notchedOutline,
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: "35px" }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={handleFilter}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "15px",
              cursor: "pointer",
            }}
          >
            <Typography sx={{ mr: "12px", color: "#03a5a5", fontSize: "14px" }}>
              FILTER
            </Typography>
            <Badge
              badgeContent={filterCount}
              sx={{
                "& .MuiBadge-anchorOriginTopRight": {
                  color: "#fff",
                  background: "#03a5a5",
                },
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <FilterIcon />
            </Badge>
          </div>
          {/* </div> */}
        </Grid>
      </Grid>
      <div style={{ padding: "90px 24px 64px" }}>
        <Grid container spacing={2}>
          {_.isEmpty(productsList) ? (
            <div style={{ marginTop: "60px", marginLeft: "50px" }}>
              <Typography sx={{ fontSize: 20, color: "#000" }}>
                No data Found
              </Typography>
            </div>
          ) : (
            ""
          )}
          {productsList?.map((product, index) => {
            if (index === productsList.length - 1) {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  key={index}
                  ref={cardBottomRef}
                >
                  <div onClick={() => handleProducts(product)}>
                    <Card
                      lists={product}
                      width={cardStyles}
                      isCatalog={false}
                      exchange={false}
                      isDemo={true}
                    />
                  </div>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} sm={4} md={4} key={index}>
                  <div onClick={() => handleProducts(product)}>
                    <Card
                      lists={product}
                      width={cardStyles}
                      isCatalog={false}
                      exchange={false}
                      isDemo={true}
                    />
                  </div>
                </Grid>
              );
            }
          })}
        </Grid>
      </div>
      {openFilter ? (
        <FilterComponent
          open={openFilter}
          handleDrawerClose={handleDrawerClose}
          productsList={productsList}
          isDemo={true}
          handleFilterClick={handleFilterClick}
        />
      ) : (
        " "
      )}
    </div>
  );
};

export default withNavBars(Demo);
