import {
  Autocomplete,
  Dialog,
  Grid,
  TextField,
  DialogTitle,
  IconButton,
  Icon,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withNavBars } from "../../common/hocs";
import { ListTable, SearchIcon } from "../../components";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getremindersList,
  addReminderStatus,
  removeReminderStatus,
  addReminderStatusError,
  removeReminderStatusError,
  clearReminders,
} from "../../app/reducers/reminderSlice";
import CloseIcon from "@mui/icons-material/Close";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { AlertComp } from "../../components/Alert";
import moment from "moment";

const reminders = [
  { label: "48hrs", value: 48 },
  { label: "3 months", value: 3 },
  { label: "6 months", value: 6 },
  { label: "9 months", value: 9 },
];
const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

function Reminders(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );
  const reminderList = useSelector(
    (state) => state.reminderReducer.remindersList
  );
  const searchData = useSelector(
    (state) => state.reminderReducer.searchDataReminders
  );
  const reminderStatusSuccess = useSelector(
    (state) => state.reminderReducer.reminderStatusSuccess
  );
  const reminderStatusError = useSelector(
    (state) => state.reminderReducer.reminderStatusError
  );
  const removReminderStatusSuccess = useSelector(
    (state) => state.reminderReducer.removReminderStatusSuccess
  );
  const removReminderStatusError = useSelector(
    (state) => state.reminderReducer.removReminderStatusError
  );

  const [reminderDropdown, setReminderDropdown] = useState(48);
  const [openModal, setOpenModal] = useState(false);
  const [statusDetails, setStatusDetails] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [statusStr, setStatusStr] = useState("");
  const [addClicked, setAddClicked] = useState(false);
  const [searchStr, setSearchStr] = useState(searchData);

  const handleDropDownChange = (newValue) => {
    setReminderDropdown(newValue.value);
  };
  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(reminderStatusSuccess)) {
      setAddClicked(false);
      dispatch(clearReminders());
      setOpenModal(false);
      setStatusStr("");
    }
    if (!_.isEmpty(removReminderStatusSuccess)) {
      setAddClicked(false);
      dispatch(clearReminders());
      setOpenModal(false);
      setStatusStr("");
    }

    if (!_.isEmpty(storeChange)) {
      dispatch(clearReminders());
      dispatch(getremindersList(reminderDropdown));
    }
  }, [
    reminderDropdown,
    storeChange,
    reminderStatusSuccess,
    removReminderStatusSuccess,
    searchData
  ]);

   const handleStatusClick = (e, data) => {
    setOpenModal(true);
    setOrderId(data.data?.orderId);
    setStatusDetails(data?.data?.statusDetails);
  };

  const handleAdd = () => {    
    setAddClicked(true);
    let temp = [...statusDetails, ""];
    setStatusDetails(temp);
  };

  const handleDelete = (e, id) => {
    dispatch(
      removeReminderStatus({
        "_id": id
      })
    );
  };

  const handleChange = (e) => {
    setStatusStr(e.target.value);
  };

  const handleClose = () => {
    setAddClicked(false);
    setOpenModal(false);
  };

  const handleAddStatus = () => {
    dispatch(
      addReminderStatus({
        status: statusStr,
        orderId: orderId,
      })
    );
  };

  let tableData = [];

  reminderList?.map((reminder) => {
    const reminderData = {
      invoiceDate: moment(reminder?.invoiceDate).format("ll"),
      consumerName: reminder?.customerName,
      mobileNumber: reminder?.phoneNumber,
      amount: reminder?.inclGst,
      status: "",
      data: reminder,
    };
    tableData.push(reminderData);

    return reminderData;
  });

  const handleAlertClose = () => {
    dispatch(addReminderStatusError(""));
    dispatch(removeReminderStatusError(""));
  };

  return (
    <div>
      <Grid
        container
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
          zIndex: 1000,
        }}
        justifyContent="flex-end"
      >
        <Grid item xs={3} sx={{ padding: "12px 24px" }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={reminders}
            defaultValue={{ label: "48hrs", value: 48 }}
            getOptionLabel={(option) => option.label}
            sx={{ width: 180 }}
            onChange={(event, newValue) => {
              handleDropDownChange(newValue);
            }}
            size="small"
            renderInput={(params) => (
              <TextField
                className={classes.outline}
                sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                {...params}
                placeholder="Select"
              />
            )}
          />
        </Grid>
      </Grid>
      <div
        style={{
          padding: "90px 24px 40px",
          height: "calc(100vh - 200px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <ListTable
          dataList={tableData}
          handleStatusClick={handleStatusClick}
          Header={[
            "Invoice date",
            "Consumer Name",
            "Mobile Number",
            "Invoice Amount",
            "Status",
          ]}
          tableData={[
            { type: ["TEXT"], name: "invoiceDate" },
            { type: ["TEXT"], name: "consumerName" },
            { type: ["TEXT"], name: "mobileNumber" },
            { type: ["TEXT"], name: "amount" },
            { type: ["REMIND_STATUS"], name: "" },
          ]}
        />
      </div>
      {openModal ? (
        <div>
          <Dialog
            open={openModal}
            maxWidth
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ borderRadius: "8px", minHeight: "450px", minWidth: "700px" }}
          >
            {reminderStatusError ? (
              <AlertComp
                type="error"
                message={reminderStatusError}
                handleAlertClose={handleAlertClose}
              />
            ) : (
              ""
            )}
            {removReminderStatusError ? (
              <AlertComp
                type="error"
                message={removReminderStatusError}
                handleAlertClose={handleAlertClose}
              />
            ) : (
              ""
            )}
            <DialogTitle sx={{ fontWeight: 650 }}>
              Comments
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div style={{ padding: "20px 60px 0px" }}>
                {statusDetails?.map((status) => {
                  if (_.isEmpty(status)) {
                    return (
                      <div
                        style={{
                          margin: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <TextField
                          fullWidth
                          value={statusStr}
                          onChange={handleChange}
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "25px",
                          }}
                          placeholder="type here ..."
                        />
                        <IconButton
                          aria-label="close"
                          onClick={handleAddStatus}
                        >
                          <Typography sx={{ color: "#03a5a5" }}>Add</Typography>
                        </IconButton>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          margin: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <TextField
                          fullWidth
                          disabled
                          value={status.status}
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "25px",
                          }}
                          placeholder="type here ..."
                        />
                        <IconButton
                          aria-label="close"
                          onClick={(e) => handleDelete(e, status?._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    );
                  }
                })}
                <IconButton
                  aria-label="close"
                  disabled={addClicked}
                  onClick={handleAdd}
                  sx={{
                    position: "absolute",
                    right: 8,
                    bottom: 8,
                  }}
                >
                  <Icon sx={{ color: "#03a5a5" }}>add_circle</Icon>
                </IconButton> 
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        " "
      )}
    </div>
  );
}

export default withNavBars(Reminders);
