import { Drawer,Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const HeadTypography = styled(Typography)(({theme}) => ({
    fontSize:"14px",
    fontWeight:600
}))

export const LabelTypography = styled(Typography)(({theme}) => ({
    fontSize:"12px",
}))


export const LensDialog = styled(Drawer)(({ theme }) => ({
    "& .MuiDrawer-paper":{
        borderRadius:"30px 30px 0px 0px",
        marginTop:"70px"
    },
}));

export const LensDialogHeader = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#03a5a5",
}));

export const useStyles = makeStyles({
    lensDialogContents : {
        color:"#03a5a5",
        fontWeight:600
    },
    alignFlex:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        padding: "20px"
    },
    notchedOutline: {
        borderRadius: "35px !important",
    },
    statusDialogBox:{
        padding:"20px 24px",
        height: "150px",
        width: "200px"
    },
    statusExchangeDialogBox:{
        padding:"20px 24px",
    },
    shipmentDialogBox:{
        padding:"20px",
        width: "70%",
        height: "450px"
    },
    deliverDialogBox:{
        padding:"20px 24px",
        width: "330px"
    },
    radio:{
        color:"#03a5a5 !important"
    },
      content: {
        width: '100%',
        height: 'calc(100vh - 64px)',
        overflow: "auto",
      }
})
