import { createSlice } from "@reduxjs/toolkit";
import _,{ isEmpty } from "lodash";

const initialState = {
  returnsList: [],
  orderData: [],
  returnsAdded: {},
  shipmentAdded: {},
  deliveryConfirmed: {},
  qualitySaved: {},
  pageNoReturns: 0,
  hasMoreReturns: true,
  searchDataReturns: ""
};

export const returnsSlice = createSlice({
  name: "returns",
  initialState,
  reducers: {
    getFilteredOrder() {},
    setFilteredOrder(state, action) {
      return Object.assign({}, state, {
        orderData: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getReturnsList() {},
    setReturnsList(state, action) {
      return Object.assign({}, state, {
        returnsList:
        state.pageNoReturns > 0
          ? [...state.returnsList, ...action.payload]
          : isEmpty(action.payload)
          ? []
          : action.payload,
          hasMoreReturns: action.payload.length < 15 ? false : true,
      });
    },  
    setPagenoReturns(state, action) {
      return Object.assign({}, state, {
        pageNoReturns: action.payload.page,
      });
    },



    getNewReturns() {},
    setNewReturns(state, action) {
      return Object.assign({}, state, {
        returnsAdded: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    confirmDelivery() {},
    setConfirmDelivery(state, action) {
      return Object.assign({}, state, {
        deliveryConfirmed: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    qualitySave() {},
    setQualitySave(state, action) {
      return Object.assign({}, state, {
        qualitySaved: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    getSaveShipment() {},
    setSaveShipment(state, action) {
      return Object.assign({}, state, {
        shipmentAdded: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setSearchDataReturns(state, action) {
      return Object.assign({}, state, {
        searchDataReturns: _.isEmpty(action.payload) ? "" : action.payload
      });
    },

    clearReturns(state, action){
      return Object.assign({}, state, initialState);
    }
  },
});

export const {
  setReturnsList,
  getReturnsList,
  setFilteredOrder,
  getFilteredOrder,
  getNewReturns,
  setNewReturns,
  confirmDelivery,
  setConfirmDelivery,
  qualitySave,
  setQualitySave,
  setSaveShipment,
  getSaveShipment,
  clearReturns,
  setPagenoReturns,
  setSearchDataReturns
} = returnsSlice.actions;

export default returnsSlice.reducer;
