import { spawn } from 'redux-saga/effects';
import { librarySaga } from './librarySaga';
import { loginSaga } from './loginSaga';
import { consumerSaga } from './consumerSaga';
import { demoSaga } from './demoSaga';
import { lenscatalogSaga } from './lenscatalogSaga';
import { checkoutSaga } from './checkoutSaga';
import { salesSaga } from './salesSaga';
import { invoiceSaga } from './invoiceSaga';
import { exchangeSaga } from './exchangeSaga';
import { returnsSaga } from './returnsSaga';
import { reminderSaga } from './reminderSaga';
import { storeLoginSaga } from './storeLoginSaga';
import { cashdepositSaga } from './cashdepositSaga';
import { transactionsSaga } from './transactionsSaga';
import { reportSaga } from './reportSaga';
import { b2bInvoiceSaga } from './b2bInvoiceSaga';

export default function* rootSaga() {
    yield spawn(loginSaga);    
    yield spawn(librarySaga);
    yield spawn(consumerSaga);
    yield spawn(lenscatalogSaga);
    yield spawn(demoSaga);
    yield spawn(checkoutSaga);
    yield spawn(salesSaga);
    yield spawn(invoiceSaga);
    yield spawn(exchangeSaga);
    yield spawn(returnsSaga);
    yield spawn(reminderSaga);
    yield spawn(storeLoginSaga);
    yield spawn(cashdepositSaga);
    yield spawn(transactionsSaga);
    yield spawn(reportSaga);
    yield spawn(b2bInvoiceSaga);
  }