import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RoutePath } from "./routes";
import { Library } from "../features/Inventory";
import { Demo } from "../features/Inventory";
import { BarcodeScanner } from "../features/Inventory";
import { NotFound } from "../features/notFound";
import { Login } from "../features/Login";
import { 
  ProductDescription,
  AddPrescription,
  LensCatalog,
  FrameOrientation,
  Checkout,
  AddConsumer,
  Sales,
  Invoices,
  Returns,
  Reminders,
  Consumers,
  CashDeposits,
  AddDeposit, 
  StoreLogin,
  Transactions,
  Reports,
  Eodv2,
  ListInvoices
} from "../features";
import Exchange from "../features/Inventory/Exchange";
import OrderCheckout from "../features/Sales/OrderCheckout";
import CounterSale from "../features/Inventory/CounterSales";
   
function RouterApp() {
  return (
    <Router>
      <Routes>
        <Route path={RoutePath.login} element={<Login />} />
        <Route path={RoutePath.demo} element={<Demo />} />
        <Route path={RoutePath.library} element={<Library />} />
        <Route path={RoutePath.product} element={<ProductDescription />} />
        <Route path={RoutePath.addPrescription} element={<AddPrescription />} />
        <Route path={RoutePath.lensCatalog} element={<LensCatalog />} />
        <Route path={RoutePath.sales} element={<Sales />} />
        <Route path={RoutePath.frameOrientation} element={<FrameOrientation />} />
        <Route path={RoutePath.checkOut} element={<Checkout />} />
        <Route path={RoutePath.addConsumer} element={<AddConsumer />} />
        <Route path={RoutePath.err} element={<NotFound />} />
        <Route path={RoutePath.invoices} element={<Invoices />} />
        <Route path={RoutePath.exchange} element={<Exchange />} />
        <Route path={RoutePath.returns} element={<Returns />} />
        <Route path={RoutePath.reminders} element={<Reminders />} />
        <Route path={RoutePath.consumers} element={<Consumers />} />
        <Route path={RoutePath.newConsumer} element={<AddConsumer />} />
        <Route path={RoutePath.scan} element={<BarcodeScanner />} />
        <Route path={RoutePath.deposits} element={<CashDeposits />} />
        <Route path={RoutePath.addDeposit} element={<AddDeposit />} />
        <Route path={RoutePath.viewOrder} element={<OrderCheckout />} />
        <Route exact path={RoutePath.storeLogin} element={<StoreLogin />} />
        <Route exact path={RoutePath.counterSale} element={<CounterSale />} />
        <Route exact path={RoutePath.transactions} element={<Transactions />} />
        <Route exact path={RoutePath.reports} element={<Reports />} />
        <Route exact path={RoutePath.eodv2} element={<Eodv2 />} />
        <Route exact path={RoutePath.b2bInvoices} element={<ListInvoices />} />
      </Routes>
    </Router>
  );
}

export default RouterApp;
