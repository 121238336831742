import React from "react";

export const VerifyBulletIcon = (props) => {
    const color = props?.color ?? "#03a5a5";
    const width = props?.width ?? "16";
    const height = props?.height ?? "16";
  return (
    <svg
      data-name="vuesax/bold/verify"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16.502 16.502"
    >
      <path
        d="m13.446 6.011-.935-1.086a1.524 1.524 0 0 1-.323-.866V2.89a1.332 1.332 0 0 0-1.328-1.327H9.691a1.542 1.542 0 0 1-.866-.323L7.739.3a1.386 1.386 0 0 0-1.733 0l-1.079.942a1.586 1.586 0 0 1-.866.316h-1.19A1.332 1.332 0 0 0 1.544 2.89v1.175a1.565 1.565 0 0 1-.316.859L.3 6.018a1.384 1.384 0 0 0 0 1.719l.927 1.093a1.565 1.565 0 0 1 .316.859v1.176a1.332 1.332 0 0 0 1.327 1.327h1.19a1.542 1.542 0 0 1 .866.323l1.086.935a1.386 1.386 0 0 0 1.733 0l1.086-.935a1.524 1.524 0 0 1 .866-.323h1.169a1.332 1.332 0 0 0 1.327-1.327V9.7a1.542 1.542 0 0 1 .323-.866l.935-1.086a1.4 1.4 0 0 0-.005-1.737zm-3.713-.433L6.412 8.9a.515.515 0 0 1-.729 0L4.019 7.235a.515.515 0 0 1 .729-.729l1.3 1.3L9 4.849a.515.515 0 1 1 .729.729z"
        transform="translate(1.379 1.373)"
        // style="fill:#03a5a5"
        fill={color}
      />
    </svg>
  );
};
