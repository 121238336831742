import { makeStyles } from '@mui/styles';

export const useStyles =  makeStyles({
    radio:{
        color:"#03a5a5 !important"
    },
    checkBox: {
        color: "#03a5a5 !important",
    },
    checkBoxLabel: {
        fontSize: "14px !important",
      },
})