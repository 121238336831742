import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from 'lodash';

const initialState = {
    frameSize: {}
};

export const frameSlice = createSlice({
  name: "frame",
  initialState,
  reducers: {
    getFrame() {},
    setFrame(state, action) {
        return Object.assign({}, state, {
            frameSize: isEmpty(action.payload) ? {} : action.payload
      });
    },
    clearFrame(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const { getFrame, setFrame, clearFrame } = frameSlice.actions;

export default frameSlice.reducer;
