import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const HeadTypography = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: 600
}))

export const LabelTypography = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
}))

export const useStyles = makeStyles({
    alignFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    notchedOutline: {
        borderRadius: "35px !important",
    },
    statusDialogBox: {
        padding: "20px 24px",
        height: "150px",
        width: "200px"
    },
    statusExchangeDialogBox: {
        padding: "20px 24px",
    },
    shipmentDialogBox: {
        padding: "20px 24px",
        width: "280px"
    },
    deliverDialogBox: {
        padding: "20px 24px",
        width: "330px"
    },
    radio: {
        color: "#03a5a5 !important"
    },
    content: {
        width: '100%',
        height: 'calc(100vh - 64px)',
        overflow: "auto",
    },
    alignItems: {
        display: "flex", alignItems: "center"
    },
    box: {
        width: "200px",
        border: "1px solid #eaeaea",
        borderRadius: "5px",
        marginRight: "23px",
        padding: "20px 0px 20px 20px",
    },
    root: {
        padding: "80px 24px 40px 26px",
        height: "calc(100vh - 2%)",
        overflow: "hidden",
    },
    alignEntireFlex:{
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-start"
    },
})

