import React, { useEffect, useState } from "react";
import { Footer, NavBar } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { RoutePath } from "../../router/routes";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from "react-dropzone";
import S3 from "react-aws-s3";
import _ from "lodash";
import { saveCashDeposit, saveCashDepositError } from "../../app/reducers/cashdepositSlice";
import { AlertComp } from "../../components/Alert";
window.Buffer = window.Buffer || require("buffer").Buffer;

const AddDeposit = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [value, setValue] = useState({});
  const [error, setError] = useState("");

  const handleChange = (e, name) => {
    setValue({ ...value, [name]: e.target.value });
  };

  const savedCashDeposit = useSelector(
    (state) => state.cashdepositReducer.savedCashDeposit
  );

  const savedCashDepositError = useSelector(
    (state) => state.cashdepositReducer.savedCashDepositError
  );

  useEffect(() => {
    if (!_.isEmpty(savedCashDeposit)) {
      history(RoutePath?.deposits, {
        state: { heading: "Cash Deposits", subHeader: "Cash Deposits" },
      });
    }
  }, [savedCashDeposit]);

  const config = {
    bucketName: process.env.REACT_APP_AWS_BUCKET,//"oicappsv3-dev",
    dirName: "eyeplay",
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };

  const ReactS3Client = new S3(config);

  const uploadToS3 = async () => {
    const rand = Math.random();
    return await ReactS3Client.uploadFile(
      value?.depositImage,
      `Store/cashdeposits/${value?.depositAmount}${rand}`
    );
  };

  const imageUpload = async () => {
    let obj = {};
    const data = await uploadToS3();
    Object.assign(obj, { receiptImage: data.location });
    return obj;
  };

  const validation = async () => {
    let isValid = false;
    if (
      _.isEmpty(value?.depositDate) ||
      _.isEmpty(value?.depositAmount) ||
      _.isEmpty(value?.depositImage)
    ) {
      setError("Please fill out mandatory fields");
      isValid = false;
    } else {
      setError("");
      isValid = true;
    }
    return isValid;
  };

  const handleSubmit = async () => {
    const valid = await validation();
    if (valid) {
      const data = await imageUpload();
      const storeId = localStorage.getItem("storeId");
      const obj = {
        depositDate: value?.depositDate,
        amount: value?.depositAmount,
        storeId: storeId,
        ...data,
      };
      dispatch(saveCashDeposit(obj));
    }
  };

  const handleBack = () => {
    history(RoutePath?.deposits, {
      state: { heading: "Cash Deposits", subHeader: "Cash Deposits" },
    });
  };

  const handleImageChange = (name, files) => {
    const fileWithPreview = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setValue({ ...value, [name]: fileWithPreview[0] });
  };

  const [dateValue, setDateValue] = React.useState({});

  const handleDateChange = (newValue, name) => {
    const date = new Date(newValue).toISOString();
    setDateValue({ ...dateValue, [name]: newValue });
    setValue({ ...value, [name]: date });
  };

  const handleAlertClose = () => {
    dispatch(saveCashDepositError(""));
  }
  return (
    <>
      <div style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
      {savedCashDepositError ? (
          <AlertComp
            type="error"
            message={savedCashDepositError}
            handleAlertClose={handleAlertClose}
          />
        ) : (
          ""
        )}
        <NavBar header={"New Deposit"} isBack={true} handleBack={handleBack} />
      </div>
      <div style={{ padding: "90px 28px" }}>
        <Grid container alignItems="center">
          <Grid item xs={3} sm={3} md={3} ld={3}></Grid>
          <Grid item xs={3} sm={6} md={6} ld={6} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <Typography sx={{ pb: "8px" }}>Deposit Date</Typography>
              <DatePicker
                selected={dateValue?.depositDate}
                onChange={(date) => handleDateChange(date, "depositDate")}
                dateFormat="dd/MM/yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="Deposit Date"
                customInput={
                  <TextField
                    fullWidth
                    type="text"
                    placeholder="Deposit Date"
                    variant="outlined"
                  />
                }
              />
            </div>
          </Grid>
          <Grid item xs={3} sm={3} md={3} ld={3}></Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={3} sm={3} md={3} ld={3}></Grid>
          <Grid item xs={3} sm={6} md={6} ld={6} sx={{ pr: "3%", pb: "1.5%" }}>
            <div>
              <Typography sx={{ pb: "8px" }}>Deposit Amount</Typography>
              <TextField
                fullWidth
                type="text"
                placeholder="Deposit Amount"
                variant="outlined"
                onChange={(e) => handleChange(e, "depositAmount")}
              />
            </div>
          </Grid>
          <Grid item xs={3} sm={3} md={3} ld={3}></Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={3} sm={3} md={3} ld={3}></Grid>
          <Grid item xs={3} sm={6} md={6} ld={6} sx={{ pr: "5%", pb: "1.5%" }}>
            <div
              style={{
                borderRadius: "3px",
                border: "solid 1px #d8d8d8",
                backgroundColor: "#fafafa",
                padding: "20px 23px 57px 33px",
              }}
            >
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleImageChange("depositImage", acceptedFiles);
                }}
                name="thumbNail"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <span style={{ fontSize: ".8rem" }}>
                      Drop Thumbnail image here, or click to select file
                    </span>
                  </div>
                )}
              </Dropzone>
              <div>
                <img
                  style={{
                    width: "220px",
                    height: "150px",
                    margin: "0",
                    display: "block",
                  }}
                  src={value?.depositImage?.preview}
                  alt="Thumbnail"
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={3} sm={3} md={3} ld={3}></Grid>
        </Grid>
      </div>
      <Footer
        back={""}
        onContinue={"Submit"}
        handleContinueButton={handleSubmit}
      />
    </>
  );
};

export default AddDeposit;
