import React from "react";
import Gallery from "react-amazon-gallery";
import _ from "lodash";

const ProductImagesComponent = (props) => {
  const imageUrls = _.isEmpty(props?.images) ? 
  "https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2FtZXJhJTIwZm9jdXN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
  : props?.images;


  return(
  <div style={{position:"relative"}}>
    <Gallery images={imageUrls} height={250} style={{ marginLeft: "30px" }} width={"100%"} />
  </div>
);
}

export default ProductImagesComponent;
