import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { BoxOutline, FlashOutline, RupeeOutline, StatusOutline } from "../svg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../router/routes";
import EyePlayLogo from "../../assets/storeLogo.svg";
import _ from "lodash";

const sideNavbarConfig = [
  {
    icon: <BoxOutline />,
    selectedIcon: <BoxOutline color="#03a5a5" />,
    header: "Inventory",
    subHeader: [
      { title: "Demo", route: RoutePath?.demo },
      { title: "Library", route: RoutePath?.library },
      { title: "Exchange", route: RoutePath?.exchange },
    ],
  },
  {
    icon: <StatusOutline />,
    selectedIcon: <StatusOutline color="#03a5a5" />,
    header: "Sales",
    subHeader: [
      { title: "Orders", route: RoutePath?.sales },
      { title: "Invoices", route: RoutePath?.invoices },
      { title: "Returns", route: RoutePath?.returns },
    ],
  },
  {
    icon: <FlashOutline />,
    selectedIcon: <FlashOutline color="#03a5a5" />,
    header: "Services",
    subHeader: [
      { title: "Reminders", route: RoutePath?.reminders },
      { title: "Consumers", route: RoutePath?.consumers },
    ],
  },
  {
    icon: <RupeeOutline />,
    selectedIcon: <RupeeOutline color="#03a5a5" />,
    header: "Cash Deposits",
    route: RoutePath?.deposits,
    title: "Cash Deposits",
  },
  {
    icon: <RupeeOutline />,
    selectedIcon: <RupeeOutline color="#03a5a5" />,
    header: "Transactions",
    route: RoutePath?.transactions,
    title: "Transactions",
  },
  {
    icon: <RupeeOutline />,
    selectedIcon: <RupeeOutline color="#03a5a5" />,
    header: "Reports",
    route: RoutePath?.reports,
    title: "Reports",
  },
  {
    icon: <RupeeOutline />,
    selectedIcon: <RupeeOutline color="#03a5a5" />,
    header: "Eod Report",
    route: RoutePath?.eodv2,
    title: "EOD Report",
  },
  {
    icon: <RupeeOutline />,
    selectedIcon: <RupeeOutline color="#03a5a5" />,
    header: "B2B Invoices",
    route: RoutePath?.b2bInvoices,
    title: "B2B Invoices",
  },
];

const useStyles = makeStyles({
  drawerheader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  route: {
    textDecoration: "none",
  },
});

function Sidebar(props) {
  const classes = useStyles();
  const history = useNavigate();

  const [open, setOpen] = React.useState();

  const handleClick = (i, name, headers) => {
    setOpen(name);
    if (_.isEmpty(headers?.subHeader)) {
      history(headers?.route, { state: { heading: headers?.header, subHeader: headers?.title } });
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/library" || path === "/demo" || path === "/exchange") {
      setOpen("Inventory");
    } else if (
      path === "/orders" ||
      path === "/invoices" ||
      path === "/returns"
    ) {
      setOpen("Sales");
    } else if (path === RoutePath?.reminders || path === RoutePath?.consumers) {
      setOpen("Services");
    }
    else if (path === RoutePath?.deposits) {
      setOpen("Cash Deposits");
    }
    else if (path === RoutePath?.transactions) {
      setOpen("Transactions");
    }
    else if (path === RoutePath?.reports) {
      setOpen("Reports");
    } else if (path === RoutePath?.eodv2) {
      setOpen("Eod Report");
    }
  }, []);

  const handleSubHeader = (i, route, subHeader, header) => {
    if (route) {
      history(route, {
        state: { heading: header, subHeader: subHeader?.title },
      });
    }
  };

  return (
    <div>
      <Drawer
        anchor={"left"}
        open={props?.open}
        onClose={props.handleDrawerClose}
      >
        <div style={{ width: 280 }}>
          <div className={classes.drawerheader}>
            {/* <Typography sx={{ color: "#000", fontWeight: 800 }}>OP</Typography> */}
            <div>
              <img src={EyePlayLogo} />
            </div>
            <IconButton size="small" onClick={props.handleDrawerClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <Divider />
          <div style={{ cursor: "pointer" }}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {sideNavbarConfig.map((val, i) => (
                <div key={i}>
                  <ListItemButton
                    onClick={() => handleClick(i, val?.header, val)}
                  >
                    <ListItemIcon sx={{ minWidth: "40px" }}>
                      {props?.location?.state?.heading === val?.header
                        ? val?.selectedIcon
                        : val?.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color:
                          props?.location?.state?.heading === val?.header
                            ? "#03a5a5"
                            : "#827e7e",
                      }}
                    >
                      {val?.header}
                    </ListItemText>
                    {val?.subHeader ? (
                      open === val?.header ? (
                        <ExpandLess sx={{ color: "#827e7e" }} />
                      ) : (
                        <ExpandMore sx={{ color: "#827e7e" }} />
                      )
                    ) : null}
                  </ListItemButton>
                  {val?.subHeader && (
                    <Collapse in={open === val?.header} unmountOnExit>
                      <List component="div" disablePadding>
                        {val?.subHeader.map((item, i) => (
                          <ListItemButton
                            key={i}
                            sx={{ padding: "0px 16px 8px 80px" }}
                            onClick={() =>
                              handleSubHeader(i, item?.route, item, val?.header)
                            }
                          >
                            <ListItemText
                              sx={{
                                color:
                                  props?.location?.state?.subHeader ===
                                  item.title
                                    ? "#000"
                                    : "#827e7e",
                              }}
                            >
                              {item.title}
                            </ListItemText>
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </div>
              ))}
            </List>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Sidebar;
