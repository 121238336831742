import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  reportList: [],
  reportListError: "",
  pageNoTransactions: 0,
  hasMoreTransactions: true
};

const initialStatev2 = {
  reponse: {},
  error: ""
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    getReportData() {},
    getReportDataSuccess(state, action) {
      return Object.assign({}, state, {
        reportList: state.pageNoTransactions > 0 ? [...state.reportList, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getReportDataError(state, action) {
      return Object.assign({}, state, {
        reportListError: action.payload,
      });
    }
  },
});

export const reportSlicev2 = createSlice({
  name: "report",
  initialState: initialStatev2,
  reducers: {
    getReportDatav2() {},
    getReportDataSuccessv2(state, action) {
      return Object.assign({}, state, {
        response: action.payload,
      }); 
    },
    getReportDataErrorv2(state, action) {
      return Object.assign({}, state, {
        error: action.payload,
      });
    },
  },
});

export const {
    getReportData,
    getReportDataSuccess,
    getReportDataError,
} = reportSlice.actions;

export const {
  getReportDatav2,
  getReportDataSuccessv2,
  getReportDataErrorv2,
} = reportSlicev2.actions;

export const reportReducer = reportSlice.reducer
export const reportReducerv2 = reportSlicev2.reducer