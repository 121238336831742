import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getReturnsList,
  setReturnsList,
  getFilteredOrder,
  setFilteredOrder,
  getNewReturns,
  setNewReturns,
  confirmDelivery,
  setConfirmDelivery,
  qualitySave,
  setQualitySave,
  getSaveShipment,
  setSaveShipment
} from "../reducers/returnsSlice";
import { API_URL } from "../../utils/constants";

function fetchReturnsListAPI(payload) {
  const { page, search } = payload;
  const storeId = localStorage.getItem("storeId");
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/returns/returnsstore`;
  const returns = axios
    .get(apiURL, {
      headers: headers,
      params: {
        limit: 15,
        page: page,
        search: search,
        storeId: storeId,
        filter: {},
        sort: { _created_at: -1 },
      },
    })
    .then((response) => response.data.data);
  return returns;
}
export function* fetchReturnsList(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchReturnsListAPI, payload);
    yield put(setReturnsList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

function fetchOrdersListAPI(payload) {
  //   const { page } = payload;
  const storeId = localStorage.getItem("storeId");
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/storeorder/orderid`;
  const products = axios
    .get(apiURL, {
      headers: headers,
      params: {
        orderId: payload
      },
    })
    .then((response) => response.data.data);
  return products;
}
export function* fetchOrderList(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchOrdersListAPI, payload);
    yield put(setFilteredOrder(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

function saveReturnsAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/returns/createreturns`;
  const returns = axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response.data.data);
  return returns;
}
export function* saveReturns(action) {
  try {
    const { payload } = action;
    const response = yield call(saveReturnsAPI, payload);
    yield put(setNewReturns(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

function deliveryConfirmationAPI(payload) {
  
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/returns/updatedeliverystatus`;
  const products = axios
    .post(apiURL,payload, { headers: headers })
    .then((response) => response.data.data);
  return products;
}

export function* deliveryConfirmation(action) {
  try {
    const { payload } = action;
    const response = yield call(deliveryConfirmationAPI, payload);
    yield put(setConfirmDelivery(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

function qualityCheckAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/returns/commonstatus`;
  const products = axios
    .post(apiURL, payload, {headers: headers})
    .then((response) => response.data.data);
  return products;
}

export function* qualityCheck(action) {
  try {
    const { payload } = action;
    const response =  yield call(qualityCheckAPI, payload);
    yield put(setQualitySave(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

function saveShipmentAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/returns/updateshippingstatus`;
  const products = axios
    .post(apiURL, payload, {headers: headers}, )
    .then((response) => response.data.data);
  return products;
}

export function* saveShipment(action) {
  try {
    const { payload } = action;
    const response = yield call(saveShipmentAPI, payload);
    yield put(setSaveShipment(response))
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* returnsSaga() {
  yield takeLatest(getReturnsList.type, fetchReturnsList);
  yield takeLatest(getFilteredOrder.type, fetchOrderList);
  yield takeLatest(getNewReturns.type, saveReturns);
  yield takeLatest(confirmDelivery.type, deliveryConfirmation);
  yield takeLatest(qualitySave.type, qualityCheck);
  yield takeLatest(getSaveShipment.type, saveShipment);
}
