import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';


export const BackButton = styled(Button)(({theme}) => ({
    backgroundColor:"#fff",
    color:"#a0a0a0 ",
    border:"1px solid #a0a0a0",
    textTransform:"none",
    borderRadius:"25px",
    padding:"8px 35px"
}))

export const ContinueButton = styled(Button)((props) => ({
    backgroundColor:"#03a5a5",
    color:"#fff",
    textTransform:"none",
    borderRadius:"25px",
    padding:"8px 35px",
    '&:hover': {
        backgroundColor: '#75c9c9',
        boxShadow: 'none',
    },
    marginLeft:"12px",    
    disabled: props.disabled,
    ...props?.style
}))

export const ContinueShoppingButton = styled(Button)((props) => ({
    backgroundColor:"#fff",
    color:"#03a5a5",
    textTransform:"none",
    borderRadius:"25px",
    border: "2px solid #03a5a5",
    padding:"8px 35px",
    '&:hover': {
        backgroundColor: '#75c9c9',
        boxShadow: 'none',
    },
    marginLeft:"12px",    
}))

export const RandomButton = styled(Button)((props) => ({
    backgroundColor:"#03a5a5",
    color:"#fff",
    textTransform:"none",
    borderRadius:"25px",
    padding:"8px 35px",
    '&:hover': {
        backgroundColor: '#75c9c9',
        boxShadow: 'none',
    },
    disabled: props.disabled
}))

export const SubLabelTypography = styled(Typography)((props) => ({
    color:props.color ? props.color : "#827e7e",
    fontSize: "13px",
    margin: props.margin ? props.margin : "0px"
}))

export const StyledTypography = styled(Typography)((props) => ({
    color: props.color ? props.color : "#827e7e",
    fontSize: props.size ? props.size : "13px",
    fontWeight: props.weight ? props.weight : 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: props.height ? props.height : 1.88,
    letterSpacing: props.letter ? props.letter : "0.8px",
    textAlign: "left"   
}))