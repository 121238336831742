import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { withNavBars } from "../../common/hocs";
import { ContinueButton, ListTable, SearchIcon } from "../../components";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSales,
  getOrderList,
  setPagenoSales,
  setSearchDataSales,
} from "../../app/reducers/salesSlice";
import { Footer, NavBar, Sidebar } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import OrderCheckout from "./OrderCheckout";
import moment from "moment";
import { useIsMount } from "../../common/hooks/customHooks";
import { RoutePath } from "../../router/routes";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import _ from "lodash";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
  content: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
  },
});

function Sales(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const isMount = useIsMount();

  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );
  const orderList = useSelector((state) => state.salesReducer.orderList);
  const pageNo = useSelector((state) => state.salesReducer.pageNoSales);
  const hasMore = useSelector((state) => state.salesReducer.hasMoreSales);
  const searchData = useSelector((state) => state.salesReducer.searchDataSales);
  const closeOrderSuccessMessage = useSelector(
    (state) => state.salesReducer.closeOrderSuccessMessage
  );

  const [open, setOpen] = useState(false);
  const [openOrderCheckout, setOpenOrderCheckout] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [comments, setComments] = useState("");
  const [openSideBar, setOpenSideBar] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(closeOrderSuccessMessage)) {
      dispatch(clearSales());
      dispatch(getOrderList({ page: 0, search: searchStr }));
    }
  }, [closeOrderSuccessMessage]);

  useEffect(() => {
    if (!_.isEmpty(storeChange)) {
      dispatch(clearSales());
      dispatch(getOrderList({ page: 0, search: searchStr }));
    }
  }, [storeChange]);

  useEffect(() => {
    setLoading(false);
  }, [orderList]);

  useEffect(() => {
    const timeOut = setTimeout(
      () =>
        !isMount ? dispatch(getOrderList({ page: 0, search: searchStr })) : "",
      1000
    );
    return () => clearTimeout(timeOut);
  }, [searchStr]);

  let tableData = [];

  orderList?.map((orders) => {
    const order = {
      orderID:
        orders?.orderId + " / " + moment(orders?._created_at).format("llll"),
      name: orders?.customerDetails.name,
      number: orders?.customerDetails.phoneNumber,
      amount: orders?.inclGst,
      status: orders?.status,
      data: orders,
    };
    tableData.push(order);

    return tableData;
  });

  const handleDrawerOpen = () => {
    setOpenSideBar(true);
  };

  const handleDrawerClose = () => {
    setOpenSideBar(false);
  };

  const handleCommentClick = (e, data) => {
    setComments(
      data.data.products[0]?.shippingDetails?.comments ||
        "User did'nt add comments"
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleView = (e, orders, index, type) => {
    if (type === "view") {
      setOrderDetails(orders.data);
      setOpenOrderCheckout(true);
      history("/orders", {
        state: { heading: "Sales", subHeader: "Orders", isBack: true },
      });
    } else if (type === "download") {
      window.open(orders.data.orderSummaryUrl, '_blank');
    } 
  };

  const handleBack = () => {
    setOpenOrderCheckout(false);
  };

  const handleNewReturn = (e) => {
    history("/login", {
      state: { heading: "Inventory", subHeader: RoutePath?.library },
    });
  };

  const handleSearch = (e) => {
    setSearchStr(e.target.value);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoSales({ page: pageNo + 1, search: searchStr }));
    dispatch(getOrderList({ page: pageNo + 1, search: searchStr }));
  };
  
  return (
    <>
      <div>
        <NavBar
          handleDrawerOpen={handleDrawerOpen}
          header={"Orders"}
          isBack={openOrderCheckout}
          handleBack={handleBack}
        />
        <Sidebar
          handleDrawerClose={handleDrawerClose}
          open={openSideBar}
          location={location}
        />
      </div>
      {openOrderCheckout ? (
        <div className={classes.content}>
          <OrderCheckout order={orderDetails} handleBack={handleBack} />
        </div>
      ) : (
        <>
          <div className={classes.content}>
          <Grid
              container
              alignItems={"center"}
              sx={{
                position: "fixed",
                backgroundColor: "#f5f5f5",
                width: "calc(100% - 20px)",
              }}
            >
              <Grid item xs={6} sx={{ padding: "12px 24px" }}>
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Search orderId"
                  fullWidth
                  variant="outlined"
                  value={searchStr}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon width={"16"} height={"16"} />
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.notchedOutline,
                    },
                  }}
                  style={{ backgroundColor: "#fff", borderRadius: "35px" }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  padding: "12px 24px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ContinueButton onClick={handleNewReturn}>
                  New Order
                </ContinueButton>
              </Grid>
            </Grid>
            <div
              style={{
                padding: "90px 24px 40px",
                height: "calc(100vh - 194px)",
                overflow: "hidden",
                backgroundColor: "#f5f5f5",
              }}
            >
              <ListTable
                dataList={tableData}
                handleCommentClick={handleCommentClick}
                handleView={handleView}
                isLoading={loading}
                handleLoadmore={handleLoadmore}
                hasMore={hasMore}
                Header={[
                  "Order ID/Date",
                  "Consumer Name",
                  "Mobile Number",
                  "Order Amount",
                  "Status",
                  "Order Summary",
                  "Comments",
                  "Actions",
                ]}
                tableData={[
                  { type: ["TEXT"], name: "orderID" },
                  { type: ["TEXT"], name: "name" },
                  { type: ["TEXT"], name: "number" },
                  { type: ["TEXT"], name: "amount" },
                  { type: ["STATUS"], name: "status" },
                  { type: ["DOWNLOAD"], name: "" },
                  { type: ["COMMENTS"], name: "" },
                  { type: ["VIEW"], name: "" },
                ]}
              />
            </div>
            {open && (
              <div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{ borderRadius: "8px" }}
                >
                  <DialogTitle sx={{ fontWeight: 650 }}>
                    Comments
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <div style={{ padding: "20px 60px 50px" }}>
                      <div style={{ textAlign: "center" }}>
                        <Typography
                          id="modal-modal-description"
                          sx={{ mt: 2, mb: 3 }}
                        >
                          Shipment Comments - {comments}
                        </Typography>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
            )}
          </div>{" "}
        </>
      )}
    </>
  );
}

export default Sales;
