export const RoutePath = {
  login: "/login",
  err: "/404",
  library: "/library",
  demo: "/demo",
  product: "/productDescription",
  addPrescription: "/add-prescription",
  lensCatalog: "/lens-catalog",
  sales:"/orders",
  frameOrientation: "/frame-orientation",  
  checkOut: "/check-out",
  addConsumer: "/add-consumer",
  invoices: "/invoices",
  exchange:"/exchange",
  returns:"/returns",
  reminders:"/reminders",
  consumers:"/consumers",
  newConsumer:"/new-consumer",
  scan: "/scan",
  deposits: "/cash-deposits",
  addDeposit: "/add-deposit",
  viewOrder:"/view-order",
  storeLogin:"/",
  counterSale: "/counter-sale",
  transactions: "/transactions",
  reports: "/reports",
  eodv2: "/eodv2",
  b2bInvoices: "/b2binvoices"
};
