import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  lenCatalog: [],
  initialLensCatalog: [],
  selectedLens: {},
  brandNames: [],
  selectedIndexList: [],
  selectedLensList: [],
  brandSelected: "",
  keyVal: {},
  preSelectedIndex: {},
  glassDropDown: {}
};

export const lenscatalogSlice = createSlice({
  name: "lenscatalog",
  initialState,
  reducers: {
    setInitialLensCatalog(state, action) {
      return Object.assign({}, state, {
        initialLensCatalog: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getLensCatalog() {},
    setLensCatalog(state, action) {
      return Object.assign({}, state, {
        lenCatalog: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    setSelectedLensCatalog(state, action) {
      return Object.assign({}, state, {
        selectedLens: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    getBrandNames() {},
    setBrandNames(state, action) {
      return Object.assign({}, state, {
        brandNames: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    setSelectedIndexPreload(state, action) {
      return Object.assign({}, state, {
        preSelectedIndex: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setIndexPreload(state, action) {
      return Object.assign({}, state, {
        selectedIndexList: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    setCatalogPreload(state, action) {
      return Object.assign({}, state, {
        selectedLensList: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    setKeyPreload(state, action) {
      return Object.assign({}, state, {
        keyVal: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setBrandPreload(state, action) {
      return Object.assign({}, state, {
        brandSelected: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    clearCatalog(state, action) {
      return Object.assign({}, state, initialState);
    },
    getGlassDropDowns() {},
    setGlassDropDowns(state, action) {
      return Object.assign({}, state, {
        glassDropDown: isEmpty(action.payload) ? {} : action.payload,
      });
    },
  },
});

export const {
  getLensCatalog,
  setLensCatalog,
  setSelectedLensCatalog,
  setBrandNames,
  getBrandNames,
  setInitialLensCatalog,
  setIndexPreload,
  setCatalogPreload,
  setKeyPreload,
  setBrandPreload,
  setSelectedIndexPreload,
  clearCatalog,
  getGlassDropDowns,
  setGlassDropDowns
} = lenscatalogSlice.actions;

export default lenscatalogSlice.reducer;
