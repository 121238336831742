import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  transactionsList: [],
  transactionsListError: "",
  pageNoTransactions: 0,
  hasMoreTransactions: true
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    getTransactionsData() {},
    getTransactionsDataSuccess(state, action) {
      return Object.assign({}, state, {
        transactionsList: state.pageNoTransactions > 0 ? [...state.transactionsList, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
        hasMoreTransactions: action.payload.length < 15 ? false : true 
      });
    },
    getTransactionsDataError(state, action) {
      return Object.assign({}, state, {
        transactionsListError: action.payload,
      });
    },

    setPagenoTransactions(state, action) {
      return Object.assign({}, state, {
        pageNoTransactions: action.payload,
      });
    },
  },
});

export const {
    getTransactionsData,
    getTransactionsDataSuccess,
    getTransactionsDataError,
    setPagenoTransactions
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
