import {
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ContinueButton, Footer } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import _ from "lodash";
import { getProductByBarcode } from "../../app/reducers/librarySlice";
import { useNavigate } from "react-router-dom";
import {
  setProductData,
  setCounterSale,
  setBarcodeCounterSale,
  setProductByBarcode
} from "../../app/reducers/librarySlice";
import BarcodeScanner from './BarcodeScanner';

function CounterSale(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [barcode, setBarcode] = useState("");
  const [message, setMessage] = useState("");
  const [openScanner, setOpenScanner] = useState(false);

  const barcodeData = useSelector(
    (state) => state.libraryReducer.barcodeProductData
  );

  const cardStyles = {
    width: "160px",
  };

  useEffect(() => {
    if (!_.isEmpty(barcodeData)) {
      const convertedProduct = {
        _id: {
          Brand: barcodeData?.Brand,
          ModelNo: barcodeData?.ModelNo,
          SizeActual: barcodeData?.SizeActual,
        },
        variants: [
          {
            Brand: barcodeData?.Brand,
            BridgeSize: barcodeData?.BridgeSize,
            Category: barcodeData?.Category,
            ColorCode: barcodeData?.ColorCode,
            brandType: barcodeData?.brandType,
            CostPrice: barcodeData?.CostPrice,
            FrameColor: barcodeData?.FrameColor,
            FrameMaterial: barcodeData?.FrameMaterial,
            FrameType: barcodeData?.FrameType,
            Gender: barcodeData?.Gender,
            Height: barcodeData?.Height,
            LensColor: barcodeData?.LensColor,
            ModelNo: barcodeData?.ModelNo,
            ProductImages: barcodeData?.ProductImages,
            ProductName: barcodeData?.ProductName,
            Quantity: barcodeData?.Quantity,
            RimColor: barcodeData?.RimColor,
            SellPrice: barcodeData?.SellPrice,
            Shape: barcodeData?.Shape,
            Size: barcodeData?.Size,
            SizeActual: barcodeData?.SizeActual,
            Status: barcodeData?.Status,
            TempleColor: barcodeData?.TempleColor,
            TempleMaterial: barcodeData?.TempleMaterial,
            TempleSize: barcodeData?.TempleSize,
            ThumbnailImage: barcodeData?.ThumbnailImage,
            Width: barcodeData?.Width,
            _id: barcodeData?._id,
          },
        ],
      };
      setMessage("");
      dispatch(setProductData(convertedProduct));
      dispatch(setBarcodeCounterSale(barcode));
      dispatch(setCounterSale(true));
      dispatch(setProductByBarcode({}))
      history("/productDescription");
    } else {
      setMessage("Enter/scan a valid barcode assigned with the store");
    }
  }, [barcodeData]);

  const handleChange = (e) => {
    setBarcode(e.target.value);
  };

  const handleBarcode = () => {
    dispatch(getProductByBarcode(barcode));
  };

  const handleScanner = () => {
    setOpenScanner(true);
  };

  const backToExchange = () => {
    props.backToExchange();
  };

  const handleBackButton = () => {
    history("/library", {
      state: { heading: "Inventory", subHeader: "Library" },
    });
  };

  const handleContinueButton = () => {};

  return (
    <>
      {openScanner ? (
        <BarcodeScanner />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                type="text"
                placeholder="Enter Barcode"
                variant="outlined"
                size="small"
                onChange={(event) => handleChange(event)}
                sx={{ borderRadius: "35px" }}
              />
              <ContinueButton onClick={handleBarcode}>Add</ContinueButton>
            </div>
            <ContinueButton onClick={handleScanner}>
              Open Scanner
            </ContinueButton>
          </div>
          <div style={{ padding: "50px" }}>
            {!_.isEmpty(message) ? message : ""}
          </div>
          <div>
            <Footer back={"Back"} handleBackButton={handleBackButton}></Footer>
          </div>
        </div>
      )}
    </>
  );
}

export default CounterSale;
