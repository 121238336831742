import React from "react";

export const RefreshIcon = (props) => {
    const color = props?.color ?? "#03a5a5";
    const width = props?.width ?? "14";
    const height = props?.height ?? "14";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <g data-name="vuesax/outline/refresh-2">
        <path
          d="M1.11 14.44v2.917"
          transform="translate(.704 -4.85)"
        //   style="stroke:#03a5a5;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5px"
        fill="none"
        />
        <path
          data-name="Vector"
          d="M11.667 5.833a5.835 5.835 0 0 1-5.833 5.833c-3.22 0-5.186-3.243-5.186-3.243h2.636M0 5.833A5.821 5.821 0 0 1 5.833 0a7.176 7.176 0 0 1 5.833 3.243m0 0V.327m0 2.917H9.077"
          transform="translate(1.167 1.167)"
        //   style="stroke:#03a5a5;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5px"
        fill="none"
        />
      </g>
    </svg>
  );
};
