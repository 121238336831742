import {
  Grid,
  Typography,
  Dialog,
  Alert,
  AlertTitle,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer, UserIcon, NavBar } from "../../../components";
import { useNavigate } from "react-router-dom";
import ProductImagesComponent from "./productImages";
import {
  setSelectedProductData,
  setMultiSelectedProductsData,
  setSkipped,
} from "../../../app/reducers/librarySlice";
import _ from "lodash";

import { AddPrescriptionIcon } from "../../../components/svg/AddPrescriptionIcon";
import { ContinueButton } from "../../../components/wrapperComponents";
import { RoutePath } from "../../../router/routes";
import { setContinueShopping } from "../../../app/reducers/checkoutSlice";

const ProductDescription = (props) => {
  const dispatch = useDispatch();

  const isDemo = useSelector((state) => state.demoReducer.isDemo);
  const isContinueShopping = useSelector(
    (state) => state.checkoutReducer.isContinueShopping
  );
  const multiSelectProduct = useSelector(
    (state) => state.libraryReducer.multiSelectProduct
  );
  const showCheckout = useSelector(
    (state) => state.prescriptionReducer.showCheckout
  );
  const product = useSelector((state) =>
    state.demoReducer.isDemo
      ? state.demoReducer.productDescription
      : state.libraryReducer.productDescription
  );
  const isSkipped = useSelector((state) => state.libraryReducer.isSkipped);
  const isPresc = useSelector(
    (state) => state.prescriptionReducer.selectedPrescription
  );

  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [outline, setOutline] = useState(
    _.isEmpty(product?.variants) ? "" : product?.variants[0]?._id
  );
  const [variant, setVariant] = useState(
    _.isEmpty(product?.variants) ? {} : product?.variants[0]
  );

  const handleClose = () => {
    setOpen(false);
  };

  let columnConfig;

  if (variant) {
    columnConfig = [
      { header: "Shape", value: isDemo ? product.Shape : variant.Shape },
      { header: "Gender", value: isDemo ? product.Gender : variant.Gender },
      {
        header: "Frame Type",
        value: isDemo ? product.FrameType : variant.FrameType,
      },
      {
        header: "Frame Material",
        value: isDemo ? product.FrameMaterial : variant.FrameMaterial,
      },
      {
        header: "Temple Material",
        value: isDemo ? product.TempleMaterial : variant.TempleMaterial,
      },
      {
        header: "Color Code",
        value: isDemo ? product.ColorCode : variant.ColorCode,
      },
      {
        header: "Frame Color",
        value: isDemo ? product.FrameColor : variant.FrameColor,
      },
      {
        header: "Lens Color",
        value: isDemo ? product.LensColor : variant.LensColor,
      },
      {
        header: "Rim Color",
        value: isDemo ? product.RimColor : variant.RimColor,
      },
      {
        header: "Temple Color",
        value: isDemo ? product.TempleColor : variant.TempleColor,
      },
      { header: "Size", value: isDemo ? product.Size : variant.Size },
      {
        header: "Size Actual",
        value: isDemo ? product.SizeActual : variant.SizeActual,
      },
    ];
  }

  useEffect(() => {
    const productObj = {
      productId: variant?._id,
      name: variant?.ProductName,
      price: variant?.SellPrice,
      costPrice: variant?.CostPrice,
      model: variant?.Model,
      frameType: variant?.FrameType,
      brandType: variant?.brandType,
      isProduct: true,
    };
    dispatch(setSelectedProductData(productObj));
    if (isContinueShopping || _.isEmpty(multiSelectProduct)) {
      dispatch(setMultiSelectedProductsData(productObj));
    }
  }, []);

  const handleContinueButton = () => {
    dispatch(setSkipped(false));
    !showCheckout ? setOpen(true) : history("/add-prescription");
  };

  const skipPrescription = () => {
    dispatch(setSkipped(true));
    history("/check-out");
  };

  const addPrescription = () => {
    dispatch(setSkipped(false));
    history("/add-prescription");
  };

  const handleBack = () => {
    history(RoutePath?.demo, {
      state: { heading: "Inventory", subHeader: "Demo" },
    });
  };

  const handleBackButton = () => {
    !isSkipped && _.isEmpty(isPresc)
      ? history("/library", {
          state: { heading: "Inventory", subHeader: "Library" },
        })
      : setShowAlert(true);
  };

  const handlePage = () => {
    dispatch(setContinueShopping(false));
    history("/library", {
      state: { heading: "Inventory", subHeader: "Library" },
    });
  };
  const handleClickVariants = (e, variantData) => {
    setVariant(variantData);
    setOutline(variantData?._id);
  };

  return (
    <>
      {isDemo ? (
        <div style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
          <NavBar
            header={"Product Description"}
            isBack={true}
            handleBack={handleBack}
          />
        </div>
      ) : (
        ""
      )}
      {showAlert ? (
        <div>
          <Alert
            severity="warning"
            color="warning"
            onClose={() => handlePage()}
          >
            {" "}
            <AlertTitle>Warning</AlertTitle>
            If you go back to previous page after checkout —{" "}
            <strong>Changes will be lost</strong> —{" "}
            <strong>Close alert to proceed</strong>
          </Alert>
        </div>
      ) : (
        ""
      )}
      <div style={{ position: "relative", overflow: "hidden" }}>
        <div style={{ padding: "64px 0px" }}>
          <Grid
            container
            sx={{ pt: "15px", pb: "20px" }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={6} sx={{ pl: "30px" }}>
              <ProductImagesComponent
                images={isDemo ? product?.ProductImages : variant.ProductImages}
              />
              {!isDemo ? (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {product?.variants.map((variant, index) => (
                    <div style={{ margin: "10px" }}>
                      <div
                        style={{
                          backgroundColor: variant.FrameColor || "#03a5a5",
                          height: "25px",
                          width: "25px",
                          borderRadius: "50%",
                          //border: " 1px solid #03a5a5",
                          outline:
                            outline === variant?._id ? "solid #03a5a5" : "",
                        }}
                        onClick={(event) => handleClickVariants(event, variant)}
                      ></div>{" "}
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div
                style={{
                  backgroundColor: "#edf9f9",
                  display: "inline-block",
                  padding: "8px 12px",
                  borderRadius: "4px",
                }}
              >
                <Typography sx={{ color: "#03a5a5", fontSize: "12px" }}>
                  {isDemo ? product?.Brand : product?._id.Brand}
                </Typography>
              </div>
              <Typography
                sx={{ color: "#242424", fontSize: "14px", margin: "10px 0px" }}
              >
                {isDemo ? product?.ProductName : variant?.ProductName}
              </Typography>
              <Typography
                sx={{ color: "#242424", fontSize: "18px", fontWeight: 600 }}
              >
                &#8377;{isDemo ? product?.SellPrice : variant?.SellPrice}
              </Typography>
            </Grid>
          </Grid>
          <div
            style={{
              border: "1px solid #eee",
              margin: "0px 75px 25px",
              padding: "40px 0px 0px",
            }}
          >
            <Grid container>
              {columnConfig?.map((val, i) => (
                <Grid item xs={2} sx={{ pb: "30px" }}>
                  <Typography
                    sx={{
                      pb: "4px",
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: 600,
                    }}
                  >
                    {val?.header}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      color: "#242424",
                    }}
                  >
                    {val?.value ? (val?.value).toUpperCase() : val?.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        {!isDemo ? (
          <Footer
            back={"Back"}
            handleBackButton={handleBackButton}
            handleContinueButton={handleContinueButton}
            onContinue={!showCheckout ? "Check Out" : "Continue"}
          />
        ) : (
          ""
        )}
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ borderRadius: "8px" }}
          >
            <div style={{ padding: "20px 60px 0px" }}>
              <div style={{ textAlign: "center" }}>
                <div style={{ paddingLeft: "25px" }}>
                  <AddPrescriptionIcon />
                </div>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 3 }}>
                  Do You Want To Add Prescription
                </Typography>
                <ContinueButton onClick={addPrescription}>
                  Add Prescription
                </ContinueButton>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, color: "#03A5A5", mb: 3 }}
                  onClick={skipPrescription}
                >
                  Skip
                </Typography>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default ProductDescription;
