import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  prescriptionData: [],
  selectedPrescription: "",
  prescriptionDataLeft: {},
  prescriptionDataRight: {},
  glassDropDown: {},
  showCheckout: false,
};

export const prescriptionSlice = createSlice({
  name: "prescription",
  initialState,
  reducers: {
    setShowCheckout(state, action) {
      return Object.assign({}, state, {
        showCheckout: action.payload,
      });
    },
    setSelectedPrescription(state, action) {
      return Object.assign({}, state, {
        selectedPrescription: isEmpty(action.payload) ? "" : action.payload,
      });
    },
    getPrescriptionData() {},
    setPrescriptionData(state, action) {
      return Object.assign({}, state, {
        prescriptionData: isEmpty(action.payload) ? [] : action.payload,
      });
    },
    preloadPrescriptionDataLeft(state, action) {
      return Object.assign({}, state, {
        prescriptionDataLeft: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    preloadPrescriptionDataRight(state, action) {
      return Object.assign({}, state, {
        prescriptionDataRight: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    clearPrescription(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getPrescriptionData,
  setPrescriptionData,
  setSelectedPrescription,
  preloadPrescriptionDataRight,
  preloadPrescriptionDataLeft,
  clearPrescription,
  setShowCheckout
} = prescriptionSlice.actions;

export default prescriptionSlice.reducer;
