import React, { useState,} from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Footer } from "../../../components";
import { useNavigate } from "react-router-dom";
import frameOrientImage from "../../../assets/frame-orient.png";
import { setFrame } from "../../../app/reducers/frameSlice";
import { useDispatch, useSelector } from "react-redux";
import { setMultiSelectedProductsData } from "../../../app/reducers/librarySlice";
import _ from "lodash";

const FrameOrientation = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const productData = useSelector(
    (state) => state.libraryReducer.selectedProduct
  );

  const lensCatalogData = useSelector(
    (state) => state.lenscatalogReducer.selectedLens
  );

  const preFrameData = useSelector((state) => state.frameReducer.frameSize);

  const stateData = _.isEmpty(preFrameData)
    ? { a: "0", b: "0", ed: "0", fh: "0", d: "0" }
    : {
      a: preFrameData?.a,
      b: preFrameData?.b,
      ed: preFrameData?.ed,
      fh: preFrameData?.fh,
      d: preFrameData?.d,
    };
  const [frameSize, setFrameSize] = useState(stateData);
  const frameSizeList = [];

  const a = {
    label: "A",
    value: frameSize.a,
  };
  const b = {
    label: "B",
    value: frameSize.b,
  };
  const ed = {
    label: "ED",
    value: frameSize.ed,
  };
  const fh = {
    label: "FH",
    value: frameSize.fh,
  };
  const d = {
    label: "D",
    value: frameSize.d,
  };

  if (lensCatalogData.lensItems.lensType === 'SINGLE VISION') {
    if (productData.frameType === 'HALFRIM') {
      frameSizeList.push(a)
    } else if (productData.frameType === 'RIMLESS') {
      frameSizeList.push(a)
      frameSizeList.push(b)
    }
  }

  if (lensCatalogData.lensItems.lensType === 'BIFOCAL') {
    if (productData.frameType === 'FULLRIM') {
      frameSizeList.push(ed)
      frameSizeList.push(fh)
    } else if (productData.frameType === 'HALFRIM') {
      frameSizeList.push(b)
      frameSizeList.push(fh)
      frameSizeList.push(ed)
    } else if (productData.frameType === 'RIMLESS') {
      frameSizeList.push(a)
      frameSizeList.push(b)
      frameSizeList.push(fh)
      frameSizeList.push(ed)
    }
  }

  if (lensCatalogData.lensItems.lensType === 'PROGRESSIVE') {
    frameSizeList.push(a)
    frameSizeList.push(b)
    frameSizeList.push(ed)
    frameSizeList.push(fh)
    frameSizeList.push(d)
  }

  const handleChange = (e, label) => {
    const labelLower = label.toLowerCase();
    setFrameSize({ ...frameSize, [labelLower]: e.target.value });
  };

  const handleBackButton = () => {
    history("/lens-catalog");
  };

  const handleContinueButton = () => {
    const data = {
      productId: productData.productId,
      isProduct: false,
      frameSize: frameSize,
    };
    dispatch(setFrame(frameSize));
    dispatch(setMultiSelectedProductsData(data));
    history("/check-out");
  };

  return (
    <div
      style={{
        textAlign: "center",
        padding: "90px 0px 0px 0px",
      }}
    >
      <Grid container direction="row">
        <Grid item xs={12} sm={6} md={6}>
          <div style={{ margin: "10px" }}>
            <img src={frameOrientImage} alt="specs" />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container direction="row" xs={12} sm={6} md={6}>
            <Typography color={"#03a5a5"} sx={{ m: "4%" }} variant="h4">
              Enter Frame Size
            </Typography>
            {frameSizeList.map((frame) => (
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label={frame.label}
                  sx={{ m: "10%" }}
                  value={frame.value}
                  onChange={(event) => handleChange(event, frame.label)}
                  InputLabelProps={{ shrink: true, disableAnimation: true }}
                ></TextField>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Footer
        back={"Back"}
        handleBackButton={handleBackButton}
        handleContinueButton={handleContinueButton}
        onContinue={"Continue"}
      />
    </div>
  );
};

export default FrameOrientation;
