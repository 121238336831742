import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  orderSummary: {},
  isFromCustomer: false,
  responseStatusCheckout: "",
  orderSummaryError: "",
  couponDataError: "",
  couponData: {},
  couponCode: "",
  isContinueShopping: false
};

export const checkoutSlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    setContinueShopping(state, action) {
      return Object.assign({}, state, {
        isContinueShopping: action.payload,
      });
    },
    setCouponsCode(state, action) {
      return Object.assign({}, state, {
        couponCode: action.payload,
      })
    },
    setIsFromOrder(state, action) {
      return Object.assign({}, state, {
        isFromCustomer: action.payload,
      });
    },

    getCheckoutData() {},
    setCheckoutData(state, action) {
      return Object.assign({}, state, {
        orderSummary: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setCheckoutDataError(state, action) {
      return Object.assign({}, state, {
        orderSummaryError: action.payload,
      });
    },

    setReponseStatusCheckout(state, action) {
      return Object.assign({}, state, {
        responseStatusCheckout: action.payload,
      });
    },
   
    getCouponData() {},
    setCouponData(state, action) {
      return Object.assign({}, state, {
        couponData: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setCouponDataError(state, action){
      return Object.assign({}, state, {
        couponDataError: action.payload,
      });
    },

    clearCheckout(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getCheckoutData,
  setCheckoutData,
  setCheckoutDataError,
  clearCheckout,
  setIsFromOrder,
  setReponseStatusCheckout,
  getCouponData,
  setCouponData,
  setCouponsCode,
  setCouponDataError,
  setContinueShopping
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
