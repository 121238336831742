import { Grid, InputAdornment, TextField, Dialog, Typography } from "@mui/material";
import React, {useState, useEffect} from "react";
import { withNavBars } from "../../common/hocs";
import { OrderIndentIcon } from "../../components";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../router/routes";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { getReportData } from "../../app/reducers/reportSlice";
import moment from "moment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import _ from "lodash";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

const indentData = {};

function Reports(props) {
  const today = moment().format("YYYY-MM-DD");
  const currentDisplayDate = moment().format("llll");
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const reportList = useSelector(
    (state) => state.reportReducer.reportList
  );


  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [searchDate, setSearchDate] = useState(today);
  const [page, setPage] = useState(0);
  const [displayDate, setDisplayDate] = useState(currentDisplayDate);
  const [payload, setPayLoad] = useState({ page: page, date: searchDate });

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (token) {
      dispatch(getPermittedStore());
    }
  }, []);

  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(storeChange)) {
      dispatch(getReportData(payload));
    }
  }, [storeChange, payload]);

  const handleView = (e, data) => {
    setOpenModal(true);
    setImageUrl(data.data.imageUrl);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (date) => {
    
    setSearchDate(moment(date).format("YYYY-MM-DD"));
    setDisplayDate(moment(date).format("llll"));
  };

  useEffect(() => {
    setPayLoad({ page: page, date: searchDate });
  }, [searchDate, page]);

  return (
    <div>
      <Grid
        container
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
          position: "fixed"
        }}
        justifyContent="flex-end"
      >
        <Grid item xs={6} sx={{ padding: "12px 24px", justifyContent: "flex-start",}}>
          <Typography
            variant="h6"
          >
            {displayDate}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            padding: "12px 24px 0px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              inputFormat="MM/dd/yyyy"
              value={searchDate}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField {...params} sx={{ 
                  "& .MuiInputBase-root": {
                    borderRadius:"100px"
                  },
                }} />
              )}
              className="date-picker"
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
          position: "static",
        }}
        justifyContent="flex-end"
      >
        <Grid item xs={12} sx={{ marginTop:"90px", padding: "12px 24px", justifyContent: "flex-start",}}>
          <Typography
            variant="h6"
          >
            Inventory
          </Typography>
        </Grid>
    </Grid>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        aligntItems: "center",
      }}
    >
      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              Demo Inventory
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              {reportList?.demoInventoryCount}
            </Typography>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              Stock Exchange
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              {reportList?.stockTransferCount}
            </Typography>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              Bad Inventory
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              -
            </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Grid
        container
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
          position: "static"
        }}
        justifyContent="flex-end"
      >
        <Grid item xs={12} sx={{  padding: "12px 24px", justifyContent: "flex-start",}}>
          <Typography
            variant="h6"
          >
            Sales
          </Typography>
        </Grid>
    </Grid>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        aligntItems: "center",
      }}
    >
      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              New Orders
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              -
            </Typography>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              Open Orders
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              {reportList?.totalOpenOrdersCount} / &#8377;{reportList?.totalOpenOrdersAmount}
            </Typography>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              Closed Orders
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              {reportList?.totalClosedOrdersCount} / &#8377;{reportList?.totalClosedOrdersAmount}
            </Typography>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              Returns
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              {reportList?.totalReturns}
            </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Grid
        container
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
          position: "static"
        }}
        justifyContent="flex-end"
      >
        <Grid item xs={12} sx={{ padding: "12px 24px", justifyContent: "flex-start",}}>
          <Typography
            variant="h6"
          >
            Payment
          </Typography>
        </Grid>
    </Grid>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        aligntItems: "center",
      }}
    >
      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              Cash
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              &#8377;{reportList?.totalCashPayment}
            </Typography>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              Card
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              &#8377;{reportList?.totalCardPayment}
            </Typography>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          minWidth: "15%",
          height: "10%",
          borderRadius: "5px",
          backgroundColor: "#fff",
          border: "solid 1px #eaeaea",
          margin: "0px 23px 34px 24px",
          padding: "17px 10px 20px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            aligntItems: "center",
          }}
        >
          <OrderIndentIcon width="31px" height="31px" />
          <div>
              <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                aligntItems: "center",
                flexDirection: "column"
              }}
            >
            <Typography
              sx={{
                color: "#827e7e",
                fontSize: "12px",
                fontWeight: 500,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              UPI
            </Typography>
            <Typography
              sx={{
                color: "#2b2b2b",
                fontSize: "20px",
                fontWeight: 600,
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: 1.88,
                letterSpacing: "0.8px",
                textAlign: "left",
              }}
            >
              &#8377;{reportList?.totalUpiPayment}
            </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    // <div>
    //   <Grid
    //     container
    //     alignItems={"center"}
    //     sx={{
    //       position: "fixed",
    //       backgroundColor: "#f5f5f5",
    //       width: "calc(100% - 20px)",
    //     }}
    //     justifyContent="flex-end"
    //   >
    //       <Grid item xs={6} sx={{ padding: "12px 24px" }}>
    //       <TextField
    //         id="input-with-icon-textfield"
    //         placeholder="Search Customer Name/Mobile Number/Order Id"
    //         fullWidth
    //         variant="outlined"
    //         InputProps={{
    //           startAdornment: (
    //             <InputAdornment position="start">
    //               <SearchIcon width={"16"} height={"16"} />
    //             </InputAdornment>
    //           ),
    //           classes: {
    //             root: classes.notchedOutline,
    //           },
    //         }}
    //         style={{ backgroundColor: "#fff", borderRadius: "35px" }}
    //       />
    //     </Grid>
    //     <Grid item xs={6} sx={{ padding: "12px 24px 0px",display:"flex",justifyContent:"flex-end" }}>
    //       <ContinueButton onClick={handleNewDeposit}>New Deposit</ContinueButton>
    //     </Grid>
    //   </Grid>
    //   <div
    //     style={{
    //       padding: "90px 24px 40px",
    //       height: "calc(100vh - 200px)",
    //       overflowY: "auto",
    //       overflowX: "hidden",
    //     }}
    //   >
    //     <ListTable
    //       dataList={[]}
    //       handleView={handleView}
    //       Header={[
    //         "Deposit Date",
    //         "Deposit Amount",
    //         "Receipt",
    //       ]}
    //       paddingLeft="100px"
    //       tableData={[
    //         { type: ["TEXT"], name: "depositDate" },
    //         { type: ["TEXT"], name: "depositAmount" },
    //         { type: ["VIEW"], name: "" },
    //       ]}
    //     />
    //   </div>
    //   <Dialog
    //         open={openModal}
    //         maxWidth
    //         onClose={handleClose}
    //         aria-labelledby="modal-modal-title"
    //         aria-describedby="modal-modal-description"
    //         sx={{ borderRadius: "8px" }}
    //       >
    //        <img src={imageUrl} alt="Image broken"></img>
    //   </Dialog>
    // </div>
  );
}

export default withNavBars(Reports);
