import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  loginData: {},
  newLoginData: {},
  isLoggedIn: false,
  existingUser: false,
};

export const userSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    clearLoginData(state, action) {
      return Object.assign({}, state, {
        loginData: {},
        existingUser: false,
      });
    },
    getLoginData() {},
    setLoginData(state, action) {
      if (!isEmpty(action.payload)) {
        return Object.assign({}, state, {
          loginData: action.payload,
          existingUser: true,
        });
      } else {
        state.existingUser = false;
      }
    },
    isLoggedIn(state, action) {
      return Object.assign({}, state, {
        isLoggedIn: action.payload,
      });
    },
    setNewUserData(state, action) {
      return Object.assign({}, state, {
        newLoginData: action.payload,
        existingUser: false,
      });
    },

    clearLogin(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getLoginData,
  setLoginData,
  isLoggedIn,
  setNewUserData,
  clearLoginData,
  clearLogin
} = userSlice.actions;

export default userSlice.reducer;
