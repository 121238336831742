import React from "react";

export const ExpandLensIcon = (props) => {
    const color = props?.color ?? "#fff";
    const width = props?.width ?? "24";
    const height = props?.height ?? "24";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g data-name="Group 2125" transform="translate(-354 -271)">
        <circle
          data-name="Ellipse 82"
          cx="16"
          cy="16"
          r="16"
          transform="translate(354 271)"
        //   style="opacity:.3;fill:#fff"
          opacity={0.5}
          fill={"#000"}
        />
        <g data-name="Group 2116">
          <path
            d="M7.483.535a.91.91 0 0 0-.477-.477A1.008 1.008 0 0 0 6.681 0h-4.65a.878.878 0 0 0-.872.872.878.878 0 0 0 .872.872h2.546L.253 6.068a.877.877 0 0 0 0 1.232.87.87 0 0 0 1.232 0l4.324-4.324v2.545a.872.872 0 0 0 1.744 0V.872a.876.876 0 0 0-.07-.337z"
            transform="translate(370.14 279)"
            // style="fill:#fff"
            fill={color}
          />
          <path
            data-name="Vector"
            d="M7.3.253a.877.877 0 0 0-1.232 0L1.744 4.577V2.031a.878.878 0 0 0-.872-.872.878.878 0 0 0-.872.872v4.65a.876.876 0 0 0 .07.337.91.91 0 0 0 .477.477.954.954 0 0 0 .325.058h4.65a.872.872 0 0 0 0-1.744H2.976L7.3 1.485a.877.877 0 0 0 0-1.232z"
            transform="translate(362.001 287.139)"
            // style="fill:#fff"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}