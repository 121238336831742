import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getProductListData,
  setProductListData,
} from "../reducers/demoSlice";
import { API_URL,} from "../../utils/constants";

function fetchProductsDataAPI(payload) {
  const { page , filter, search } = payload;
  const storeId = localStorage.getItem("storeId");
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/inventory/fetchstoreinventory`;
  const products = axios
    .get(apiURL, {
      params: {
        limit: 20,
        page: page,
        storeId: storeId,
        search: search,
        filter: filter,
        sort: { _created_at: -1 },
      },
      headers: headers,
    })
    .then((response) => response);
  return products;
}

export function* fetchProductsData(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchProductsDataAPI, payload);
    yield put(setProductListData(response.data.data));
  } catch (error) {
  }
}

export function* demoSaga() {
  yield takeLatest(getProductListData.type, fetchProductsData);
}
