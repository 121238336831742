import { createSlice } from "@reduxjs/toolkit";
import _,{ isEmpty } from "lodash";

const initialState = {
  consumerAdded: {},
  consumerData: [],
  consumerAddedError: "",
  consumerListError: "",
  pageNo: 0,
  hasMore: true,
  searchDataConsumer: ""
};

export const consumerSlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    getConsumerData() {},
    setConsumerData(state, action) {
      return Object.assign({}, state, {
        consumerAdded: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setConsumerDataError(state, action) {
      return Object.assign({}, state, {
        consumerAddedError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    setPageno(state, action) {
      return Object.assign({}, state, {
        pageNo: action.payload,
      });
    },
    setSearchDataConsumer(state, action) {
      return Object.assign({}, state, {
        searchDataConsumer: _.isEmpty(action.payload) ? "" : action.payload,
      });
    },

    getConsumerList() {},
    setConsumerList(state, action) {
      return Object.assign({}, state, {
        consumerData: state.pageNo > 0 ? [...state.consumerData, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
        hasMore: action.payload.length < 15 ? false : true 
      });
    },
    setConsumerListError(state, action) {
      return Object.assign({}, state, {
        consumerDataError: action.payload,
      });
    },

    clearConsumer(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getConsumerData,
  setConsumerData,
  setConsumerDataError,
  getConsumerList,
  setConsumerList,
  setConsumerListError,
  clearConsumer,
  setPageno,
  setSearchDataConsumer
} = consumerSlice.actions;

export default consumerSlice.reducer;
