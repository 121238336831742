import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import _ from "lodash";

const initialState = {
  selectedProductDemo: {},
  multiSelectProductDemo: [],
  filterDataDemo: {},
  productsListDemo: [],
  productDescription: {},
  isDemo: false,
  responseStatus: "",
  pageNoDemo: 0,
  hasMoreDemo: true,
  searchDataDemo: "",
};

export const demoSlice = createSlice({
  name: "demo",
  initialState,
  reducers: {
    setReponseStatus(state, action) {
      return Object.assign({}, state, {
        responseStatus: action.payload,
      });
    },
    getProductListData() {},
    setProductListData(state, action) {
      return Object.assign({}, state, {
        productsListDemo:
          state.pageNoDemo > 0
            ? [...state.productsListDemo, ...action.payload]
            : _.isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreDemo: action.payload.length < 20 ? false : true,
      });
    },
    setPagenoDemo(state, action) {
      return Object.assign({}, state, {
        pageNoDemo: action.payload,
      });
    },

    getProductData() {},
    setProductData(state, action) {
      return Object.assign({}, state, {
        productDescription: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setFiltersDataDemo(state, action) {
      return Object.assign({}, state, {
        filterDataDemo: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setSearchDataDemo(state, action) {
      return Object.assign({}, state, {
        searchDataDemo: isEmpty(action.payload) ? "": action.payload,
      });
    },

    setIsDemo(state, action) {
      return Object.assign({}, state, {
        isDemo: action.payload,
      });
    },
    clearDemo(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getProductData,
  setProductData,
  setFiltersDataDemo,
  setProductListData,
  getProductListData,
  setIsDemo,
  setMultiSelectedProductsDataDemo,
  setSelectedProductDataDemo,
  setReponseStatus,
  clearDemo,
  setPagenoDemo,
  setSearchDataDemo
} = demoSlice.actions;

export default demoSlice.reducer;
