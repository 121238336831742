import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withNavBars } from "../../common/hocs";
import { ListTable, SearchIcon } from "../../components";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getB2bInvoicesList,
  setPageno,
} from "../../app/reducers/b2bInvoiceSlice";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import _ from "lodash";
import { useIsMount } from "../../common/hooks/customHooks";
import moment from "moment";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

function ListInvoices() {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const isMount = useIsMount();
  
  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );
  const b2bInvoicesList = useSelector(
    (state) => state.b2bInvoiceReducer.b2bInvoiceData
  );
  const pageNo = useSelector((state) => state.b2bInvoiceReducer.pageNo);
  const hasMore = useSelector((state) => state.b2bInvoiceReducer.hasMore);

  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState("");


  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(storeChange)) {
      dispatch(getB2bInvoicesList({ page:0 , search: searchStr}));
    }
  }, [storeChange]);

  useEffect(() => {
    setLoading(false);
  }, [b2bInvoicesList]);

  const tableData = b2bInvoicesList?.map((invoice) => {
    const invoiceDetail = {
      invoiceIdDate: invoice?._id + "/" + moment(invoice?.invoiceDate).format("ll"),
      invoiceUrl: invoice?.invoiceUrl || "-",
      subTotal: invoice?.subTotal || "-",
      grandTotal: invoice?.grandTotal || "-",
      totalGst: invoice?.totalGst || "-",
    };
    return invoiceDetail;
  });


  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPageno(pageNo + 1));
    dispatch(getB2bInvoicesList({ page:pageNo + 1 , search: searchStr}));
  };

  const handleSearch = (e) => {
    setSearchStr(e.target.value);
  }

  useEffect(() => {
    const timeOut =  setTimeout(() =>  !isMount ? dispatch(getB2bInvoicesList({ page:0 , search: searchStr})) : "", 1000);
    return () => clearTimeout(timeOut);
  },[searchStr])

  const handleView = (e, data, index, type) => {
 
    if (type === "download") {
      window.open(data.invoiceUrl, '_blank');
    } 
  };

  return (
    <div>
      <Grid
        container
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
        }}
        justifyContent="flex-end"
      >
        <Grid item xs={6} sx={{ padding: "12px 24px" }}>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search Invoice"
            fullWidth
            variant="outlined"
            onChange={handleSearch}
            value={searchStr}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon width={"16"} height={"16"} />
                </InputAdornment>
              ),
              classes: {
                root: classes.notchedOutline,
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: "35px" }}
          />
        </Grid>
      </Grid>
      <div
        style={{
          padding: "90px 24px 40px",
          height: "calc(100vh - 200px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <ListTable
          dataList={tableData}
          isLoading={loading}
          handleView={handleView}
          handleLoadmore={handleLoadmore}
          hasMore={hasMore}
          Header={["Invoice ID/Date", "Sub Total", "GST Amount", "Grand Total", "", ""]}
          paddingLeft="100px"
          tableData={[
            { type: ["TEXT"], name: "invoiceIdDate" },
            { type: ["TEXT"], name: "subTotal" },
            { type: ["TEXT"], name: "totalGst" },
            { type: ["TEXT"], name: "grandTotal" },
            { type: ["DOWNLOAD"], name: "" },
            { type: ["VIEW"], name: "" },
          ]}
        />
      </div>
    </div>
  );
}

export default withNavBars(ListInvoices);
