import {
  Grid,
  Dialog,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { withNavBars } from "../../common/hocs";
import { ContinueButton, ListTable, SearchIcon } from "../../components";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../router/routes";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCashData, setPagenoCash } from "../../app/reducers/cashdepositSlice";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import moment from "moment";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

function CashDeposits(props) {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );
  const cashDepositList = useSelector(
    (state) => state.cashdepositReducer.cashDepositList
  );
  const pageNo = useSelector((state) => state.cashdepositReducer.pageNoCash);
  const hasMore = useSelector((state) => state.cashdepositReducer.hasMoreCash);

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(storeChange)) {
      dispatch(getCashData(0));
    }
  }, [storeChange]);

  const handleNewDeposit = () => {
    history(RoutePath?.addDeposit, {
      state: { heading: "Cash Deposits", subHeader: "Cash Deposits" },
    });
  };

  const handleView = (e, data) => {
    setOpenModal(true);
    setImageUrl(data.data.receiptImage);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  let tableData = [];

  cashDepositList?.map((cash) => {
    const cashData = {
      depositDate: moment(cash?.depositDate).format("ll"),
      depositAmount: cash?.amount,
      data: cash,
    };
    tableData.push(cashData);
    return cashData;
  });

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoCash(pageNo + 1));
    dispatch(getCashData(pageNo + 1));
  };

  return (
    <div>
      <Grid
        container
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
        }}
        justifyContent="flex-end"
      >
        <Grid
          item
          xs={6}
          sx={{
            padding: "12px 14px 0px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ContinueButton onClick={handleNewDeposit}>
            New Deposit
          </ContinueButton>
        </Grid>
      </Grid>
      <div
        style={{
          padding: "90px 24px 40px",
          height: "calc(100vh - 200px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <ListTable
          dataList={tableData}
          handleView={handleView}
          isLoading={loading}
          handleLoadmore={handleLoadmore}
          hasMore={hasMore}
          Header={["Deposit Date", "Deposit Amount", "Receipt"]}
          paddingLeft="100px"
          tableData={[
            { type: ["TEXT"], name: "depositDate" },
            { type: ["TEXT"], name: "depositAmount" },
            { type: ["VIEW"], name: "" },
          ]}
        />
      </div>
      <Dialog
        open={openModal}
        maxWidth
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "8px" }}
      >
        <div>
          <div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <img
            src={imageUrl}
            width="500px"
            height="300px"
            alt="Image broken"
          ></img>
        </div>
      </Dialog>
    </div>
  );
}

export default withNavBars(CashDeposits);
