import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const HeadTypography = styled(Typography)(({theme}) => ({
    fontSize:"14px",
    fontWeight:600
}))

export const LabelTypography = styled(Typography)(({theme}) => ({
    fontSize:"12px",
}))

export const useStyles = makeStyles({
    alignFlex:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between"
    },
    notchedOutline: {
        borderRadius: "35px !important",
    },
    statusDialogBox:{
        padding:"20px 24px",
        height: "150px",
        width: "200px"
    },
    statusExchangeDialogBox:{
        padding:"20px 24px",
    },
    shipmentDialogBox:{
        padding:"20px 24px",
        width: "280px"
    },
    deliverDialogBox:{
        padding:"20px 24px",
    },
    deliveryPaper:{
        width:"30%"
    },  
    radio:{
        color:"#03a5a5 !important"
    },
      content: {
        width: '100%',
        height: 'calc(100vh - 64px)',
        overflow: "auto",
      }
})