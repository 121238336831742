import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { getLoginData, setLoginData } from "../../app/reducers/loginSlice";
import { API_URL } from "../../utils/constants";

async function fetchUserDetailsAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/customer/searchphonenumber`;
  const userData = await axios
    .get(apiURL,{params: {
      phoneNumber: payload},
      headers: headers
    })
    .then((response) => response.data.data);
  return userData;
}   
export function* fetchUserDetails(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchUserDetailsAPI, payload);
    if(response){
       const userData =  response.phoneNumber === payload ? response : {};
       yield put(setLoginData({ ...userData }));
    }
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* loginSaga() {
    
 yield takeLatest(getLoginData.type, fetchUserDetails);
}
