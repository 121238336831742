import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import {
  setFiltersData,
  getBrandDropDowns,
  getGlassDropDowns,
} from "../../../app/reducers/librarySlice";
import { setFiltersDataDemo } from "../../../app/reducers/demoSlice";
import {
  ApplyButton,
  useStyles,
  FooterDrawer,
  CustomSlider,
  FilterPaper,
} from "./styles";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

const followersMarks = [
  {
    value: 0,
    scaledValue: 0,
    label: (
      <div>
        <CurrencyRupeeIcon fontSize="12px" /> 0
      </div>
    ),
  },
  {
    value: 25,
    scaledValue: 2500,
  },
  {
    value: 50,
    scaledValue: 5000,
  },
  {
    value: 75,
    scaledValue: 7000,
  },
  {
    value: 100,
    scaledValue: 10000,
    label: (
      <div>
        <CurrencyRupeeIcon fontSize="12px" /> 10,000
      </div>
    ),
  },
];

const scale = (value) => {
  const previousMarkIndex = Math.floor(value / 25);
  const previousMark = followersMarks[previousMarkIndex];
  const remainder = value % 25;
  if (remainder === 0) {
    return previousMark.scaledValue;
  }
  const nextMark = followersMarks[previousMarkIndex + 1];
  const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;

  return remainder * increment + previousMark.scaledValue;
};

function FilterComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const filter = useSelector((state) => props.isDemo ? state.demoReducer.filterDataDemo : state.libraryReducer.filterData);
  const glassDropDown = useSelector(
    (state) => state.libraryReducer.glassDropDown
  );
  const brandDropDown = useSelector(
    (state) => state.libraryReducer.brandDropDown
  );

  useEffect(() => {
    dispatch(getGlassDropDowns());
    dispatch(getBrandDropDowns());
  }, []);

  const [filterCheckbox, setfilterCheckbox] = useState(filter);

  const handlefilterCheckbox = (event, label, newValue) => {
    if (newValue) {
      setfilterCheckbox({
        ...filterCheckbox,
        LowestSellPrice: newValue[0] * 100,
        HighestSellPrice: newValue[1] * 100,
      });
    } else {
      const value = event.target.value;

      if (
        !_.isEmpty(filterCheckbox?.[label]) &&
        filterCheckbox?.[label].includes(value)
      ) {
        const temp = filterCheckbox?.[label].filter(
          (element) => element !== value
        );

        const obj = Object.assign({}, filterCheckbox, {
          [label]: temp,
        });
        setfilterCheckbox(obj);
      } else {
        const obj = Object.assign({}, filterCheckbox, {
          [label]: _.isEmpty(filterCheckbox?.[label])
            ? [value]
            : [...filterCheckbox?.[label], value],
        });

        setfilterCheckbox(obj);
      }
    }
  };

  const filters = [
    "Gender",
    "Shape",
    "FrameType",
    "Brand",
    "FrameColor",
    "TempleColor",
  ];

  const handleFilterClick = () => {
    if (props.isDemo) {
      dispatch(setFiltersDataDemo(filterCheckbox));
    } else {
      dispatch(setFiltersData(filterCheckbox));
    }
    props.handleFilterClick();
  };

  const handleClearClick = () => {
    if (props.isDemo) {
      dispatch(setFiltersDataDemo({}));
    } else {
      dispatch(setFiltersData({}));
    }
    props.handleFilterClick();
  };

  return (
    <div>
      <FooterDrawer
        anchor={"right"}
        open={props?.open}
        onClose={props?.handleDrawerClose}
      >
        <FilterPaper>
          <div className={classes.filterDrawerHeader}>
            <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>FILTER</Typography>
            <IconButton size="small" onClick={props.handleDrawerClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className={classes.drawerContents}>
            {/* -----------------Slider------------------- */}
            <div style={{ width: "95%" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>PRICE RANGE</Typography>
              <div style={{ width: "100%" }}>
                <CustomSlider
                  style={{ maxWidth: 500 }}
                  value={filterCheckbox.hasOwnProperty("LowestSellPrice") ? [
                    filterCheckbox.LowestSellPrice / 100,
                    filterCheckbox.HighestSellPrice / 100,
                  ] : [0,10]}
                  min={0}
                  step={1}
                  max={100}
                  scale={scale}
                  marks={followersMarks}
                  onChange={(event, newValue) =>
                    handlefilterCheckbox(event, "Slider", newValue)
                  }
                  valueLabelDisplay="auto"
                  aria-labelledby="non-linear-slider"
                />
              </div>
            </div>
            {
              <div>
                <Typography sx={{ pt: "16px", fontSize: "14px", fontWeight: 700 }}>
                  Brand
                </Typography>

                <FormControl component="fieldset" variant="standard">
                  <FormGroup
                    onChange={(event) =>
                      handlefilterCheckbox(event, "Brand", "")
                    }
                    row
                  >
                    {brandDropDown.map((brand, i) => (
                      <FormControlLabel
                        value={brand.name}
                        control={
                          <Checkbox
                            classes={{ checked: classes.checked }}
                            size="small"
                            checked={
                              !_.isEmpty(filterCheckbox.Brand)
                                ? filterCheckbox?.Brand.includes(brand.name)
                                : false
                            }
                            name={brand.name}
                          />
                        }
                        label={brand.name}
                        classes={{ label: classes.checkBoxLabel }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </div>
            }
            {Object.entries(glassDropDown).map((entries, index) => {
              if (filters.includes(entries[0])) {
                return (
                  <div>
                    <Typography sx={{ pt: "16px", fontSize: "14px", fontWeight: 700 }}>
                      {entries[0]}
                    </Typography>

                    <FormControl component="fieldset" variant="standard">
                      <FormGroup
                        onChange={(event) =>
                          handlefilterCheckbox(event, entries[0], "")
                        }
                        row
                      >
                        {entries[1]?.map((entryValue, i) => (
                          <FormControlLabel
                            value={entryValue}
                            control={
                              <Checkbox
                                classes={{ checked: classes.checked }}
                                size="small"
                                checked={
                                  !_.isEmpty(filterCheckbox[entries[0]])
                                    ? filterCheckbox?.[entries[0]].includes(
                                        entryValue
                                      )
                                    : false
                                }
                                name={entryValue}
                              />
                            }
                            label={entryValue}
                            classes={{ label: classes.checkBoxLabel }}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </div>
                );
              }
            })}
            <div style={{ paddingTop: "20px" }}>
              <ApplyButton onClick={handleClearClick}>Clear</ApplyButton>
              <ApplyButton onClick={handleFilterClick}>Apply</ApplyButton>
            </div>
          </div>
        </FilterPaper>
      </FooterDrawer>
    </div>
  );
}

export default FilterComponent;
