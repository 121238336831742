import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getLensCatalog,
  setLensCatalog,
  setBrandNames,
  setInitialLensCatalog,
  setGlassDropDowns,
  getGlassDropDowns
} from "../reducers/lenscatalogSlice";
import { API_URL } from "../../utils/constants";
import _ from "lodash";

async function fetchLensCatalogAPI(filter) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/lenscatalog/fetch`;
  const lenscatalog = await axios
    .get(apiURL, {
      params: {
        filter: {
          lensType: filter.toUpperCase(),
        },
      },
      headers: headers
    })
    .then((response) => response.data.data);
  return lenscatalog;
}
export function* fetchLensCatalog(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchLensCatalogAPI, payload);

    /* Not used in code saved for futre ---START*/
    const brandNames = _.isEmpty(response[0]._id.brand)
      ? ["Regular"]
      : response.map((data) => _.startCase(_.toLower(data._id.brand)));
    const unique = [...new Set(brandNames)];
    /* Not used in code saved for futre  ---END*/

    const initialData = _.isEmpty(response[0]._id.brand)
      ? response
      : response.filter((catalog) => catalog?._id.brand === "REGULAR");

    /* Not used in code saved for futre ---START*/
    yield put(setBrandNames(unique));
    /* Not used in code saved for futre  ---END*/

    yield put(setLensCatalog(response));
    yield put(setInitialLensCatalog(initialData));

  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function fetchGlassesDropdownAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = process.env.REACT_APP_API_URL + `/v2/inventory/common/meta/getv1`;
  const products = axios
    .get(apiURL, {
      headers: headers,
    })
    .then((response) => response.data.data);
  return products;
}

export function* fetchGlassesDropdown(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchGlassesDropdownAPI, payload);
    yield put(setGlassDropDowns(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* lenscatalogSaga() {
  yield takeLatest(getLensCatalog.type, fetchLensCatalog);
  yield takeLatest(getGlassDropDowns.type, fetchGlassesDropdown);
}
