import React from "react";

export const ScannerIcon = (props) => {
  const color = props?.color ?? "#827e7e";
  const width = props?.width ?? "21.5";
  const height = props?.height ?? "21.5";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="110"
      viewBox="0 0 110 110"
    >
      <defs>
        <filter
          id="g8x407vfaa"
          x="0"
          y="0"
          width="110"
          height="110"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="10" result="blur" />
          <feFlood floodColor="#03a5a5" floodOpacity=".2" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 2151">
        <g  >
          <rect
            data-name="Rectangle 1040"
            width="50"
            height="50"
            rx="25"
            transform="translate(30 20)"
            fill="#03a5a5"
          />
        </g>
        <g data-name="vuesax/outline/scan">
          <path
            d="M.75 8.5A.755.755 0 0 1 0 7.75v-2.5A5.256 5.256 0 0 1 5.25 0h2.5a.755.755 0 0 1 .75.75.755.755 0 0 1-.75.75h-2.5A3.751 3.751 0 0 0 1.5 5.25v2.5a.755.755 0 0 1-.75.75z"
            transform="translate(44.25 34.25)"
            fill="#fff"
          />
          <path
            data-name="Vector"
            d="M7.75 8.5A.755.755 0 0 1 7 7.75v-2.5A3.751 3.751 0 0 0 3.25 1.5H.75A.755.755 0 0 1 0 .75.755.755 0 0 1 .75 0h2.5A5.256 5.256 0 0 1 8.5 5.25v2.5a.755.755 0 0 1-.75.75z"
            transform="translate(57.25 34.25)"
            fill="#fff"
          />
          <path
            data-name="Vector"
            d="M2.25 7.5H.75a.75.75 0 0 1 0-1.5h1.5A3.751 3.751 0 0 0 6 2.25V.75a.75.75 0 0 1 1.5 0v1.5A5.256 5.256 0 0 1 2.25 7.5z"
            transform="translate(58.25 48.25)"
            fill="#fff"
          />
          <path
            data-name="Vector"
            d="M7.75 8.5h-2.5A5.256 5.256 0 0 1 0 3.25V.75A.755.755 0 0 1 .75 0a.755.755 0 0 1 .75.75v2.5A3.751 3.751 0 0 0 5.25 7h2.5a.75.75 0 0 1 0 1.5z"
            transform="translate(44.25 47.25)"
            fill="#fff"
          />
          <path
            data-name="Vector"
            d="M7.75 12.5h-4A3.381 3.381 0 0 1 0 8.75v-5A3.381 3.381 0 0 1 3.75 0h4a3.381 3.381 0 0 1 3.75 3.75v5a3.381 3.381 0 0 1-3.75 3.75zm-4-11c-1.58 0-2.25.67-2.25 2.25v5c0 1.58.67 2.25 2.25 2.25h4C9.33 11 10 10.33 10 8.75v-5c0-1.58-.67-2.25-2.25-2.25z"
            transform="translate(49.25 38.75)"
            fill="#fff"
          />
          <path
            data-name="Vector"
            d="M14.75 1.5h-14A.755.755 0 0 1 0 .75.755.755 0 0 1 .75 0h14a.755.755 0 0 1 .75.75.755.755 0 0 1-.75.75z"
            transform="translate(47.25 44.25)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
