import React from "react";
import { Sidebar, NavBar } from "../../components";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  content: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflow: "auto",
  },
})


const withNavBars = (Component) => (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div >
      <NavBar handleDrawerOpen={handleDrawerOpen} header={location?.state?.subHeader} isBack={location?.state?.isBack} />
      <Sidebar handleDrawerClose={handleDrawerClose} open={open} location={location} />

      <div style={{backgroundColor:"#f5f5f5"}} className={classes.content}>
          <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
