import _ from 'lodash';

const lensPower = () => {
  const sphericalLength = 24;
  const cylindricalLength = 6;
  const axisLength = 180;
  const addLength = 4;
  
  let spherical = [
    {
      value: 0,
      label: 0,
    },
  ];
  let axis = [];
  let cylindrical = [
    {
      value: 0,
      label: 0,
    },
  ];
  let add = [];
  let sphericalIncBy = 0.25;
  const cylindricalIncBy = 0.25;
  const addIncBy = 0.25;
  const axisIncBy = 1;

  for (let i = 0.25; i <= sphericalLength; i += sphericalIncBy) {
    spherical.push({
      value: i,
      label: i,
    });

    spherical.push({
      value: -i,
      label: -i,
    });

    if (i >= 8) {
      sphericalIncBy = 0.5;
    }
  }

  for (let i = 0.25; i <= cylindricalLength; i += cylindricalIncBy) {
    cylindrical.push({
      value: i,
      label: i,
    });

    cylindrical.push({
      value: -i,
      label: -i,
    });
  }

  for (let i = 0; i <= addLength; i += addIncBy) {
    add.push({
      value: i,
      label: i,
    });
  }

  for (let i = 1; i <= axisLength; i += axisIncBy) {
    axis.push({
      value: i,
      label: i,
    });
  }

  spherical = _.sortBy(spherical, (obj) => obj.label);
  cylindrical = _.sortBy(cylindrical, (obj) => obj.label);
  axis = _.sortBy(axis, (obj) => obj.label);
  add = _.sortBy(add, (obj) => obj.label);

  return {
    spherical,
    axis,
    cylindrical,
    add,
  };
};

export default lensPower;