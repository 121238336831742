import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getTransactionsData,
  getTransactionsDataSuccess,
  getTransactionsDataError
} from "../reducers/transactionsSlice"
import { API_URL } from "../../utils/constants";

async function fetchTransactionsDataAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const storeId = localStorage.getItem("storeId");
  const apiURL = `${API_URL}/client/reports/transactions`;
  const userData = await axios
    .get(apiURL, {
      params: {
        limit: 15,
        page: payload.page,
        searchDate: payload.date,
        storeId: storeId,
        filter: {
            storeId: storeId
        },
        sort: { _created_at: -1 },
      },
      headers: headers
    })
    .then((response) => response);
  return userData;
}

export function* fetchTransactionsData(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchTransactionsDataAPI, payload);
    yield put(getTransactionsDataSuccess(response.data.data));
  } catch (error) {
    yield put(getTransactionsDataError(error.response.data.message[0].msg));
  }
}

export function* transactionsSaga() {
  yield takeLatest(getTransactionsData.type, fetchTransactionsData);
}
