import React from "react";

export const InfoIcon = (props) => {
    const color = props?.color ?? "#03a5a5";
    const width = props?.width ?? "17";
    const height = props?.height ?? "17";
  return (
    <svg
      data-name="vuesax/linear/info-circle"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 17"
    >
      <path
        d="M7.083 0A7.083 7.083 0 1 1 0 7.083 7.1 7.1 0 0 1 7.083 0z"
        transform="translate(1.417 1.417)"
        // style="stroke:#03a5a5;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5px"
        fill="none"
      />
      <path
        data-name="Vector"
        d="M0 4.183V0"
        transform="translate(8.5 8.15)"
        // style="stroke:#03a5a5;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5px"
        fill="none"
      />
      <path
        data-name="Vector"
        d="M0 0h.006"
        transform="translate(8.496 5.667)"
        // style="stroke:#03a5a5;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill:none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5px"
        fill="none"
      />
    </svg>
  );
};
