import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withNavBars } from "../../common/hocs";
import { ContinueButton, ListTable, SearchIcon } from "../../components";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../router/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  getConsumerList,
  clearConsumer,
  setConsumerListError,
  setPageno,
} from "../../app/reducers/consumerSlice";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import { AlertComp } from "../../components/Alert";
import _ from "lodash";
import { useIsMount } from "../../common/hooks/customHooks";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

function ListInvoices(props) {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const isMount = useIsMount();
  
  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );
  const consumerList = useSelector(
    (state) => state.consumerReducer.consumerData
  );
  const pageNo = useSelector((state) => state.consumerReducer.pageNo);
  const searchData = useSelector((state) => state.consumerReducer.searchDataConsumer);
  const hasMore = useSelector((state) => state.consumerReducer.hasMore);
  const consumerDataError = useSelector(
    (state) => state.consumerReducer.consumerDataError
  );

  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState("");


  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(storeChange)) {
      dispatch(clearConsumer());
      dispatch(getConsumerList({ page:0 , search: searchStr}));
    }
  }, [storeChange]);

  useEffect(() => {
    setLoading(false);
  }, [consumerList]);

  const handleNewConsumer = () => {
    history(RoutePath?.newConsumer);
  };

  const handleAlertClose = () => {
    dispatch(setConsumerListError(""));
  };

  let tableData = [];

  const data = consumerList?.map((consumer) => {
    const consumerData = {
      consumerName: consumer?.customerName,
      mobileNumber: consumer?.phoneNumber,
      email: consumer?.emailId || "-",
    };
    tableData.push(consumerData);

    return tableData;
  });

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPageno(pageNo + 1));
    dispatch(getConsumerList({ page:pageNo + 1 , search: searchStr}));
  };

  const handleSearch = (e) => {
    setSearchStr(e.target.value);
  }

  
  useEffect(() => {
    const timeOut =  setTimeout(() =>  !isMount ? dispatch(getConsumerList({ page:0 , search: searchStr})) : "", 1000);
    return () => clearTimeout(timeOut);
  },[searchStr])

  return (
    <div>
      <Grid
        container
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
        }}
        justifyContent="flex-end"
      >
        {consumerDataError ? (
          <AlertComp
            type="error"
            message={consumerDataError}
            handleAlertClose={handleAlertClose}
          />
        ) : (
          ""
        )}
        <Grid item xs={6} sx={{ padding: "12px 24px" }}>
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search Phone Number/Consumer Name"
            fullWidth
            variant="outlined"
            onChange={handleSearch}
            value={searchStr}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon width={"16"} height={"16"} />
                </InputAdornment>
              ),
              classes: {
                root: classes.notchedOutline,
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: "35px" }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            padding: "12px 24px 0px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ContinueButton onClick={handleNewConsumer}>
            New Consumer
          </ContinueButton>
        </Grid>
      </Grid>
      <div
        style={{
          padding: "90px 24px 40px",
          height: "calc(100vh - 200px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <ListTable
          dataList={tableData}
          isLoading={loading}
          handleLoadmore={handleLoadmore}
          hasMore={hasMore}
          Header={["Consumer Name", "Mobile Number", "Email ID"]}
          paddingLeft="100px"
          tableData={[
            { type: ["TEXT"], name: "consumerName" },
            { type: ["TEXT"], name: "mobileNumber" },
            { type: ["TEXT"], name: "email" },
          ]}
        />
      </div>
    </div>
  );
}

export default withNavBars(ListInvoices);
