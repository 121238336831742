import { Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { ContinueButton, DownloadIcon, ListTable, OrderIndentIcon } from "../../components";
import React, { useEffect } from "react";
import { withNavBars } from "../../common/hocs";
import { useNavigate } from "react-router-dom";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { getReportDatav2 } from "../../app/reducers/reportSlice";
import moment from "moment";
import _ from "lodash";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function Eodv2(props) {
    const today = moment().format("D MMM YYYY");
    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem("eyePlay-auth-token");
        if (token) {
            dispatch(getPermittedStore());
        }
    }, []);

    const storeChange = useSelector(
        (state) => state.storeLoginReducer.selectedStoreId
    );

    useEffect(() => {
        const token = localStorage.getItem("eyePlay-auth-token");
        if (_.isEmpty(token)) {
            history("/");
        }

        if (token && _.isEmpty(storeChange)) {
            dispatch(getPermittedStore());
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(storeChange)) {
            dispatch(getReportDatav2());
        }
    }, [storeChange]);
    const eodData = useSelector(
        (state) => state.reportReducerv2.response
    );
    const tableData = []
    const totalSalesSummary = eodData ? eodData.totalSalesSummary : []
    const array = totalSalesSummary.map((data) => {
        const table = {
            type: data.type,
            totalOrders: data.totalOrders,
            taxableAmount: Number(data.taxableAmount).toFixed(2),
            totalGst: Number(data.totalGst).toFixed(2),
            totalAmount: Number(data.totalAmount).toFixed(2),
            abv: data.abv || "-"
        }
        tableData.push(table);
        return tableData;
    })
    const DownloadIconProps = {
        color: "#FFF"
    }
    return (
        <div>
            <Grid
                container
                alignItems={"center"}
                sx={{
                    position: "fixed",
                    backgroundColor: "#f5f5f5",
                    width: "calc(100% - 20px)",
                    position: "static"
                }}
                justifyContent="flex-end"
            >
                <Grid
                    container
                    alignItems={"center"}
                    sx={{

                    }}
                >
                    <Grid item xs={6} sx={{ padding: "12px 24px" }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontSize: "20px",
                                fontWeight: 600,
                            }}
                        >
                            Sales Summary
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sx={{
                            padding: "12px 24px",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <MobileDatePicker
                                inputFormat="MM/dd/yyyy"
                                renderInput={(params) => (
                                    <TextField  {...params} sx={{
                                        "& .MuiInputBase-root": {
                                            borderRadius: "100px"
                                        },
                                    }} />
                                )}
                                className="date-picker"
                            />
                        </LocalizationProvider>
                        <ContinueButton >
                            <DownloadIcon { ...DownloadIconProps}/>  <span style={{ margin: "5px" }}>Download</span>
                        </ContinueButton>
                    </Grid>
                </Grid>

            </Grid>
            <div
                style={{
                    padding: "0px 24px 40px",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <ListTable
                    dataList={eodData ? tableData : []}
                    Header={[
                        "",
                        "Orders",
                        `Taxable `,
                        "GST",
                        "Total",
                        "ABV"
                    ]}
                    tableData={[
                        { type: ["EOD"], name: "type" },
                        { type: ["TEXT"], name: "totalOrders" },
                        { type: ["TEXT"], name: "taxableAmount" },
                        { type: ["TEXT"], name: "totalGst" },
                        { type: ["TEXT"], name: "totalAmount" },
                        { type: ["TEXT"], name: "abv" },
                    ]}
                />
            </div>

            <Grid
                container
                alignItems={"center"}
                sx={{
                    position: "fixed",
                    backgroundColor: "#f5f5f5",
                    width: "calc(100% - 20px)",
                    position: "static",
                }}
                justifyContent="flex-end"
            >
                <Grid item xs={12} sx={{ padding: "12px 24px", justifyContent: "flex-start", }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: "20px",
                            fontWeight: 600,
                        }}
                    >
                        Order Summary, <span style={{ fontWeight: 300 }}> Today ({today})</span>
                    </Typography>
                </Grid>
            </Grid>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    aligntItems: "center", padding: "12px 24px",
                }}
            >
                <Grid container >
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                CASH
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                &#8377; {eodData && eodData.orderSummary.totalCashPayment}
                            </Typography>
                        </CardContent>
                    </Card >
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                CARD
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                &#8377; {eodData && eodData.orderSummary.totalCardPayment}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                UPI
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                &#8377; {eodData && eodData.orderSummary.totalUpiPayment}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                TOTAL
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                &#8377; {eodData && eodData.orderSummary.totalPayment}
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid>
            </div>
            <div
                style={{
                    padding: "0px 24px 40px",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <ListTable
                    dataList={eodData ? eodData.orderSummary.orders : []}
                    Header={[
                        "Order ID",
                        "Order Amount",
                        "Cash",
                        "Card",
                        "UPI",
                    ]}
                    tableData={[
                        { type: ["TEXT"], name: "_id" },
                        { type: ["TEXT"], name: "orderAmount" },
                        { type: ["TEXT"], name: "totalCashPayment" },
                        { type: ["TEXT"], name: "totalCardPayment" },
                        { type: ["TEXT"], name: "totalUpiPayment" },
                    ]}
                />
            </div>

            <Grid
                container
                alignItems={"center"}
                sx={{
                    position: "fixed",
                    backgroundColor: "#f5f5f5",
                    width: "calc(100% - 20px)",
                    position: "static"
                }}
                justifyContent="flex-end"
            >
                <Grid item xs={12} sx={{ padding: "12px 24px", justifyContent: "flex-start", }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: "20px",
                            fontWeight: 600,
                        }}

                    >
                        Invoice Summary,<span style={{ fontWeight: 300 }}> Today ({today})</span>
                    </Typography>
                </Grid>
            </Grid>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    aligntItems: "center", padding: "12px 24px",
                }}
            >
                <Grid container >
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                CASH
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                &#8377; {eodData && eodData.orderSummary.totalCashPayment}
                            </Typography>
                        </CardContent>
                    </Card >
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                CARD
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                &#8377; {eodData && eodData.orderSummary.totalCardPayment}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                UPI
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                &#8377; {eodData && eodData.orderSummary.totalUpiPayment}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                TOTAL
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                &#8377; {eodData && eodData.orderSummary.totalPayment}
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid>
            </div>
            <div
                style={{
                    padding: "0px 24px 40px",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <ListTable
                    dataList={eodData ? eodData.invoiceSummary.invoices : []}
                    Header={[
                        "Order ID",
                        "Order Amount",
                        "Cash",
                        "Card",
                        "UPI",
                    ]}
                    tableData={[
                        { type: ["TEXT"], name: "_id" },
                        { type: ["TEXT"], name: "orderAmount" },
                        { type: ["TEXT"], name: "totalCashPayment" },
                        { type: ["TEXT"], name: "totalCardPayment" },
                        { type: ["TEXT"], name: "totalUpiPayment" },
                    ]}
                />
            </div>

            <Grid
                container
                alignItems={"center"}
                sx={{
                    position: "fixed",
                    backgroundColor: "#f5f5f5",
                    width: "calc(100% - 20px)",
                    position: "static"
                }}
                justifyContent="flex-end"
            >
                <Grid item xs={12} sx={{ padding: "12px 24px", justifyContent: "flex-start", }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: "20px",
                            fontWeight: 600,
                        }}
                    >
                        Payment Summary,<span style={{ fontWeight: 300 }}> Today ({today})</span>
                    </Typography>
                </Grid>
            </Grid>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    aligntItems: "center", padding: "12px 24px",
                }}
            >
                <Grid container >
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                CASH
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                &#8377; {eodData && eodData.orderSummary.totalCashPayment}
                            </Typography>
                        </CardContent>
                    </Card >
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                CARD
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                &#8377; {eodData && eodData.orderSummary.totalCardPayment}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                UPI
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                &#8377; {eodData && eodData.orderSummary.totalUpiPayment}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ minWidth: 275, marginRight: 10 }}>
                        <CardContent sx={{ flex: '3 0 auto' }}>
                            <Typography
                                sx={{
                                    color: "#827e7e",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }} >
                                TOTAL
                            </Typography>
                            <Typography
                                sx={{
                                    color: "#2b2b2b",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    fontStretch: "normal",
                                    fontStyle: "normal",
                                    lineHeight: 1.88,
                                    letterSpacing: "0.8px",
                                    textAlign: "left",
                                }}
                            >
                                &#8377; {eodData && eodData.orderSummary.totalPayment}
                            </Typography>
                        </CardContent>
                    </Card>

                </Grid>
            </div>
        </div>
    );
}

export default withNavBars(Eodv2);
