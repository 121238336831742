import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import { Typography } from "@mui/material";

export const useStyles = makeStyles({
  catalogCard: {
    padding: "4px 4px 16px",
    backgroundColor: "#fff",
    borderRadius:"4px",
    cursor:"pointer"
  },
  listCard: {
    padding: "8px 24px 24px",
    backgroundColor: "#fff",
  },
  listImgDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  catalogImgDiv: {
    alignItems: "center",
    position:"relative"
  },
  listExchange: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  },
  listImg: {
    borderRadius: "0px",
  },
  catalogImg: {
    borderRadius: "5px",
  },
  listContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  catalogContent: {
    alignItems: "center",
  },
  catalogTitle: {
      fontSize: "12px ",
      color: "#242424",
      padding: "12px 0px 0px 8px",
  },
  catalogExpandIcon:{
      position:"absolute",
      right:"12px",
      bottom:"0px",
  },
  deleteIcon: {
    position:"absolute",
    right:"10px",
    top:"10px",
  }
});

export const CatalogTitle = styled(Typography)(({ theme }) => ({
    fontSize: "14px ",
    color: "#242424",
    padding: "12px 0px 0px 8px",
}));

export const CatalogAmount = styled(Typography)(({ theme }) => ({
    fontSize: "16px ",
    color: "#2b2b2b",
    padding: "0px 0px 0px 8px",
    fontWeight:600
}));

export const ListHeader = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    color: "#2b2b2b",
    padding: "0px 8px",
    borderRight: "1px solid #2b2b2b",
}));


