import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    getInvoiceData,
  setInvoiceData,
} from "../../app/reducers/invoiceSlice";
import { API_URL } from "../../utils/constants";

async function fetchInvoiceAPI(payload) {
  const apiURL = `${API_URL}/client/storeorder/invoices`;
  const storeId = localStorage.getItem("storeId");
  const { page, search } = payload;
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const invoices = axios
    .get(apiURL, {
      params: {
        limit: 15,
        page: page,
        storeId: storeId,
        search : search,
        filter: {},
        sort: {"_created_at": -1}
      },
      headers: headers
    })
    .then((response) => response.data.data);
  return invoices;
}
export function* fetchInvoice(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchInvoiceAPI, payload);
      yield put(setInvoiceData(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* invoiceSaga() {
  yield takeLatest(getInvoiceData.type, fetchInvoice);
}
