import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  exchangeDetails: [],
  inventory: "",
  inventories: [],
  savedExchange: {},
  pageNoExchange: 0,
  hasMoreExchange: true,
  deliveryStatus: {},
  shipmentUpdate: {}
};

export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    getExchangeData() {},
    setExchangeData(state, action) {
      return Object.assign({}, state, {
        exchangeDetails: state.pageNoExchange > 0 ? [...state.exchangeDetails, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
        hasMoreExchange: action.payload.length < 15 ? false : true 
      });
    },
    getInventoryWithBarecode() {},
    setInventoryWithBarecode(state, action) {
      return Object.assign({}, state, {
        inventory: isEmpty(action.payload) ? {} : action.payload,
      });
    },
    setInventories(state, action) {
        return Object.assign({}, state, {
            inventories: isEmpty(action.payload) ? state.inventories : [...state.inventories, action.payload],
        })
    },
    removeInventory(state, action) {
        return Object.assign({}, state, {
            inventories: state.inventories.filter( (item, i)  =>  i!== action.payload),
        })
    },
    getShipmentUpdate() {},
    shipmentUpdateSuccess(state, action) {
      return Object.assign({}, state, {
          shipmentUpdate: action.payload,
      })
    },

    confirmDelivery() {},
    confirmDeliverySuccess(state, action) {
      return Object.assign({}, state, {
          deliveryStatus: action.payload,
      })
    },
    getSaveExchange() {},
    setSaveExchange(state, action) {
        return Object.assign({}, state, {
            savedExchange: action.payload,
        })
    },
    setPagenoExchange(state, action) {
      return Object.assign({}, state, {
        pageNoExchange: action.payload,
      });
    },
    

    clearExchange(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getExchangeData,
  setExchangeData,
  getShipmentUpdate,
  confirmDelivery,
  getInventoryWithBarecode,
  setInventoryWithBarecode,
  setInventories,
  removeInventory,
  setSaveExchange,
  getSaveExchange,
  clearExchange,
  setPagenoExchange,
  confirmDeliverySuccess,
  shipmentUpdateSuccess
} = exchangeSlice.actions;
export default exchangeSlice.reducer;
