import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Box,
  Typography,
  Divider,
  Dialog,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { ContinueButton, SearchIcon } from "../../components";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckoutBox from "../Inventory/Checkout/CheckoutBox";
import { SmsIcon } from "../../components/svg/SmsIcon";
import { CallIcon } from "../../components/svg/CallIcon";
import { FrameIcon } from "../../components/svg/FrameIcon";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { TickIcon } from "../../components/svg/TickIcon";
import {
  getFilteredOrder,
  getNewReturns,
} from "../../app/reducers/returnsSlice";
import ViewDetail from "../Inventory/Checkout/ViewDetail";
import { Footer } from "../../components";
import moment from "moment";
import _, { isEmpty } from "lodash";
import { height } from "@mui/system";
import { HeadTypography, LabelTypography, useStyles } from "./styles";

const NewReturns = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchStr, setSearchStr] = useState("");
  const [isOrder, setIsOrder] = useState(false);
  const [checkBoxData, setCheckBoxData] = useState({});
  const [viewDetail, setViewDetail] = useState(false);
  const [viewDetailData, setViewDetailData] = useState({});
  const [openReturns, setOpenReturns] = useState(false);
  const [success, setSuccess] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonComments, setReasonComments] = useState("");

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    td: {
      border: 0,
    },
  }));

  const returnReasons = ["Broken", "Damaged", "Not the required One"];
  const filteredOrder = useSelector((state) => state.returnsReducer.orderData);
  const returnsAdded = useSelector((state) => state.returnsReducer.returnsAdded);

  const orders = filteredOrder;
  const quantity = 1;

  useEffect(() => { }, [filteredOrder]);

  useEffect(() => {
    if (!_.isEmpty(returnsAdded)) {
      setSuccess(true);
    }
  }, [returnsAdded]);

  const onChangeSearch = (e) => {
    setSearchStr(e.target.value);
  };

  const handleViewClose = () => {
    setViewDetail(false);
  };

  const handleSearch = () => {
    dispatch(getFilteredOrder(searchStr));
    setIsOrder(true);
  };

  const handleContinueButton = () => {
    setOpenReturns(true);
  };

  const handleClose = () => {
    setOpenReturns(false);
  };
  const handleDropDownChange = (newValue) => {
    setReason(newValue);
  };
  const handleReasonComments = (event) => {
    setReasonComments(event.target.value);
  };
  const backToReturns = () => {
    props.backToReturns();
  };

  const handleConfirmReturn = () => {
    let arr = [];
    const products = orders?.products.map((product) => {
      const data = Object.keys(checkBoxData).map((id) => {
        if (product._id === id) {
          if (
            checkBoxData[id]?.frame === true &&
            checkBoxData[id]?.lens !== true
          ) {
            const obj = {
              productId: product.productId,
              name: product.name,
              price: product.price,
              gst: product.gst,
              inclGst: product.inclGst,
              prescriptions: product?.prescriptions,
              frameSize: product?.frameSize,
            };
            arr.push(obj);
          } else if (
            checkBoxData[id]?.frame !== true &&
            checkBoxData[id]?.lens === true
          ) {
            const obj = {
              lensItems: product?.lensItems,
              prescriptions: product?.prescriptions,
              frameSize: product?.frameSize,
            };
            arr.push(obj);
          } else if (
            checkBoxData[id]?.frame === true &&
            checkBoxData[id]?.lens === true
          ) {
            const obj = {
              productId: product.productId,
              name: product.name,
              price: product.price,
              gst: product.gst,
              inclGst: product.inclGst,
              prescriptions: product?.prescriptions,
              frameSize: product?.frameSize,
            };
            const obj1 = {
              lensItems: product?.lensItems,
              prescriptions: product?.prescriptions,
              frameSize: product?.frameSize,
            };
            arr.push(obj);
            arr.push(obj1);
          } else {
            arr.push({});
          }
        } else {
          return;
        }
      });
    });

    const obj = {
      orderId: orders?.orderId,
      storeId: orders?.storeId,
      orderDate: orders?._created_at,
      reason: reason,
      comments: reasonComments,
      products: arr,
      customerDetails: {
        customerId: orders?.customerDetails?.customerId,
        name: orders?.customerDetails?.name,
        phoneNumber: orders?.customerDetails?.phoneNumber,
        emailId: orders?.customerDetails?.emailId,
      },
    };
    setOpenReturns(false);
    dispatch(getNewReturns(obj));
  };

  const handleChange = (event, Id, isFrame) => {
    const data = {
      [Id]: {
        frame:
          isFrame === "all"
            ? event.target.checked
            : isFrame === "frame"
              ? event.target.checked
              : checkBoxData[Id]?.frame,
        lens:
          isFrame === "all"
            ? event.target.checked
            : isFrame === "lens"
              ? event.target.checked
              : checkBoxData[Id]?.lens,
      },
    };
    setCheckBoxData({ ...checkBoxData, ...data });
  };

  const handleViewDetails = (event, product) => {
    setViewDetail(true);
    setViewDetailData(product);
  };

  return (
    <>
      {_.isEmpty(filteredOrder) ? (
        <div>
          <Grid
            container
            sx={{
              position: "fixed",
              backgroundColor: "#f5f5f5",
              width: "100%",
              zIndex: 1000,
              height: isOrder ? "unset" : "100vh",
            }}
          >
            <Grid item xs={6} sx={{ padding: "12px 24px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Search Order ID"
                  fullWidth
                  variant="outlined"
                  onChange={onChangeSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon width={"16"} height={"16"} />
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.notchedOutline,
                    },
                  }}
                  style={{ backgroundColor: "#fff", borderRadius: "35px" }}
                />
                <ContinueButton onClick={handleSearch}>Search</ContinueButton>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              height: "calc(100vh - 128px)",
              paddingTop: "80px",
              overflow: "auto",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ backgroundColor: "#f5f5f5", padding: "30px" }}
            >
              <Typography sx={{ color: "#000" }}>
                No matching order ID found please try with another ID !!!
              </Typography>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <Grid
            container
            sx={{
              position: "fixed",
              backgroundColor: "#f5f5f5",
              width: "100%",
              zIndex: 1000,
              height: isOrder ? "unset" : "100vh",
            }}
          >
            <Grid item xs={6} sx={{ padding: "12px 24px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="input-with-icon-textfield"
                  placeholder="Search Order ID"
                  fullWidth
                  variant="outlined"
                  onChange={onChangeSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon width={"16"} height={"16"} />
                      </InputAdornment>
                    ),
                    classes: {
                      root: classes.notchedOutline,
                    },
                  }}
                  style={{ backgroundColor: "#fff", borderRadius: "35px" }}
                />
                <ContinueButton onClick={handleSearch}>Search</ContinueButton>
              </div>
            </Grid>
          </Grid>
          {isOrder && (
            <>
              <Grid
                container
                sx={{
                  height: "calc(100vh - 128px)",
                  paddingTop: "60px",
                  overflow: "auto",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={8}
                  lg={8}
                  sx={{ backgroundColor: "#f5f5f5" }}
                >
                  <CheckoutBox header="Consumer Details">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        p: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingRight: "25px",
                        }}
                      >
                        <FrameIcon />
                        <Typography>{orders?.customerDetails?.name}</Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingRight: "25px",
                        }}
                      >
                        <CallIcon />
                        <Typography>
                          +91{orders?.customerDetails?.phoneNumber}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingRight: "25px",
                        }}
                      >
                        <SmsIcon />
                        <Typography>
                          {orders?.customerDetails?.emailId}
                        </Typography>
                      </div>
                    </Box>
                  </CheckoutBox>

                  <CheckoutBox header="Product Summary">
                    {orders?.products.map((product, index) => (
                      <>
                        <TableContainer component={Box}>
                          <Table
                            sx={{ minWidth: 700 }}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "select all desserts",
                                    }}
                                    checked={
                                      checkBoxData[product?._id]?.frame &&
                                      checkBoxData[product?._id]?.lens
                                    }
                                    indeterminate={
                                      checkBoxData[product?._id]?.frame !==
                                      checkBoxData[product?._id]?.lens
                                    }
                                    onChange={(event) =>
                                      handleChange(event, product._id, "all")
                                    }
                                  />
                                </TableCell>
                                <TableCell width={"400px"}>
                                  Product Description
                                </TableCell>
                                <TableCell align="left">
                                  Unit Cost(&#8377;)
                                </TableCell>
                                <TableCell align="left">Tax</TableCell>
                                <TableCell align="left">Qty</TableCell>
                                <TableCell align="left">
                                  Total Cost(&#8377;)
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            {
                              <TableBody>
                                <StyledTableRow key={product._id}>
                                  <TableCell>
                                    <Checkbox
                                      color="primary"
                                      checked={
                                        isEmpty(checkBoxData[product?._id])
                                          ? false
                                          : checkBoxData[product?._id]?.frame
                                      }
                                      onChange={(event) =>
                                        handleChange(
                                          event,
                                          product._id,
                                          "frame"
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "select all desserts",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left">
                                    {product.name}
                                  </TableCell>
                                  <TableCell align="left">
                                    {product.price}
                                  </TableCell>
                                  <TableCell align="left">
                                    {(
                                      (product.price *
                                        (product.name.includes("EYEGLASSES")
                                          ? 12
                                          : 18)) /
                                      100
                                    )
                                      .toString()
                                      .concat(
                                        `(${product.name.includes("EYEGLASSES")
                                          ? 12
                                          : 18
                                        })%`
                                      )}
                                  </TableCell>
                                  <TableCell align="left">{quantity}</TableCell>
                                  <TableCell align="left">
                                    {product.inclGst}
                                  </TableCell>
                                </StyledTableRow>
                                {!isEmpty(product.lensItems) ? (
                                  <StyledTableRow key={index}>
                                    <TableCell>
                                      <Checkbox
                                        color="primary"
                                        checked={
                                          isEmpty(checkBoxData[product?._id])
                                            ? false
                                            : checkBoxData[product?._id]?.lens
                                        }
                                        onChange={(event) =>
                                          handleChange(
                                            event,
                                            product._id,
                                            "lens"
                                          )
                                        }
                                        inputProps={{
                                          "aria-label": "select all desserts",
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell align="left">
                                      {
                                        product.lensItems.brand
                                        + " " +
                                        product.lensItems.lensType
                                        + " " +
                                        product.lensItems.lensCoating
                                        + " " +
                                        product.lensItems.index
                                      }
                                    </TableCell>
                                    <TableCell align="left">
                                      {product.lensItems.price}
                                    </TableCell>
                                    <TableCell align="left">
                                      {((product.lensItems?.price * 12) / 100)
                                        .toString()
                                        .concat("(12%)")}
                                    </TableCell>
                                    <TableCell align="left">
                                      {quantity}
                                    </TableCell>
                                    <TableCell align="left">
                                      {product.lensItems.inclGst}
                                    </TableCell>
                                  </StyledTableRow>
                                ) : (
                                  ""
                                )}
                              </TableBody>
                            }
                          </Table>
                        </TableContainer>
                        <Divider />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <div
                            style={{
                              padding: "20px",
                              textAlign: "center",
                              color: "#ff6f00",
                            }}
                            onClick={(event) =>
                              handleViewDetails(event, product)
                            }
                          >
                            <Typography
                              sx={{
                                fontSize: "10pt",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                            >
                              View Prescription &amp; Frame Size
                            </Typography>
                          </div>
                        </div>
                        <Divider />
                      </>
                    ))}
                  </CheckoutBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  sx={{ backgroundColor: "#f5f5f5" }}
                >
                  <CheckoutBox header="Order Details">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "0px",
                        padding: "12px",
                      }}
                    >
                      <Typography>{orders?.orderId} </Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                          {moment(orders?.orderDate).format("ll")}
                        </Typography>
                      </div>
                    </div>
                  </CheckoutBox>
                </Grid>
              </Grid>{" "}
            </>
          )}
          {/* </Grid> */}
          <Footer
            handleContinueButton={handleContinueButton}
            onContinue={"Confirm"}
          />
          <ViewDetail
            open={viewDetail}
            handleViewClose={handleViewClose}
            data={viewDetailData}
          />
          {success ? (
            <div>
              <Dialog
                open={success}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ borderRadius: "8px" }}
              >
                <div style={{ padding: "20px 60px 40px" }}>
                  <div style={{ textAlign: "center" }}>
                    <div style={{ paddingLeft: "25px" }}>
                      <TickIcon />
                    </div>
                    <Typography
                      id="modal-modal-description"
                      sx={{ mt: 2, mb: 3 }}
                    >
                      Returns Placed Successfully
                    </Typography>
                    <ContinueButton onClick={backToReturns}>
                      Back To Returns
                    </ContinueButton>
                  </div>
                </div>
              </Dialog>
            </div>
          ) : (
            " "
          )}
          {openReturns ? (
            <div>
              <Dialog
                open={openReturns}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ borderRadius: "8px" }}
                PaperProps={{
                  classes: { root: classes?.deliveryPaper },
                }}
              >
                <div className={classes?.deliverDialogBox}>
                  <div className={classes?.alignFlex}>
                    <HeadTypography>Confirm Returns</HeadTypography>
                    <CloseIcon onClick={handleClose} />
                  </div>
                  <div style={{ margin: "10px 0px" }}>
                    <Grid container>
                      <Grid item xs={12} md={10}>
                        <div style={{ paddingBottom: "12px" }}>
                          <LabelTypography sx={{ pb: "8px" }}>
                            REASON
                          </LabelTypography>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={returnReasons}
                            sx={{ width: 180 }}
                            onChange={(event, newValue) => {
                              handleDropDownChange(newValue);
                            }}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                className={classes.outline}
                                sx={{
                                  backgroundColor: "#fff",
                                  borderRadius: "25px",
                                }}
                                {...params}
                                placeholder="Select"
                              />
                            )}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <div style={{ marginBottom: "20px" }}>
                      <Grid container>
                        <Grid item xs={12} md={10}>
                          <LabelTypography sx={{ pb: "8px" }}>
                            COMMENTS
                          </LabelTypography>
                          <TextField
                            fullWidth
                            helperText="Comments are Mandatory"
                            error={_.isEmpty(reasonComments)}
                            multiline
                            rows={4}
                            onChange={handleReasonComments}
                            placeholder="Type Here..."
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div>
                      <ContinueButton
                        style={{ marginLeft: "0px" }}
                        disabled={_.isEmpty(reasonComments)}
                        onClick={handleConfirmReturn}
                      >
                        Confirm
                      </ContinueButton>
                    </div>
                  </div>
                </div>
              </Dialog>
            </div>
          ) : (
            " "
          )}
        </div>
      )}
    </>
  );
};

export default NewReturns;
