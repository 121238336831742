import React from "react";
import CheckoutHeader from "./CheckoutHeader";
import { Box} from "@mui/material";

const CheckoutBox = (props) => {
  return (
    <div>
      <Box sx={{ m: "20px", background: "#FFF", borderRadius: "2px"}}>
        <CheckoutHeader header={props.header} /> 
          {props.children}
      </Box>
    </div>
  );
};

export default CheckoutBox;
