import { createSlice } from "@reduxjs/toolkit";
import _, { isEmpty } from "lodash";

const initialState = {
  b2bInvoiceData: [],
  b2bInvoiceListError: "",
  pageNo: 0,
  hasMore: true,
  searchDataB2bInvoice: ""
};

export const b2bInvoiceSlice = createSlice({
  name: "b2bInvoice",
  initialState,
  reducers: {
    setPageno(state, action) {
      return Object.assign({}, state, {
        pageNo: action.payload,
      });
    },
    setSearchDataB2bInvoice(state, action) {
      return Object.assign({}, state, {
        searchDataB2bInvoice: _.isEmpty(action.payload) ? "" : action.payload,
      });
    },

    getB2bInvoicesList() {},
    setB2bInvoicesList(state, action) {
      return Object.assign({}, state, {
        b2bInvoiceData: state.pageNo > 0 ? [...state.b2bInvoiceData, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
        hasMore: action.payload.length < 15 ? false : true
      });
    },
    setB2bInvoicesListError(state, action) {
      return Object.assign({}, state, {
        b2bInvoiceDataError: action.payload,
      });
    },

    clearB2bInvoices(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  setB2bInvoicesList,
  setB2bInvoicesListError,
  getB2bInvoicesList,
  clearB2bInvoices,
  setPageno,
  setSearchDataB2bInvoice
} = b2bInvoiceSlice.actions;

export default b2bInvoiceSlice.reducer;
