import { createSlice } from "@reduxjs/toolkit";
import _, { isEmpty } from "lodash";

const initialState = {
  orderList: [],
  closeOrderSuccessMessage: {},
  closeOrderErrorMessage: "",
  pageNoSales: 0,
  hasMoreSales: true,
  searchDataSales: ""
};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    getOrderList() {},
    setOrderList(state, action) {
      return Object.assign({}, state, {
        orderList:
          state.pageNoSales > 0
            ? [...state.orderList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreSales: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoSales(state, action) {
      return Object.assign({}, state, {
        pageNoSales: action.payload.page,
      });
    },

    confirmDelivery() {},
    closeOrder() {},
    closeOrderSuccess(state, action) {
      return Object.assign({}, state, {
        closeOrderSuccessMessage: action.payload,
      });
    },
    closeOrderError(state, action) {
      return Object.assign({}, state, {
        closeOrderErrorMessage: Array.isArray(action.payload)
          ? action.payload[0].msg
          : action.payload,
      });
    },

    qualitySave() {},
    setSearchDataSales(state, action) {
      return Object.assign({}, state, {
        searchDataSales: _.isEmpty(action.payload) ? "" : action.payload
      });
    },
    clearSales(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  setOrderList,
  getOrderList,
  confirmDelivery,
  closeOrder,
  closeOrderSuccess,
  closeOrderError,
  qualitySave,
  clearSales,
  setPagenoSales,
  setSearchDataSales
} = salesSlice.actions;

export default salesSlice.reducer;
