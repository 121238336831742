import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Grid,
  Box,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import CheckoutBox from "../Inventory/Checkout/CheckoutBox";
import { SmsIcon } from "../../components/svg/SmsIcon";
import { CallIcon } from "../../components/svg/CallIcon";
import { FrameIcon } from "../../components/svg/FrameIcon";
import { styled } from "@mui/material/styles";
import { getSaveShipment } from "../../app/reducers/returnsSlice";
import { HeadTypography, LabelTypography, useStyles } from "./styles";
import { Footer } from "../../components";
import { useNavigate } from "react-router-dom";
import ViewDetail from "../Inventory/Checkout/ViewDetail";
import CloseIcon from "@mui/icons-material/Close";
import { ContinueButton } from "../../components/wrapperComponents";
import { confirmDelivery, qualitySave } from "../../app/reducers/returnsSlice";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ReturnsCheckout = (props) => {
  const classes = useStyles();
  const { returns } = props;
  const { products } = props.returns;

  const dispatch = useDispatch();

  const history = useNavigate();

  const [openShipment, setOpenShipment] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [shippingData, setShippingData] = useState({});
  const [shipmentRow, setShipmentRow] = useState({});
  const [selectedProductId, setSelectedProductId] = useState("");

  const shipmentAdded = useSelector(
    (state) => state.returnsReducer.shipmentAdded
  );
  const deliveryConfirmed = useSelector(
    (state) => state.returnsReducer.deliveryConfirmed
  );
  const qualitySaved = useSelector(
    (state) => state.returnsReducer.qualitySaved
  );

  useEffect(() => {}, [shipmentAdded, qualitySaved, deliveryConfirmed]);

  const handleShipment = (event, Id) => {
    setOpenShipment(true);
    setSelectedProductId(Id);
  };
  const handleDateChange = (newValue, name) => {
    const date = new Date(newValue).toISOString();
    setDateValue(newValue);
    setShippingData({ ...shippingData, [name]: date });
  };

  const handleShippingChange = (event, name) => {
    setShippingData({ ...shippingData, [name]: event.target.value });
  };

  const handleSubmit = () => {
    const shipmentObj = {
      returnId: returns?.returnId,
      product_id: selectedProductId,
      status: "Shipped From Store",
      shippingDetails: shippingData,
    };

    dispatch(getSaveShipment(shipmentObj));
    setOpenShipment(false);
  };

  const [viewDetail, setViewDetail] = useState(false);
  const [viewDetailData, setViewDetailData] = useState({});

  const [delivery, setDelivery] = useState(false);
  const [deliveryComments, setDeliveryComments] = useState("");

  const [quality, setQuality] = useState(false);
  const [checked, setChecked] = useState(true);
  const [qualityCheckBox, setQualityCheckBox] = useState("pass");
  const [qualityComments, setQualityComments] = useState("");

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    td: {
      border: 0,
    },
  }));

  const handleDelivery = (event, Id) => {
    setDelivery(true);
    setSelectedProductId(Id);
  };

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const handleConfirmDelivery = () => {
    const date = new Date(Date.now());
    const obj = {
      returnId: returns?.returnId,
      product_id: selectedProductId,
      deliveryDetails: {
        deliveryDate: date,
      },
      status: "Delivered To Store",
    };
    dispatch(confirmDelivery(obj));
    setDelivery(false);
    props?.backToReturns();
  };

  const handleDeliveryComments = (event) => {
    setDeliveryComments(event.target.value);
  };

  const handleQuality = (event, Id) => {
    setQuality(true);
    setSelectedProductId(Id);
  };

  const handleQualitySave = () => {
    const obj = {
      returnId: returns?.returnId,
      product_id: selectedProductId,
      status: qualityCheckBox === "pass" ? "QC Passed" : "QC Failed",
    };
    dispatch(qualitySave(obj));
    props?.backToReturns();
  };

  const handleQualityComments = (event) => {
    setQualityComments(event.target.value);
  };

  const handleQualityCheckBox = (event) => {
    setQualityCheckBox(event.target.value);
  };

  const handleClose = () => {
    setDelivery(false);
    setQuality(false);
    setOpenShipment(false);
  };

  const handleViewDetails = (event, product) => {
    setViewDetail(true);
    setViewDetailData(product);
  };
  const handleViewClose = () => {
    setViewDetail(false);
  };

  const quantity = 1;

  const generateStatusBullet = (data) => {
    switch (data) {
      case "Delivered": {
        return "#b7e6cf";
      }
      case "Shipped From Store": {
        return "#d9ebff";
      }
      case "Delivered To Warehouse": {
        return "#b7e6cf";
      }
      case "Job Order QC Passed": {
        return "#b7e6cf";
      }
      case "Job Order QC Failed": {
        return "#ffb1b2";
      }

    case "Shipped From Warehouse": {
      return "#d9ebff";
    }
    case "Delivered To Store": {
      return "#b7e6cf";
    }
      case "Shipped": {
        return "#d9ebff";
      }
      case "QC": {
        return "#d1efef";
      }
      case "QC Failed": {
        return "#ffb1b2";
      }
      case "Created": {
        return "#d1efef";
      }
      case "Intend Created": {
        return "#ffeacd";
      }
      case "Packed": {
        return "#d1efef";
      }
      case "Completed": {
        return "#d9ebff";
      }
      case "QC Passed": {
        return "#b7e6cf";
      }
      default: {
        return "#d1efef";
      }
    }
  };

  const statusColor = (data) => {
    switch (data) {
      case "Delivered": {
        return "#1daf67";
      }
      case "Shipped": {
        return "#0077ff";
      }
      case "Shipped From Store": {
        return "#0077ff";
      }
      case "Delivered To Warehouse": {
        return "#1daf67";
      }
    case "Job Order QC Passed": {
      return "#1daf67";
    }
    case "Job Order QC Failed": {
      return "#ff0000";
    }
    case "Shipped From Warehouse": {
      return "#0077ff";
    }
    case "Delivered To Store": {
      return "#1daf67";
    }
      case "Packed": {
        return "#03a5a5";
      }
      case "QC": {
        return "#03a5a5";
      }
      case "QC Failed": {
        return "#ff0000";
      }
      case "Created": {
        return "#03a5a5";
      }
      case "Intend Created": {
        return "#ffb95a";
      }
      case "Completed": {
        return "#0077ff";
      }
      case "QC Passed": {
        return "#1daf67";
      }
      default: {
        return "#03a5a5";
      }
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(245, 245, 245)",
          paddingTop: "30px",
        }}
      >
        <Grid container sx={{ pb: "70px" }}>
          <Grid item xs={12} md={8} lg={8}>
            <CheckoutBox header="Consumer Details">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "25px",
                  }}
                >
                  <FrameIcon />
                  <Typography sx={{ fontWeight: 500 }}>
                    {returns?.customerDetails?.name}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "25px",
                  }}
                >
                  <CallIcon />
                  <Typography sx={{ fontWeight: 500 }}>
                    +91{returns?.customerDetails?.phoneNumber}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "25px",
                  }}
                >
                  <SmsIcon />
                  <Typography sx={{ fontWeight: 500 }}>
                    {returns?.customerDetails?.emailId}
                  </Typography>
                </div>
              </Box>
            </CheckoutBox>

            <CheckoutBox header="Product Summary">
              {products.map((product) => (
                <>
                  <TableContainer component={Box}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 650 }} width={"400px"}>
                            Product Description
                          </TableCell>
                          <TableCell sx={{ fontWeight: 650 }} align="left">
                            Unit Cost(&#8377;)
                          </TableCell>
                          <TableCell sx={{ fontWeight: 650 }} align="left">
                            Qty
                          </TableCell>
                          <TableCell sx={{ fontWeight: 650 }} align="left">
                            Total Cost(&#8377;)
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {
                        <TableBody>
                          <StyledTableRow>
                            <TableCell
                              sx={{ pt: "10px", pb: "0px" }}
                              align="left"
                            >
                              {/* <div
                                style={{
                                  backgroundColor: generateStatusBullet(
                                    product.status
                                  ),
                                  display: "inline-block",
                                  padding: "8px 12px",
                                  borderRadius: "4px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#03a5a5",
                                    fontSize: "10px",
                                    letterSpacing: "0.5px",
                                  }}
                                >
                                  {product.status}
                                </Typography>
                              </div> */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    height: "24px",
                                    backgroundColor: generateStatusBullet(
                                      product.status
                                    ),
                                    display: "inline-block",
                                    padding: "4px 3px 4px 0px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: statusColor(product.status),
                                      fontSize: "11px",
                                      fontWeight: 500,
                                      // lineHeight: 1.14,
                                      letterSpacing: "0.42px",
                                      textAlign: "center",
                                      padding: "2px 2px 0px 4px ",
                                    }}
                                  >
                                    {product.status}
                                  </Typography>
                                </div>
                              </div>
                            </TableCell>
                          </StyledTableRow>
                          {!isEmpty(product.name) ? (
                            <StyledTableRow key={product._id}>
                              <TableCell align="left">{product.name}</TableCell>
                              <TableCell align="left">
                                {product.price}
                              </TableCell>
                              <TableCell align="left">{quantity}</TableCell>
                              <TableCell align="left">
                                {(+product.price +
                                  +((product.price * product.gst) / 100)) *
                                  quantity}
                              </TableCell>
                            </StyledTableRow>
                          ) : (
                            " "
                          )}
                          {!isEmpty(product.lensItems) ? (
                            <StyledTableRow key={product.lensItems.lensId}>
                              <TableCell align="left">
                                {product.lensName}
                              </TableCell>
                              <TableCell align="left">
                                {product.lensItems.price}
                              </TableCell>
                              <TableCell align="left">{quantity}</TableCell>
                              <TableCell align="left">
                                {(+product.lensItems?.price +
                                  +((product.lensItems?.price * 12) / 100)) *
                                  quantity}
                              </TableCell>
                            </StyledTableRow>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>
                  <Divider />
                  {product.status === "Shipped From Warehouse" ||
                  product.status === "Delivered To Store" ||
                  product.status === "QC Failed" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          padding: "20px",
                          textAlign: "center",
                          color: "#ff6f00",
                        }}
                        onClick={(event) => handleViewDetails(event, product)}
                      >
                        <Typography
                          sx={{
                            fontSize: "10pt",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          View Prescription &amp; Frame Size
                        </Typography>
                      </div>
                      <div
                        style={{
                          padding: "20px",
                          textAlign: "center",
                          color: "#03a5a5",
                        }}
                        onClick={
                          product.status === "Delivered To Store" ||
                          product.status === "QC Failed"
                            ? (event) => handleQuality(event, product?._id)
                            : (event) => handleDelivery(event, product?._id)
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: "10pt",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          {product.status === "Delivered To Store" ||
                          product.status === "QC Failed"
                            ? "Quality Check"
                            : "Confirm Delivery"}
                        </Typography>
                      </div>
                    </div>
                  ) : product.status === "Created" ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          padding: "20px",
                          textAlign: "center",
                          color: "#ff6f00",
                        }}
                        onClick={(event) => handleViewDetails(event, product)}
                      >
                        <Typography
                          sx={{
                            fontSize: "10pt",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          View Prescription &amp; Frame Size
                        </Typography>
                      </div>
                      <div
                        style={{
                          padding: "20px",
                          textAlign: "center",
                          color: "#03a5a5",
                        }}
                        onClick={(event) => handleShipment(event, product?._id)}
                      >
                        <Typography
                          sx={{
                            fontSize: "10pt",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          Update Shipment
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: "20px",
                        textAlign: "center",
                        color: "#ff6f00",
                      }}
                      onClick={(event) => handleViewDetails(event, product)}
                    >
                      <Typography
                        sx={{
                          fontSize: "10pt",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                      >
                        View Prescription &amp; Frame Size
                      </Typography>
                    </div>
                  )}
                  <Divider />
                </>
              ))}
            </CheckoutBox>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Grid item>
              <CheckoutBox header="Order Details">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px 12px 12px",
                  }}
                >
                  <Typography>{returns?.orderId} </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>
                      {moment(returns?.orderDate).format("ll")}
                    </Typography>
                  </div>
                </div>
              </CheckoutBox>
              {returns.status === "Shipped" ? (
                <CheckoutBox header="Shipping Info">
                  <div style={{ padding: "30px 20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography>Shipping Date</Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                          {moment(
                            returns.products[0].shippingDetails.shippingDate
                          ).format("DD/MM/YYYY") || "-"}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography>Service Provider</Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                          {returns.products[0].shippingDetails
                            .serviceProvider || "-"}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography>Tracking ID</Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                          {returns.products[0].shippingDetails.trackingId ||
                            "-"}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography>Comments</Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                          {returns.products[0].shippingDetails.comments || "-"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </CheckoutBox>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
        <ViewDetail
          open={viewDetail}
          handleViewClose={handleViewClose}
          data={viewDetailData}
        />
        {delivery ? (
          <div>
            <Dialog
              open={delivery}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: "8px" }}
            >
              <DialogTitle>
                Confirm Delivery
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div style={{ padding: "20px 20px 0px" }}>
                  <div>
                    <FormControlLabel
                      label="Delivered"
                      control={
                        <Checkbox
                          sx={{
                            "&.MuiCheckbox-colorPrimary": {
                              color: "#03a5a5",
                            },
                          }}
                          checked={checked}
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div style={{ textAlign: "center", marginBottom: "20px" }}>
                    <TextField
                      onChange={handleDeliveryComments}
                      placeholder="Type Here..."
                      disabled={!checked}
                    />
                  </div>
                  <div>
                    <ContinueButton onClick={handleConfirmDelivery}>
                      Confirm{" "}
                    </ContinueButton>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        ) : (
          ""
        )}
        {quality ? (
          <div>
            <Dialog
              open={quality}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: "8px" }}
            >
              <DialogTitle sx={{ fontWeight: 650, fontSize: 18 }}>
                Update Status
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div style={{ padding: "20px 20px 0px" }}>
                  <div style={{ padding: "0px 20px 10px" }}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={qualityCheckBox}
                      onChange={handleQualityCheckBox}
                      row
                    >
                      <FormControlLabel
                        value="pass"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "#03A5A5",
                              "&.Mui-checked": {
                                color: "#03A5A5",
                              },
                            }}
                          />
                        }
                        label="QC Pass"
                      />
                      <FormControlLabel
                        value="fail"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "#03A5A5",
                              "&.Mui-checked": {
                                color: "#03A5A5",
                              },
                            }}
                          />
                        }
                        label="QC Fail"
                      />
                    </RadioGroup>
                  </div>
                  <div style={{ padding: "0px 20px 20px" }}>Comments</div>
                  <div style={{ padding: "0px 20px 20px" }}>
                    <TextField
                      onChange={handleQualityComments}
                      placeholder="Type Here..."
                    />
                  </div>
                  <div style={{ padding: "0px 0px 20px" }}>
                    <ContinueButton onClick={handleQualitySave}>
                      Confirm
                    </ContinueButton>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        ) : (
          ""
        )}
        <Dialog open={openShipment} onClose={handleClose}>
          <div className={classes?.statusExchangeDialogBox}>
            <div className={classes?.alignFlex}>
              <HeadTypography>Update Status</HeadTypography>
              <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
            </div>
            <Grid container alignItems="center" sx={{ mt: "16px" }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ pr: "3%", pb: "1.5%" }}
              >
                <div>
                  <LabelTypography sx={{ pb: "8px" }}>
                    SHIPPING DATE
                  </LabelTypography>
                  <DatePicker
                    selected={dateValue}
                    autoComplete="off"
                    onChange={(date) => handleDateChange(date, "shippingDate")}
                    dateFormat="dd/MM/yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="ShippingDate"
                    customInput={
                      <TextField
                        fullWidth
                        type="text"
                        placeholder="ShippingDate"
                        variant="outlined"
                      />
                    }
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ pr: "5%", pb: "1.5%" }}
              >
                <div>
                  <LabelTypography sx={{ pb: "8px" }}>
                    SERVICE PROVIDER
                  </LabelTypography>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleShippingChange(e, "serviceProvider")}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ pr: "5%", pb: "1.5%" }}
              >
                <div>
                  <LabelTypography sx={{ pb: "8px" }}>
                    TRACKING ID
                  </LabelTypography>
                  <TextField
                    fullWidth
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleShippingChange(e, "trackingId")}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={12} md={6}>
                <div>
                  <LabelTypography sx={{ pb: "8px" }}>COMMENTS</LabelTypography>
                  <TextField
                    type="text"
                    fullWidth
                    multiline
                    rows={5}
                    variant="outlined"
                    onChange={(e) => handleShippingChange(e, "comments")}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={{ mt: "16px" }}>
                <ContinueButton sx={{ ml: "0px" }} onClick={handleSubmit}>
                  Confirm
                </ContinueButton>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default ReturnsCheckout;
