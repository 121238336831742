import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
  BackButton,
  ContinueButton,
  ContinueShoppingButton,
} from "../wrapperComponents";
import { useStyles } from "../styles";

const Footer = (props) => {
  const classes = useStyles();
  const {
    addCustomer,
    back,
    onContinue,
    handleCustomer = () => null,
    handleBackButton = () => null,
    handleContinueButton = () => {},
    continueShopping,
    handleContinueShopping = () => {},
    disabled = false,
    disablePlaceOrder = false,
    disableOnContinue = false,
    disableContinueShopping = false,
  } = props;

  const handleBack = () => {
    handleBackButton();
  };

  const handleContinue = (event) => {
    handleContinueButton(event);
  };

  return (
    <>
      <div style={{ clear: "both", height: "64px" }}></div>
      <AppBar
        position="fixed"
        className={classes.appBarFooter}
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar>
          {addCustomer && (
            <ContinueButton disabled={disabled} onClick={handleCustomer}>
              {addCustomer}
            </ContinueButton>
          )}
          <div style={{ flexGrow: 1 }}></div>
          {back && <BackButton onClick={handleBack}>{back}</BackButton>}
          {disableContinueShopping
            ? ""
            : continueShopping && (
                <ContinueShoppingButton onClick={handleContinueShopping}>
                  {continueShopping}
                </ContinueShoppingButton>
              )}
          {onContinue && (
            <ContinueButton disabled={disablePlaceOrder} onClick={handleContinue}>
              {onContinue}
            </ContinueButton>
          )}
        </Toolbar>
      </AppBar>
    </>
    // </Box>
  );
};

export default Footer;
