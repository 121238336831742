import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getOrderList,
  setOrderList,
  confirmDelivery,
  closeOrder,
  qualitySave,
  closeOrderSuccess,
  closeOrderError,
} from "../reducers/salesSlice";
import { API_URL } from "../../utils/constants";

function fetchOrdersListAPI(payload) {
    const { page, search } = payload;
  const storeId = localStorage.getItem("storeId");
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/storeorder/openorders`;
  const products = axios
    .get(apiURL, {
      headers: headers,
      params: {
        limit: 15,
        page: page,
        storeId: storeId,
        search: search,
        filter: {},
        sort: { _created_at: -1 },
      },
    })
    .then((response) => response.data.data);
  return products;
}

function deliveryConfirmationAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/joborder/updatedeliverystatus`;
  const products = axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response.data.data);
  return products;
}

function closeOrderAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/storeorder/closeorder`;
  const products = axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response);
  return products;
}

function qualityCheckAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/storeorder/updateorderstatus`;
  const products = axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response.data.data);
  return products;
}

export function* fetchOrderList(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchOrdersListAPI, payload);
    yield put(setOrderList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* deliveryConfirmation(action) {
  try {
    const { payload } = action;
    const response = yield call(deliveryConfirmationAPI, payload);
    yield put(getOrderList({page: 0, search: ""}));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* closeOrders(action) {
  try {
    const { payload } = action;
    const response = yield call(closeOrderAPI, payload);
    yield put(closeOrderSuccess(response.data.data));
  } catch (error) {
    yield put(closeOrderError(error.response.data.message));
  }
}

export function* qualityCheck(action) {
  try {
    const { payload } = action;
    yield call(qualityCheckAPI, payload);
    yield put(getOrderList({page: 0, search: ""}));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* salesSaga() {
  yield takeLatest(getOrderList.type, fetchOrderList);
  yield takeLatest(confirmDelivery.type, deliveryConfirmation);
  yield takeLatest(closeOrder.type, closeOrders);
  yield takeLatest(qualitySave.type, qualityCheck);
}
