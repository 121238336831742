import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    getCheckoutData,
    setCheckoutData,
    getCouponData,
    setCouponData,
    setCheckoutDataError,
    setCouponDataError
} from "../../app/reducers/checkoutSlice";
import { API_URL } from "../../utils/constants";

async function addOrderAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/storeorder/createorder`;
  const userData = await axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response);
  return userData;
}
export function* saveOrder(action) {
  try {
    const { payload } = action;
    const response = yield call(addOrderAPI, payload);
    if (response.data.data) {
      yield put(setCheckoutData({ ...response.data.data }));
    }
  } catch (error) {
    yield put(setCheckoutDataError(error.response.data.message));
  }
}


async function fetchCouponsAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/couponcode/orderamount`;
  const userData = await axios
    .get(apiURL, {
      params: {
        orderAmount: payload.orderAmount,
        couponCode: payload.couponCode
      },
      headers: headers
    })
    .then((response) => response);
  return userData;
}

export function* fetchCoupons(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchCouponsAPI, payload);
    if (response.data) {
      yield put(setCouponData(response.data));
    }
  } catch (error) {
    yield put(setCouponDataError(error.response.data.message[0].msg));
  }
}


export function* checkoutSaga() {
  yield takeLatest(getCheckoutData.type, saveOrder);
  yield takeLatest(getCouponData.type, fetchCoupons);
}
