import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getremindersList,
  setremindersList,
  addReminderStatus,
  addReminderStatusSuccess,
  addReminderStatusError,
  removeReminderStatus,
  removeReminderStatusSuccess,
  removeReminderStatusError
} from "../../app/reducers/reminderSlice";
import { API_URL } from "../../utils/constants";

async function fetchRemindersAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/reminders/fetchsalesreminders`;
  const reminders = axios
    .get(apiURL, {
      params: {
        month: payload,
      },
      headers: headers
    })
    .then((response) => response.data.data);
  return reminders;
}
export function* fetchReminders(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchRemindersAPI, payload);
    yield put(setremindersList(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}


async function addRemindersAPI(payload) {
  const apiURL = `${API_URL}/client/reminders/createsalesstatus`;
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const userData = await axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response);
  return userData;
}

export function* addReminders(action) {
  try {
    const { payload } = action;
    const response = yield call(addRemindersAPI, payload);
      yield put(addReminderStatusSuccess(response.data.data));
  } catch (error) {
    yield put(addReminderStatusError(error.response.data.message));
  }
}

async function removeRemindersAPI(payload) {
  const apiURL = `${API_URL}/client/reminders/removesalesstatus`;
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const userData = await axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response);
  return userData;
}

export function* removeReminders(action) {
  try {
    const { payload } = action;
    const response = yield call(removeRemindersAPI, payload);
      yield put(removeReminderStatusSuccess(response.data.data));
  } catch (error) {
    yield put(removeReminderStatusError(error.response.data.message));
  }
}

export function* reminderSaga() {
  yield takeLatest(getremindersList.type, fetchReminders);
  yield takeLatest(addReminderStatus.type, addReminders);
  yield takeLatest(removeReminderStatus.type, removeReminders);
}
