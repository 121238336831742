import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getB2bInvoicesList,
  setB2bInvoicesList,
  setB2bInvoicesListError
} from "../../app/reducers/b2bInvoiceSlice";
import { API_URL } from "../../utils/constants";

async function fetchB2bInvoicesAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/b2binvoice/getinvoices`;
  const storeId = localStorage.getItem("storeId");
  const userData = await axios
    .get(apiURL, {
      params: {
        limit: 20,
        page: payload.page,
        filter: {},
        storeId,
        search: payload.search,
        sort: { _created_at: -1 },
      },
      headers: headers
    })
    .then((response) => response);
  return userData;
}

export function* fetchB2bInvoices(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchB2bInvoicesAPI, payload);
    yield put(setB2bInvoicesList(response.data.data));
  } catch (error) {
    yield put(setB2bInvoicesListError(error.response.data.message));
  }
}

export function* b2bInvoiceSaga() {
  yield takeLatest(getB2bInvoicesList.type, fetchB2bInvoices);
}
