import { Grid, InputAdornment, TextField, Dialog } from "@mui/material";
import React, { useState, useEffect } from "react";
import { withNavBars } from "../../common/hocs";
import { ListTable } from "../../components";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../router/routes";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionsData } from "../../app/reducers/transactionsSlice";
import moment from "moment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import _ from "lodash";

const useStyles = makeStyles({
  notchedOutline: {
    borderRadius: "35px !important",
  },
});

const today = moment().format("YYYY-MM-DD");

function Transactions(props) {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const transactionsList = useSelector(
    (state) => state.transactionsReducer.transactionsList
  );

  const [openModal, setOpenModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [searchDate, setSearchDate] = useState(today);
  const [page, setPage] = useState(0);
  const [payload, setPayLoad] = useState({ page: page, date: searchDate });

  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );

  let tableData = [];

  transactionsList?.map((transactions) => {
    const transactionData = {
      orderId:
        transactions?.orderId +
        "/" +
        moment(transactions?.orderDate).format("llll"),
      type: "-",
      paymentMode: transactions?.paymentMode,
      paymentDetail: _.isEmpty(transactions?.paymentDetail)
        ? "-"
        : transactions?.paymentDetail,
      transactionDate:
        moment(transactions?.transactionDate).format("ll") +
        "/" +
        moment(transactions?.transactionDate).format("hh:mm a"),
      amount: transactions?.amount,
    };
    tableData.push(transactionData);
    return transactionData;
  });

  useEffect(() => {
    setPayLoad({ page: page, date: searchDate });
  }, [searchDate, page]);

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(storeChange)) {
      dispatch(getTransactionsData(payload));
    }
  }, [storeChange, payload]);

  const handleView = (e, data) => {
    setOpenModal(true);
    setImageUrl(data.data.imageUrl);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (date) => {
    setSearchDate(date);
  };

  return (
    <div>
      <Grid
        container
        alignItems={"center"}
        sx={{
          position: "fixed",
          backgroundColor: "#f5f5f5",
          width: "calc(100% - 20px)",
        }}
        justifyContent="flex-end"
      >
        <Grid item xs={6} sx={{ padding: "12px 24px" }}>
          {/* <TextField
            id="input-with-icon-textfield"
            placeholder="Search Customer Name/Mobile Number/Order Id"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon width={"16"} height={"16"} />
                </InputAdornment>
              ),
              classes: {
                root: classes.notchedOutline,
              },
            }}
            style={{ backgroundColor: "#fff", borderRadius: "35px" }}
          /> */}
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            padding: "12px 24px 0px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              inputFormat="MM/dd/yyyy"
              value={searchDate}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField {...params} sx={{ 
                  "& .MuiInputBase-root": {
                    borderRadius:"100px"
                  },
                }} />
              )}
              className="date-picker"
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <div
        style={{
          padding: "90px 24px 40px",
          height: "calc(100vh - 200px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <ListTable
          dataList={tableData}
          handleView={handleView}
          Header={[
            "Order ID/ Date",
            // "Type",
            "Payment Mode",
            "Transaction ID (or) Card Number",
            "Transaction Date/Time",
            "Amount",
          ]}
          paddingLeft="100px"
          tableData={[
            { type: ["TEXT"], name: "orderId" },
            // { type: ["TEXT"], name: "type" },
            { type: ["TEXT"], name: "paymentMode" },
            { type: ["TEXT"], name: "paymentDetail" },
            { type: ["TEXT"], name: "transactionDate" },
            { type: ["TEXT"], name: "amount" },
          ]}
        />
      </div>
      <Dialog
        open={openModal}
        maxWidth
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: "8px" }}
      >
        <img src={imageUrl} alt="Image broken"></img>
      </Dialog>
    </div>
  );
}

export default withNavBars(Transactions);
