import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getConsumerData,
  setConsumerData,
  setConsumerDataError,
  getConsumerList,
  setConsumerList,
  setConsumerListError
} from "../../app/reducers/consumerSlice";
import { API_URL } from "../../utils/constants";

async function saveConsumerDetailsAPI(payload) {
  const apiURL = `${API_URL}/client/customer/add`;
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const userData = await axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response);
  return userData;
}

export function* saveConsumerDetails(action) {
  try {
    const { payload } = action;
    const response = yield call(saveConsumerDetailsAPI, payload);
    if (response.data.data) {
      yield put(setConsumerData({ ...response.data.data }));
    }
  } catch (error) {
    yield put(setConsumerDataError(error.response.data.message));
  }
}

async function fetchConsumerDetailsAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/customer/fetch`;
  const userData = await axios
    .get(apiURL, {
      params: {
        limit: 20,
        page: payload.page,
        filter: {},
        search: payload.search,
        sort: { _created_at: -1 },
      },
      headers: headers
    })
    .then((response) => response);
  return userData;
}

export function* fetchConsumerDetails(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchConsumerDetailsAPI, payload);
    yield put(setConsumerList(response.data.data.customers));
  } catch (error) {
    yield put(setConsumerListError(error.response.data.message));
  }
}

export function* consumerSaga() {
  yield takeLatest(getConsumerData.type, saveConsumerDetails);
  yield takeLatest(getConsumerList.type, fetchConsumerDetails);
}
