import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Button, Drawer, Paper, Slider } from "@mui/material";

const drawerWidth = 320;
export const useStyles = makeStyles({
  drawerContents: {
    padding: "20px 24px 20px",
    height: "calc(100vh - 101px)",
    overflowY: "auto",
  },
  filterDrawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #e6e6e6",
  },
  checkBoxLabel: {
    fontSize: "12px !important",
  },
  checked: {
    color: "#03a5a5 !important",
  },
});

export const ApplyButton = styled(Button)(({ theme }) => ({
  border: "1px solid #e6e6e6",
  margin: "4px",
  borderRadius: "2px",
  width: "100%",
  backgroundColor: "#03a5a5",
  color: "#fff",
  textTransform: "none",
  '&:hover': {
    backgroundColor: '#75c9c9',
    boxShadow: 'none',
},
}));

export const FooterDrawer = styled(Drawer)(({ theme }) => ({
    "& .MuiDrawer-paper":{
        overflowY: "hidden"
    },
}));

export const CustomSlider = styled(Slider)(({theme}) => ({
    "& .MuiSlider-rail":{
        color: "#eaeaea",
    },
    "& .MuiSlider-track":{
        backgroundColor: "#03a5a5"
    },
    "& .MuiSlider-thumb":{
        backgroundColor: "#fff",
        border: "4px solid #03a5a5",
    },
    "& .MuiSlider-mark":{
        display: "none",
    },
    "& .MuiSlider-valueLabelOpen":{
        backgroundColor: "#fff",
        color: "#000",
        transform: "translateY(100%) !important",
        padding: "4px 4px 2px 4px",
    },
    "& .MuiSlider-markLabel":{
        color: "#000",
        '&[data-index="0"]' : {
          left:'-2% !important'
        },
        '&[data-index="4"]' : {
          left:'97% !important'
        },
    }
}))

export const FilterPaper = styled(Paper)(({theme}) => ({
  // width:drawerWidth,
  [theme.breakpoints.down('sm')]: {
    width: `${drawerWidth}px`,
  },
  [theme.breakpoints.up('sm')]: {
    width: "350px",
  },
  overFlow: "hidden"
}))

