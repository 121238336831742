import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    getCashData,
    getCashDataSuccess,
    getCashDataError,
    saveCashDeposit,
    saveCashDepositSuccess,
    saveCashDepositError,
} from "../../app/reducers/cashdepositSlice";
import { API_URL } from "../../utils/constants";

async function saveCashDataAPI(payload) {
  const apiURL = `${API_URL}/client/payment/cashdeposit`;
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const userData = await axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response);
  return userData;
}

export function* saveCashData(action) {
  try {
    const { payload } = action;
    const response = yield call(saveCashDataAPI, payload);
    if (response.data.data) {
      yield put(saveCashDepositSuccess({ ...response.data.data }));
    }
  } catch (error) {
    yield put(saveCashDepositError(error.response.data.message[0].msg));
  }
}

async function fetchCashDataAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const storeId = localStorage.getItem("storeId");
  const apiURL = `${API_URL}/client/payment/cashdeposit`;
  const userData = await axios
    .get(apiURL, {
      params: {
        limit: 15,
        page: payload,
        filter: {
            storeId: storeId
        },
        sort: { _created_at: -1 },
      },
      headers: headers
    })
    .then((response) => response);
  return userData;
}

export function* fetchCashData(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchCashDataAPI, payload);
    yield put(getCashDataSuccess(response.data.data));
  } catch (error) {
    yield put(getCashDataError(error.response.data.message[0].msg));
  }
}

export function* cashdepositSaga() {
  yield takeLatest(getCashData.type, fetchCashData);
  yield takeLatest(saveCashDeposit.type, saveCashData);
}
