import {
  Grid,
  InputAdornment,
  TextField,
  DialogTitle,
  Typography,
  DialogContent,
  IconButton,
  Dialog,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ContinueButton, ListTable, SearchIcon } from "../../components";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getReturnsList,
  clearReturns,
  setPagenoReturns,
  setSearchDataReturns,
} from "../../app/reducers/returnsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { NavBar, Sidebar } from "../../components";
import NewReturns from "./NewReturns";
import { HeadTypography, LabelTypography, useStyles } from "./styles";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ReturnsCheckout from "./ReturnsCheckout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getPermittedStore } from "../../app/reducers/storeLoginSlice";
import _ from "lodash";
import { useIsMount } from "../../common/hooks/customHooks";

const Returns = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const isMount = useIsMount();

  const storeChange = useSelector(
    (state) => state.storeLoginReducer.selectedStoreId
  );
  const returnsList = useSelector((state) => state.returnsReducer.returnsList);
  const shipmentAdded = useSelector(
    (state) => state.returnsReducer.shipmentAdded
  );
  const deliveryConfirmed = useSelector(
    (state) => state.returnsReducer.deliveryConfirmed
  );
  const qualitySaved = useSelector(
    (state) => state.returnsReducer.qualitySaved
  );
  const pageNo = useSelector((state) => state.returnsReducer.pageNoReturns);
  const hasMore = useSelector((state) => state.returnsReducer.hasMoreReturns);
  const returnsAdded = useSelector(
    (state) => state.returnsReducer.returnsAdded
  );

  const [newReturn, setNewReturn] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [commentVal, setCommentVal] = useState("");
  const [openReturnCheckout, setOpenReturnCheckout] = useState(false);
  const [returnDetails, setReturnDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("eyePlay-auth-token");
    if (_.isEmpty(token)) {
      history("/");
    }

    if (token && _.isEmpty(storeChange)) {
      dispatch(getPermittedStore());
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(storeChange)) {
      dispatch(clearReturns());
      dispatch(getReturnsList({ page: 0, search: searchStr }));
    }
  }, [storeChange]);

  useEffect(() => {
    if (!_.isEmpty(shipmentAdded)) {
      setNewReturn(false);
      setOpenReturnCheckout(false);
      dispatch(clearReturns());
      dispatch(getReturnsList({ page: 0, search: searchStr }));
    }
  }, [shipmentAdded]);

  useEffect(() => {
    if (!_.isEmpty(qualitySaved)) {
      setNewReturn(false);
      setOpenReturnCheckout(false);
      dispatch(clearReturns());
      dispatch(getReturnsList({ page: 0, search: searchStr }));
    }
  }, [qualitySaved]);

  useEffect(() => {
    if (!_.isEmpty(deliveryConfirmed)) {
      setNewReturn(false);
      setOpenReturnCheckout(false);
      dispatch(clearReturns());
      dispatch(getReturnsList({ page: 0, search: searchStr }));
    }
  }, [deliveryConfirmed]);

  useEffect(() => {
    if (!_.isEmpty(returnsAdded)) {
      setNewReturn(false);
      setOpenReturnCheckout(false);
      dispatch(clearReturns());
      dispatch(getReturnsList({ page: 0, search: searchStr }));
    }
  }, [returnsAdded]);

  useEffect(() => {
    setLoading(false);
  }, [returnsList]);

  useEffect(() => {
    const timeOut = setTimeout(
      () =>
        !isMount
          ? dispatch(getReturnsList({ page: 0, search: searchStr }))
          : "",
      1000
    );
    return () => clearTimeout(timeOut);
  }, [searchStr]);

  const handleDrawerOpen = () => {
    setOpenSideBar(true);
  };

  const handleDrawerClose = () => {
    setOpenSideBar(false);
  };

  const handleNewReturn = () => {
    setNewReturn(true);
  };

  const handleBack = () => {
    setNewReturn(false);
    setOpenReturnCheckout(false);
  };
  const backToReturns = () => {
    setNewReturn(false);
    setOpenReturnCheckout(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCommentClick = (e, data) => {
    setOpen(true);
    setCommentVal(data.data.comments);
  };

  let tableData = [];

  returnsList?.map((returns) => {
    const returnsData = {
      returnId:
        returns?.returnId + " / " + moment(returns?._created_at).format("llll"),
      orderId:
        returns?.orderId + " / " + moment(returns?.orderDate).format("llll"),
      consumerName:
        returns?.customerDetails.name +
        "/" +
        returns?.customerDetails.phoneNumber,
      amount: returns?.returnAmount,
      type: returns?.reason,
      status: returns?.status,
      data: returns,
    };
    tableData.push(returnsData);

    return tableData;
  });

  const handleStatusClick = (e, data) => {};

  const handleView = (e, returns) => {
    setReturnDetails(returns.data);
    setOpenReturnCheckout(true);
    history("/returns", {
      state: { heading: "Sales", subHeader: "Returns", isBack: true },
    });
  };

  const handleSearch = (e) => {
    setSearchStr(e.target.value);
  };

  const handleLoadmore = () => {
    setLoading(true);
    dispatch(setPagenoReturns({ page: pageNo + 1, search: searchStr }));
    dispatch(getReturnsList({ page: pageNo + 1, search: searchStr }));
  };

  
  return (
    <>
      <div>
        <NavBar
          handleDrawerOpen={handleDrawerOpen}
          header={"Returns"}
          isBack={newReturn || openReturnCheckout}
          handleBack={handleBack}
        />
        <Sidebar
          handleDrawerClose={handleDrawerClose}
          open={openSideBar}
          location={location}
        />
      </div>
      {newReturn ? (
        <div>
          <NewReturns backToReturns={backToReturns} />{" "}
        </div>
      ) : openReturnCheckout ? (
        <div>
          <ReturnsCheckout
            returns={returnDetails}
            backToReturns={backToReturns}
          />
        </div>
      ) : (
        <div className={classes.content}>
          <Grid
            container
            alignItems={"center"}
            sx={{
              position: "fixed",
              backgroundColor: "#f5f5f5",
              width: "calc(100% - 20px)",
            }}
          >
            <Grid item xs={6} sx={{ padding: "12px 24px" }}>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search Consumer Name/Mobile Number/Order ID"
                fullWidth
                variant="outlined"
                value={searchStr}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon width={"16"} height={"16"} />
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.notchedOutline,
                  },
                }}
                style={{ backgroundColor: "#fff", borderRadius: "35px" }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                padding: "12px 24px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ContinueButton onClick={handleNewReturn}>
                New Return
              </ContinueButton>
            </Grid>
          </Grid>
          <div
            style={{
              padding: "90px 24px 40px",
              height: "calc(100vh - 194px)",
              overflow: "hidden",
              backgroundColor: "#f5f5f5",
            }}
          >
            <ListTable
              dataList={tableData}
              handleCommentClick={handleCommentClick}
              handleStatusClick={handleStatusClick}
              handleView={handleView}
              isLoading={loading}
              handleLoadmore={handleLoadmore}
              hasMore={hasMore}
              Header={[
                "Return Id/Date",
                "Order Id/Date",
                "Consumer Name/Mobile Number",
                "Order Amount",
                "Type",
                "Status",
                "Comments",
                "Actions",
              ]}
              tableData={[
                { type: ["TEXT"], name: "returnId" },
                { type: ["TEXT"], name: "orderId" },
                { type: ["TEXT"], name: "consumerName" },
                { type: ["TEXT"], name: "amount" },
                { type: ["TEXT"], name: "type" },
                { type: ["STATUS"], name: "status" },
                { type: ["COMMENTS"], name: "" },
                { type: ["VIEW"], name: "" },
              ]}
            />
          </div>
          {open && (
            <div>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ borderRadius: "8px" }}
              >
                <DialogTitle sx={{ fontWeight: 650 }}>
                  Comments
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <div style={{ padding: "20px 60px 50px" }}>
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2, mb: 3 }}
                      >
                        {commentVal || "No comments Found"}
                      </Typography>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Returns;
