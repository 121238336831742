import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  cashDepositList: [],
  cashDepositListError: "",
  pageNoCash: 0,
  hasMoreCash: true,
  savedCashDeposit: {},
  savedCashDepositError: "",  
};

export const cashdepositSlice = createSlice({
  name: "cashDeposit",
  initialState,
  reducers: {
    getCashData() {},
    getCashDataSuccess(state, action) {
      return Object.assign({}, state, {
        cashDepositList: state.pageNoCash > 0 ? [...state.cashDepositList, ...action.payload] : isEmpty(action.payload) ? [] : action.payload,
        hasMoreCash: action.payload.length < 15 ? false : true 
      });
    },
    getCashDataError(state, action) {
      return Object.assign({}, state, {
        cashDepositListError: action.payload,
      });
    },

    setPagenoCash(state, action) {
      return Object.assign({}, state, {
        pageNoCash: action.payload,
      });
    },

    saveCashDeposit() {},
    saveCashDepositSuccess(state, action) {
      return Object.assign({}, state, {
        savedCashDeposit:  isEmpty(action.payload) ? {} : action.payload
      });
    },
    saveCashDepositError(state, action) {
      return Object.assign({}, state, {
        savedCashDepositError: action.payload,
      });
    },

    clearCashDeposits(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
    getCashData,
    getCashDataSuccess,
    getCashDataError,
    setPagenoCash,
    saveCashDeposit,
    saveCashDepositSuccess,
    saveCashDepositError,
    clearCashDeposits
} = cashdepositSlice.actions;

export default cashdepositSlice.reducer;
