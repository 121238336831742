import React from "react";

export const AddPrescriptionIcon = (props) => {
  const width = props?.width ?? "79";
  const height = props?.height ?? "79";
  const color = props?.color ?? "#a0a0a0";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
    >
      <g data-name="vuesax/outline/note">
        <path
          d="M40.641 61.359h-25.5C5.514 61.359 0 55.845 0 46.219V15.141C0 5.1 5.1 0 15.141 0a2.408 2.408 0 0 1 2.391 2.391 4.788 4.788 0 0 0 4.781 4.781h11.156a4.775 4.775 0 0 0 4.781-4.781A2.408 2.408 0 0 1 40.641 0c10.04 0 15.14 5.1 15.14 15.141v31.078c0 9.626-5.514 15.14-15.14 15.14zM13.069 4.845c-5.036.414-8.287 2.677-8.287 10.3v31.074c0 7.076 3.283 10.359 10.359 10.359h25.5C47.717 56.578 51 53.295 51 46.219V15.141c0-7.618-3.251-9.849-8.287-10.3a9.581 9.581 0 0 1-9.244 7.108H22.313a9.475 9.475 0 0 1-6.757-2.805 9.629 9.629 0 0 1-2.487-4.299z"
          transform="translate(10.359 11.156)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M20.719 19.125H9.563A9.577 9.577 0 0 1 0 9.563 9.591 9.591 0 0 1 9.563 0h11.156a9.577 9.577 0 0 1 9.563 9.563 9.591 9.591 0 0 1-9.563 9.562zM9.563 4.781a4.781 4.781 0 0 0 0 9.562h11.156a4.781 4.781 0 0 0 0-9.562z"
          transform="translate(23.109 3.984)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M15.141 4.781H2.391A2.408 2.408 0 0 1 0 2.391 2.408 2.408 0 0 1 2.391 0h12.75a2.408 2.408 0 0 1 2.391 2.391 2.408 2.408 0 0 1-2.391 2.39z"
          transform="translate(23.109 39.047)"
          fill={color}
        />
        <path
          data-name="Vector"
          d="M27.891 4.781h-25.5A2.408 2.408 0 0 1 0 2.391 2.408 2.408 0 0 1 2.391 0h25.5a2.408 2.408 0 0 1 2.391 2.391 2.408 2.408 0 0 1-2.391 2.39z"
          transform="translate(23.109 51.797)"
          fill={color}
        />
      </g>
    </svg>
  );
};
