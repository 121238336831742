import React from "react";
import {
  TableCell,
  TextField,
  Typography,

} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TableSwitch } from "./TableSwitch";
import EditIcon from "@mui/icons-material/Edit";
import { CommentIcon } from "../svg/CommentIcon";
import { StyledTableRow } from "./styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DownloadIcon, RefreshIcon, InfoIcon } from "../svg";

const useStyles = makeStyles({
  tableBody: {
    fontFamily: "poppinsemibold",
    fontSize: "12px",
    padding: 10,
  },
});

export const ListTableRow = ({
  paddingLeft = "25px",
  incrementCount = 0,
  index = null,
  parent_id = "",
  tableData = [],
  condition = () => true,
  Header = [],
  row = {},
  refreshIcon = "",
  exchangeStatusIcon = "",
  handleCheckBox = () => null,
  handleTextfield = () => null,
  handleEdit = () => null,
  handleOverRide = () => null,
  handleDelete = () => null,
  handleHyperLink = () => null,
  handleSelect = () => null,
  handleCommentClick = () => null,
  handleStatusClick = () => null,
  handleExchangeStatus = () => null,
  handleView = () => null,
  setOpen = () => null,
  state = {},
  tdStyle = {},
}) => {
  const classes = useStyles();

  return (
    <StyledTableRow
      id={`${parent_id}-${index}-table-dataList-TableRow`}
      key={index + 1}
    >
      {tableData.map((val, i) => {
        return (
          <TableCell
            id={`${parent_id}-${i}-table-dataList-TableCell`}
            style={{
              paddingLeft: i === 0 ? paddingLeft : "",
              paddingRight: i === Header.length - 1 ? "25px" : "",
              fontSize: "12px",
              color: "#858585",
              ...tdStyle,
            }}
            align={val.align ? val.align : "left"}
            className={classes.tableBody}
          >
            {val.type.map((type) => {
              if (condition(row, type, val.name)) {
                return getComponentType({
                  index: index + incrementCount,
                  type: type,
                  condition: val.cond,
                  value: val.optionLabel
                    ? row[val.name]?.[val.optionLabel]
                    : row[val.name],
                  error: !!row?.error?.[val.name],
                  placeholder: [val.name],
                  options: val?.options ?? [],
                  icon: val?.icon ?? "",
                  toolTip: val?.toolTip ?? {},
                  state: state,
                  row: row,
                  refreshIcon: refreshIcon,
                  exchangeStatusIcon: exchangeStatusIcon,
                  handleCheckBox: (e) => {
                    handleCheckBox(e, row, index);
                  },
                  handleEdit: (e) => {
                    handleEdit(e, row, index);
                  },
                  handleView: (e, type = "") => {
                    handleView(e, row, index, type);
                  },
                  handleOverRide: (e) => {
                    handleOverRide(e, row, index);
                  },
                  handleDelete: (e) => {
                    handleDelete(e, row, index);
                  },
                  handleTextfield: (e) => {
                    handleTextfield(e, row, val.name, index);
                  },
                  handleHyperLink: (e) => {
                    handleHyperLink(e, row, index);
                  },
                  handleSelect: (data) => {
                    handleSelect(data, row, val.name, index);
                  },
                  setOpen: (data, index) => {
                    setOpen(data, index);
                  },
                  handleStatusClick: (e) => {
                    handleStatusClick(e, row, index);
                  },
                  handleCommentClick: (e) => {
                    handleCommentClick(e, row, index);
                  },
                  handleExchangeStatus: (e) => {
                    handleExchangeStatus(e, row, index);
                  },
                });
              } else {
                return false;
              }
            })}
          </TableCell>
        );
      })}
    </StyledTableRow>
  );
};


const generateStatusBullet = (data) => {
  switch (data) {
    case "DELIVERED": {
      return "#b7e6cf";
    }
    case "PENDING": {
      return "#ffeacd";
    }
    case "PAID": {
      return "#b7e6cf";
    }
    case "Pending": {
      return "#ffeacd";
    }
    case "Fitted": {
      return "#d1efef";
    }
    case "Delivered": {
      return "#b7e6cf7";
    }
    case "Shipped": {
      return "#d9ebff";
    }
    case "Shipped From Store": {
      return "#d9ebff";
    }
    case "Delivered To Warehouse": {
      return "#b7e6cf";
    }
    case "Job Order QC Passed": {
      return "#b7e6cf";
    }
    case "Job Order QC Failed": {
      return "#ffb1b2";
    }

    case "Shipped From Warehouse": {
      return "#d9ebff";
    }
    case "Delivered To Store": {
      return "#b7e6cf";
    }
    case "Packed": {
      return "#d1efef";
    }
    case "QC": {
      return "#b7e6cf";
    }
    case "QC Failed": {
      return "#ffb1b2";
    }
    case "Created": {
      return "#d1efef";
    }
    case "In Progress": {
      return "#ffeacd";
    }
    case "Completed": {
      return "#d9ebff";
    }
    case "Paid": {
      return "#b7e6cf";
    }
    default: {
      return "#d1efef";
    }
  }
};

const statusColor = (data) => {
  switch (data) {
    case "DELIVERED": {
      return "#1daf67";
    }
    case "QC Failed": {
      return "#ff0000";
    }
    case "PENDING": {
      return "#ffb95a";
    }
    case "PAID": {
      return "#1daf67";
    }
    case "Pending": {
      return "#ffb95a";
    }
    case "Fitted": {
      return "#03a5a5";
    }
    case "Delivered": {
      return "#1daf67";
    }
    case "Shipped": {
      return "#0077ff";
    }
    case "Shipped From Store": {
      return "#0077ff";
    }
    case "Delivered To Warehouse": {
      return "#1daf67";
    }
    case "Job Order QC Passed": {
      return "#1daf67";
    }
    case "Job Order QC Failed": {
      return "#ff0000";
    }
    case "Shipped From Warehouse": {
      return "#0077ff";
    }
    case "Delivered To Store": {
      return "#1daf67";
    }
    case "Packed": {
      return "#03a5a5";
    }
    case "QC": {
      return "#1daf67";
    }
    case "Created": {
      return "#03a5a5";
    }
    case "In Progress": {
      return "#ffb95a";
    }
    case "Completed": {
      return "#0077ff";
    }
    case "Paid": {
      return "#1daf67";
    }
    default: {
      return "#03a5a5";
    }
  }
};

const getComponentType = (data) => {
  const DownloadIconProps = {
    color: "#03a5a5"
}
  switch (data.type) {
    case "TEXT": {
      if (
        data?.condition &&
        data.row[data.condition.key] === data.condition.value
      ) {
        return (
          <React.Fragment>
            <span style={{ paddingLeft: "5px" }}>{data.value}</span>
          </React.Fragment>
        );
      } else {
        return data.value;
      }
    }
    case "INCRIMENT": {
      return data.index + 1;
    }
    case "CHECKBOX": {
      return (
        <TableSwitch
          id={`table-value-TableSwitch`}
          checked={data.value}
          onChange={data.handleCheckBox}
          name="checkbox"
        />
      );
    }
    case "EDIT": {
      return (
        <img
          id={`table-EditIcon-img`}
          src={<EditIcon />}
          onClick={data.handleEdit}
          width="12px"
          style={{ marginRight: "10px", cursor: "pointer" }}
          alt="table-Edit"
        />
      );
    }
    case "COMMENTS": {
      return (
        <div
          style={{ paddingLeft: "25px", cursor: "pointer" }}
          onClick={data.handleCommentClick}
        >
          <CommentIcon />
        </div>
      );
    }
    case 'EOD': {
      return (
        <React.Fragment>
          <span style={{ paddingLeft: "5px", color: "#03a5a5"}}>{data.value}</span>
        </React.Fragment>
      );
    }
    case "TEXTFIELD": {
      return (
        <TextField
          id={`table-${data?.value}-TextField`}
          value={data?.value}
          placeholder={`Enter ${data?.placeholder}`}
          onChange={data.handleTextfield}
          error={data?.error}
        />
      );
    }
    case "STATUS": {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "center",
          }}
        >
          <div
          style={{
            height: "24px",
            backgroundColor: generateStatusBullet(data?.value),
            display: "inline-block",
            padding: "4px 3px 4px 0px",
            borderRadius: "4px",
          }}
        >
          <Typography
            sx={{
              color: statusColor(data?.value),
              fontSize: "11px",
              fontWeight: 500,
              // lineHeight: 1.14,
              letterSpacing: "0.42px",
              textAlign: "center",
              padding: "2px 2px 0px 4px ",
            }}
          >
            {data?.value.toUpperCase()}
          </Typography>
          </div>
          {data?.refreshIcon ? (
            <div onClick={data.handleStatusClick}>
              <RefreshIcon />
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
    case "VIEW": {
      return (
        <div onClick={(event) => { data.handleView(event, 'view') }}>
          <VisibilityIcon sx={{ color: "#03a5a5" }} />
        </div>
      );
    }
    case "DOWNLOAD": {
      return (
        <div style={{ paddingLeft: "55px" }} onClick={(event) => { data.handleView(event, 'download') }}>
          <DownloadIcon { ...DownloadIconProps} />
        </div>
      );
    }
    case "REMIND_STATUS": {
      return (
        <div onClick={data.handleStatusClick} style={{ paddingLeft: "12px" }}>
          <CommentIcon />
        </div>
      );
    }
    case "EXCHANGE_STATUS": {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <div
          style={{
            height: "24px",
            backgroundColor: generateStatusBullet(data?.value),
            display: "inline-block",
            padding: "4px 3px 4px 0px",
            borderRadius: "4px",
          }}
        >
          <Typography
            sx={{
              color: statusColor(data?.value),
              fontSize: "11px",
              fontWeight: 500,
              letterSpacing: "0.42px",
              textAlign: "center",
              padding: "2px 2px 0px 4px ",
            }}
          >
            {data?.value.toUpperCase()}
          </Typography>
          </div>
          {data?.exchangeStatusIcon === "refresh" ? (
            <div onClick={data.handleExchangeStatus}>
              <RefreshIcon width={18} height={18} />
            </div>
          ) : ( data?.exchangeStatusIcon === "info" ? 
            <div onClick={data.handleExchangeStatus}>
              <InfoIcon />
            </div> : ""
          )}
        </div>
      );
    }

    default: {
      return data.value;
    }
  }
};
