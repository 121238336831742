import {
    Grid,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Alert,
    AlertTitle,
    Autocomplete,
    TextField,
    Box,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Footer } from "../../../components";
  import { useNavigate } from "react-router-dom";
  import lensPower from "./lensPower";
  import { useDispatch, useSelector } from "react-redux";
  import {
    setPrescriptionData,
    setSelectedPrescription,
    preloadPrescriptionDataLeft,
    preloadPrescriptionDataRight,
    setShowCheckout,
  } from "../../../app/reducers/prescriptionSlice";
  import { clearCatalog } from "../../../app/reducers/lenscatalogSlice";
  import { setMultiSelectedProductsData } from "../../../app/reducers/librarySlice";
  import _ from "lodash";
  
  const AddPrescription = (props) => {
    const dispatch = useDispatch();
    const preSelectedPrescription = useSelector(
      (state) => state.prescriptionReducer.selectedPrescription
    );
    const preSelectedPrescriptionLeft = useSelector(
      (state) => state.prescriptionReducer.prescriptionDataLeft
    );
    const preSelectedPrescriptionRight = useSelector(
      (state) => state.prescriptionReducer.prescriptionDataRight
    );
  
    const lensType = _.isEmpty(preSelectedPrescription)
      ? "Single Vision"
      : preSelectedPrescription;
  
    const left = _.isEmpty(preSelectedPrescriptionLeft)
      ? {
          sph: "0.0",
          cyl: "0.0",
          axis: "0.0",
          add: "0.0",
          pd: "0.0",
        }
      : preSelectedPrescriptionLeft;
  
    const right = _.isEmpty(preSelectedPrescriptionRight)
      ? {
        sph: "0.0",
        cyl: "0.0",
        axis: "0.0",
        add: "0.0",
        pd: "0.0",
        }
      : preSelectedPrescriptionRight;
  
    const [addPres, setAddPres] = useState(true);
  
    const [lensTypeValue, setLensTypeValue] = useState(lensType);
    const [showAdd, setShowAdd] = useState(false);
    const [prescriptionDataLeft, setPrescriptionDataLeft] = useState(left);
    const [prescriptionDataRight, setPrescriptionDataRight] = useState(right);
    const [showAlert, setShowAlert] = useState(false);
    const productData = useSelector(
      (state) => state.libraryReducer.selectedProduct
    );
  
    const history = useNavigate();
    const style = {
      color: "#03A5A5",
    };
  
    const data = lensPower();
  
    useEffect(() => {
      if (lensTypeValue === "Bifocal" || lensTypeValue === "Progressive") {
        setShowAdd(true);
      } else {
        setShowAdd(false);
      }
    }, [lensTypeValue, preSelectedPrescriptionLeft]);
  
    const handleNewPrescription = (event) => {
      setAddPres(true);
    };
  
    const handleChange = (event) => {
      setLensTypeValue(event.target.value);
    };
  
    const handlePD = (event, header, eyeSide) => {
      if (eyeSide === "right") {
        setPrescriptionDataRight({
          ...prescriptionDataRight,
          [header]: event.target.value.toString(),
        });
        dispatch(
          preloadPrescriptionDataRight({
            ...prescriptionDataLeft,
            [header]: event.target.value.toString(),
          })
        );
      } else {
        setPrescriptionDataLeft({
          ...prescriptionDataLeft,
          [header]: event.target.value.toString(),
        });
        dispatch(
          preloadPrescriptionDataLeft({
            ...prescriptionDataLeft,
            [header]: event.target.value.toString(),
          })
        );
      }
    };
  
    const handleBackButton = () => {
      dispatch(setShowCheckout(true));
      history("/productDescription");
    };
  
    const handlePDPage = () => {
      setShowAlert(false);
      history("/productDescription");
    };
  
    const handleContinueButton = () => {
      
      let temp1 = { eye: "RE" };
      const tempObj1 = Object.assign({}, temp1, prescriptionDataRight);
  
      let temp = { eye: "LE" };
      const tempObj = Object.assign({}, temp, prescriptionDataLeft);
  
      var prescription = [tempObj1];
      prescription = [...prescription, tempObj];
      const data = {
        productId: productData.productId,
        isProduct: false,
        prescriptions: prescription,
      };
  
      if (lensType !== lensTypeValue) {
        dispatch(clearCatalog());
      }
  
      dispatch(setPrescriptionData(prescription));
      dispatch(setSelectedPrescription(lensTypeValue));
      dispatch(setMultiSelectedProductsData(data));
      history("/lens-catalog");
    };
  
    const handleNewValue = (newValues, header, eyeSide) => {
      if (eyeSide === "right") {
        setPrescriptionDataRight({
          ...prescriptionDataRight,
          [header]: newValues.value.toString(),
        });
        dispatch(
          preloadPrescriptionDataRight({
            ...prescriptionDataRight,
            [header]: newValues.value.toString(),
          })
        );
      } else {
        setPrescriptionDataLeft({
          ...prescriptionDataLeft,
          [header]: newValues.value.toString(),
        });
        dispatch(
          preloadPrescriptionDataLeft({
            ...prescriptionDataLeft,
            [header]: newValues.value.toString(),
          })
        );
      }
    };
  
    return (
      <>
        {showAlert ? (
          <div>
            <Alert
              severity="warning"
              color="warning"
              onClose={() => handlePDPage()}
            >
              {" "}
              <AlertTitle>Warning</AlertTitle>
              If you go back to previous page after checkout —{" "}
              <strong>Changes will be lost</strong> —{" "}
              <strong>Close alert to proceed</strong>
            </Alert>
          </div>
        ) : (
          ""
        )}
        <div style={{ padding: "80px 0px" }}>
          <Grid
            container
            sx={{ pt: "15px", pb: "20px" }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={8} sm={8} md={8} sx={{ pl: "0.8% !important" }}>
              <Typography
                sx={{
                  pb: "4px",
                  fontSize: 16,
                  display: "flex",
                  fontWeight: 600,
                  color: "#03A5A5",
                }}
              >
                ENTER PRESCRIPTION
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              sx={{ mb: "40px", paddingLeft: "17.5% !important" }}
            >
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  sx={{
                    fontSize: 13,
                    fontWeight: 500,
                    focused: false,
                    color: "#000",
                  }}
                >
                  CHOOSE LENS TYPE
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={lensTypeValue}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="Single Vision"
                    disabled={!addPres}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "#03A5A5",
                          "&.Mui-checked": {
                            color: "#03A5A5",
                          },
                        }}
                      />
                    }
                    label="Single Vision"
                  />
                  <FormControlLabel
                    value="Bifocal"
                    disabled={!addPres}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "#03A5A5",
                          "&.Mui-checked": {
                            color: "#03A5A5",
                          },
                        }}
                      />
                    }
                    label="Bifocal"
                  />
                  <FormControlLabel
                    value="Progressive"
                    disabled={!addPres}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "#03A5A5",
                          "&.Mui-checked": {
                            color: "#03A5A5",
                          },
                        }}
                      />
                    }
                    label="Progressive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {!addPres ? (
              <Grid item xs={4} sm={4} md={4} lg={4} sx={{ mb: "40px" }}>
                <div
                  style={{ color: "#ff6f00", fontSize: 16, fontWeight: 600 }}
                  onClick={handleNewPrescription}
                >
                  + New Prescription
                </div>
              </Grid>
            ) : (
              <Grid item xs={4} sm={4} md={4} lg={4} sx={{ mb: "40px" }}></Grid>
            )}
             <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              sx={{ p: "1%", mr: "1%" }}
            >
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={style}>RIGHT EYE</Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Box>
                  <Typography>SPH</Typography>
                </Box>
                <Autocomplete
                  id="RSPL"
                  options={data.spherical}
                  sx={{ width: "90%" }}
                  disabled={!addPres}
                  defaultValue={prescriptionDataRight?.sph}
                  onChange={(event, newValue) =>
                    handleNewValue(newValue, "sph", "right")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="0.00"
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                
              <Box>
                  <Typography>CYL</Typography>
                </Box>
                <Autocomplete
                  id="RCYL"
                  options={data.cylindrical}
                  sx={{ width: "90%" }}
                  disabled={!addPres}
                  defaultValue={prescriptionDataRight?.cyl}
                  onChange={(event, newValue) =>
                    handleNewValue(newValue, "cyl", "right")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="0.00"
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                
              <Box>
                  <Typography>AXIS</Typography>
                </Box>
                <Autocomplete
                  id="RAXIS"
                  options={data.axis}
                  sx={{ width: "90%" }}
                  disabled={!addPres}
                  defaultValue={prescriptionDataRight?.axis}
                  onChange={(event, newValue) =>
                    handleNewValue(newValue, "axis", "right")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="0.00"
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                
              <Box>
                  <Typography>PD</Typography>
                </Box>
                <TextField
                  placeholder="0.00"
                  disabled={!addPres}
                  sx={{ width: "90%" }}
                  defaultValue={prescriptionDataRight?.pd} 
                  inputProps={{
                    style: { textAlign: "right" },
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  InputLabelProps={{ shrink: true, disableAnimation: true }}
                  onChange={(event) => handlePD(event, "pd", "right")}
                />
              </Grid>
              {showAdd && (
                <Grid item xs={2} sm={2} md={2}>
                  
                <Box>
                  <Typography>ADD</Typography>
                </Box>
                  <Autocomplete
                    id="RADD"
                    options={data.add}
                    sx={{ width: "90%" }}
                    disabled={!addPres}
                    defaultValue={prescriptionDataRight?.add}
                    onChange={(event, newValue) =>
                      handleNewValue(newValue, "add", "right")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="0.00"
                        InputLabelProps={{ shrink: true, disableAnimation: true }}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              sx={{ p: "1%", mr: "1%" }}
            >
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={style}>LEFT EYE</Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Autocomplete
                  id="LSPH"
                  options={data.spherical}
                  defaultValue={prescriptionDataLeft?.sph}
                  disabled={!addPres}
                  sx={{ width: "90%" }}
                  onChange={(event, newValue) =>
                    handleNewValue(newValue, "sph", "left")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="0.00"
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Autocomplete
                  id="LCYL"
                  options={data.cylindrical}
                  sx={{ width: "90%" }}
                  disabled={!addPres}
                  defaultValue={prescriptionDataLeft?.cyl}
                  onChange={(event, newValue) =>
                    handleNewValue(newValue, "cyl", "left")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="0.00"
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <Autocomplete
                  id="LAXIS"
                  options={data.axis}
                  disabled={!addPres}
                  defaultValue={prescriptionDataLeft?.axis}
                  sx={{ width: "90%" }}
                  onChange={(event, newValue) =>
                    handleNewValue(newValue, "axis", "left")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="0.00"
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                <TextField
                  placeholder="0.00"
                  sx={{ width: "90%" }}
                  disabled={!addPres}
                  defaultValue={prescriptionDataLeft?.pd} 
                  inputProps={{
                    style: { textAlign: "right" },
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  onChange={(event) => handlePD(event, "pd", "left")}
                  InputLabelProps={{ shrink: true, disableAnimation: true }}
                />
              </Grid>
              {showAdd && (
                <Grid item xs={2} sm={2} md={2}>
                  <Autocomplete
                    id="LADD"
                    options={data.add}
                    sx={{ width: "90%" }}
                    disabled={!addPres}
                    defaultValue={prescriptionDataLeft?.add}
                    onChange={(event, newValue) =>
                      handleNewValue(newValue, "add", "left")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="0.00"
                        InputLabelProps={{ shrink: true, disableAnimation: true }}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Footer
              back={"Back"}
              handleBackButton={handleBackButton}
              handleContinueButton={handleContinueButton}
              onContinue={"Continue"}
            />
          </Grid>
        </div>
      </>
    );
  };
  
  export default AddPrescription;
  