import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  setStoreLogin,
  getStoreLogin,
  setErrors,
  getPermittedStore,
  setPermittedStore,
  setSelectedStoreId,
  getCurrentUser
} from "../reducers/storeLoginSlice";
import { API_URL } from "../../utils/constants";

function getLoginDataAPI(payload) {
  const apiURL = process.env.REACT_APP_API_URL + "/v1/accounts/auth/accounts/verify";
  const products = axios.post(apiURL, payload).then((response) => response);
  return products;
}

export function* getLoginData(action) {
  try {
    const { payload } = action;
    const response = yield call(getLoginDataAPI, payload);    
    localStorage.setItem("eyePlay-auth-token", response.data.data.tokens.token);
    localStorage.setItem("eyePlay-refresh-token", response.data.data.tokens.refreshToken); 
    yield put(setStoreLogin(response.data.data));
    yield put(getPermittedStore());
    yield put(getCurrentUser());
  } catch (error) {
    yield put(setErrors(error.response.data.message));
  }
}

function getStoreAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/store/permittedstores`;
  const products = axios
    .get(apiURL, { headers: headers })
    .then((response) => response.data.data);
  return products;
}

export function* getStore(action) {
  try {
    const { payload } = action;
    const response = yield call(getStoreAPI, payload);
    let modifiedResponseArr = [];
    const modifiedResponse = response.map((data) => {
       const obj = {
         storeName: data?.name,
         storeId: data?.storeId
       }
       modifiedResponseArr.push(obj);
    })
    yield put(setSelectedStoreId(modifiedResponseArr[0]));
    yield put(setPermittedStore(modifiedResponseArr));
  } catch (error) {
    yield put(setErrors(error.response.data));
  }
}

function getUserAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const apiURL = `${API_URL}/client/user/currentuserinfo`;
  const products = axios
    .get(apiURL, { headers: headers })
    .then((response) => response.data.data);
  return products;
}

export function* getUser(action) {
  try {
    const { payload } = action;
    const response = yield call(getUserAPI, payload);
    localStorage.setItem("user-name", response.name);  
  } catch (error) {
    yield put(setErrors(error.response.data));
  }
}


export function* storeLoginSaga() {
  yield takeLatest(getStoreLogin.type, getLoginData);
  yield takeLatest(getPermittedStore.type, getStore);
  yield takeLatest(getCurrentUser.type, getUser);
}
