import React, { useEffect, useState } from "react";
import {
  Dialog,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Autocomplete,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { setMultiSelectedProductsData } from "../../../app/reducers/librarySlice";
import { setSelectedLensCatalog, getGlassDropDowns } from "../../../app/reducers/lenscatalogSlice";
import CloseIcon from "@mui/icons-material/Close";
import { HeadTypography, LabelTypography, useStyles } from "./style";
import { ContinueButton } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const OtherBrandModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();

  const brand = useSelector(
    (state) => state.lenscatalogReducer?.brandNames.filter((data) => data !== "Regular")
  );
  
  const dropDown = useSelector((state) => state.lenscatalogReducer.glassDropDown);
  
  useEffect(() => {
    dispatch(getGlassDropDowns());
  },[dispatch])
  const [brandOptions, setBrandOptions] = useState(brand);
  const [inclGst, setInclusiveGst] = useState(false);
  const [value, setValue] = useState({});

  const handleChange = (event, newValue, name) => {
    if (name === "BrandCustom") {
      setValue({ ...value, brand: event.target.value });
    } else if (name === "inclGst") {
      setInclusiveGst(event.target.value === "true" ?? false);
    } else {
      if (newValue) {
        setValue({ ...value, [name]: newValue });
      } else {
        setValue({ ...value, [name]: event.target.value });
      }
    }
  };


  const handleBrand = (value) => {
    setBrandOptions([...brandOptions, value]);
  };

  const handleConfirm = () => {
    alert(value.brandType)
    const data = {
      productId: props?.productData.productId,
      isProduct: false,
      lensItems: {
        //lensId: selectedIndex?._id,
        lensType: props?.selectedPrescription.toUpperCase(),
        lensCoating: value?.lensCoating,
        index: value?.index,
        price: value?.costPrice,
        brandType: value?.brandType,
        brand: value?.brand,
        includingGst: inclGst,
      },
    };

    const dataSelected = {
      productId: props?.productData.productId,
      isProduct: false,
      lensItems: {
        //lensId: selectedIndex?._id,
        lensType: props?.selectedPrescription.toUpperCase(),
        lensCoating: value?.lensCoating,
        index: value?.index,
        price: value?.costPrice,
        brand: value?.BrandCustom,
        brandType: value?.brandType,
        includingGst: inclGst,
      },
      lensCategory: _.isEmpty(props?.selectedPrescription)
        ? ""
        : props?.selectedPrescription === "Progressive"
        ? ""
        : "",
    };
    dispatch(setSelectedLensCatalog(dataSelected));
    dispatch(setMultiSelectedProductsData(data));
    history("/frame-orientation");
  };
  return (
    <Dialog open={props?.open} onClose={props?.handleClose} maxWidth={false}>
      <div
        sx={{
          width: "70%",
        }}
      >
        <div className={classes?.alignFlex}>
          <HeadTypography sx={{ marginLeft: "2%" }}>Choose Lens Catalog</HeadTypography>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={props.handleClose} />
        </div>
        <Grid
          container
          sx={{
            height: "370px",
            padding: "20px",
            marginLeft: "2%"
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <LabelTypography>BRAND</LabelTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={brandOptions}
                value={value?.brand}
                placeholder="Select"
                sx={{ width: "90%" }}
                onChange={(event, newValue) => {
                  handleChange(event, newValue, "brand");
                }}
                size="medium"
                renderInput={(params) => (
                  <TextField
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.target.value) {
                        handleChange(e, "", "BrandCustom");
                        handleBrand(e.target.value);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5}>
              <LabelTypography>MODEL</LabelTypography>
              <TextField
                fullWidth
                type="text"
                onChange={(event) => handleChange(event, "", "model")}
                placeholder="type here"
                variant="outlined"
                size="medium"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
            >
              <LabelTypography>COATING</LabelTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="medium"
                sx={{ width: "90%" }}
                options={dropDown?.LensCoating}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "lensCoating")
                }
                placeholder="Select"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <LabelTypography>INDEX</LabelTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={dropDown?.Index}
                placeholder="Select"
                sx={{ width: "83%" }}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "index")
                }
                size="medium"
                renderInput={(params) => (
                  <TextField
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container md={12} ls={12} sx={{ maxHeight: "10%" }}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
              <LabelTypography>BRAND TYPE</LabelTypography>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={['Inhouse', 'Branded']}
                placeholder="Select"
                sx={{ width: "83%" }}
                onChange={(event, newValue) =>
                  handleChange(event, newValue, "brandType")
                }
                size="medium"
                renderInput={(params) => (
                  <TextField
                    sx={{ backgroundColor: "#fff", borderRadius: "25px" }}
                    {...params}
                    placeholder="Select"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <LabelTypography>PRICE</LabelTypography>
              <TextField
                fullWidth
                type="text"
                placeholder="0.00"
                variant="outlined"
                size="medium"
                sx={{ width: "90%" }}
                onChange={(event) => handleChange(event, "", "costPrice")}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={3}>
              <Typography sx={{ marginTop: "13%", marginLeft: "25%" }}>INCLUSIVE GST ?</Typography>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <RadioGroup
                sx={{ marginTop: "10%" }}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={inclGst}
                onChange={(event) => handleChange(event, "", "inclGst")}
                row
              >
                <FormControlLabel
                  value="true"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "#03A5A5",
                        "&.Mui-checked": {
                          color: "#03A5A5",
                        },
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "#03A5A5",
                        "&.Mui-checked": {
                          color: "#03A5A5",
                        },
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "5%", maxHeight: "10%" }}>
            <Grid item sm={5} md={5} lg={5}>
              <ContinueButton onClick={handleConfirm}>Confirm</ContinueButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default OtherBrandModal;
