import React from "react";
import {  Box, Divider } from "@mui/material";

const CheckoutHeader = (props) => {
  return (
    <div>
      <Box
        sx={{
          p: "16px",
          fontSize: 16,
          display: "flex",
          fontWeight: 650,
          color: "#03A5A5",
          letterSpacing: "0.4px",
        }}
      >
        {props.header}
      </Box>
      <Divider/>
    </div>
  );
};

export default CheckoutHeader;
