import { createSlice } from "@reduxjs/toolkit";
import _,{ isEmpty } from "lodash";

const initialState = {
  invoiceDetails: [],
  pageNoInvoice: 0,
  hasMoreInvoice: true,
  searchDataInvoice: ""
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    getInvoiceData() {},
    setInvoiceData(state, action) {
      return Object.assign({}, state, {
        invoiceDetails:
          state.pageNoInvoice > 0
            ? [...state.invoiceDetails, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreInvoice: action.payload.length < 15 ? false : true,
      });
    },
    setPagenoInvoice(state, action) {
      return Object.assign({}, state, {
        pageNoInvoice: action.payload.page
      });
    },
    setSearchDataInvoice(state, action) {
      return Object.assign({}, state, {
        searchDataInvoice: _.isEmpty(action.payload) ? "" : action.payload
      });
    },

    clearInvoice(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getInvoiceData,
  setInvoiceData,
  clearInvoice,
  setPagenoInvoice,
  setSearchDataInvoice
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
