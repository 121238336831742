import { createSlice } from "@reduxjs/toolkit";
import _,{ isEmpty } from "lodash";

const initialState = {
  remindersList: [],
  pageNoReminders: 0,
  hasMoreReminders: true,
  searchDataReminders: "",
  reminderStatusSuccess: {},
  reminderStatusError: "",
  removReminderStatusSuccess: {},
  removReminderStatusError: ""
};

export const reminderSlice = createSlice({
  name: "reminder",
  initialState,
  reducers: {
    getremindersList() {},
    setremindersList(state, action) {
      return Object.assign({}, state, {
        remindersList:
          state.pageNoReminders > 0
            ? [...state.remindersList, ...action.payload]
            : isEmpty(action.payload)
            ? []
            : action.payload,
        hasMoreReminders: action.payload.length < 15 ? false : true,
      });
    },
    addReminderStatus() {},
    addReminderStatusSuccess(state, action) {
      return Object.assign({}, state, {
        reminderStatusSuccess: action.payload,
      });
    },
    addReminderStatusError(state, action) {
      return Object.assign({}, state, {
        reminderStatusError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },

    removeReminderStatus() {},
    removeReminderStatusSuccess(state, action) {
      return Object.assign({}, state, {
        removReminderStatusSuccess: action.payload,
      });
    },
    removeReminderStatusError(state, action) {
      return Object.assign({}, state, {
        removReminderStatusError: Array.isArray(action.payload) ? action.payload[0].msg : action.payload,
      });
    },


    setPagenoReminders(state, action) {
      return Object.assign({}, state, {
        pageNoReminders: action.payload,
      });
    },
    setSearchDataReminders(state, action) {
      return Object.assign({}, state, {
        searchDataReminders: _.isEmpty(action.payload) ? "" : action.payload
      });
    },
    clearReminders(state, action) {
      return Object.assign({}, state, initialState);
    },
  },
});

export const {
  getremindersList,
  setremindersList,
  clearReminders,
  setPagenoReminders,
  setSearchDataReminders,
  addReminderStatus,
  addReminderStatusSuccess,
  addReminderStatusError,
  removeReminderStatus,
  removeReminderStatusSuccess,
  removeReminderStatusError
} = reminderSlice.actions;

export default reminderSlice.reducer;
