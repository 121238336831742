import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getExchangeData,
  setExchangeData,
  getShipmentUpdate,
  confirmDelivery,
  getInventoryWithBarecode,
  setInventoryWithBarecode,
  setInventories,
  setSaveExchange,
  getSaveExchange,
  confirmDeliverySuccess,
  shipmentUpdateSuccess
} from "../../app/reducers/exchangeSlice";
import { API_URL } from "../../utils/constants";

async function fetchExchangeAPI(payload) {
  const apiURL = `${API_URL}/client/stocktransfer/fetchstocktransfer`;
  const storeId = localStorage.getItem("storeId");
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const exchanges = axios
    .get(apiURL, {
      params: {
        limit: 15,
        page: payload.page,
        storeId: storeId,
        search: "",
        filter: {},
        sort: { _created_at: -1 },
      },
      headers: headers,
    })
    .then((response) => response.data.data);
  return exchanges;
}
export function* fetchExchange(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchExchangeAPI, payload);
    yield put(setExchangeData(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function shipmentUpdateAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/stocktransfer/updateshippingstatus`;
  const exchanges = axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response.data.data);
  return exchanges;
}

export function* shipmentUpdate(action) {
  try {
    const { payload } = action;
    const response = yield call(shipmentUpdateAPI, payload);
    if (response) {
      yield put(shipmentUpdateSuccess(response));
    }
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function deliveryConfirmationAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/stocktransfer/updatedeliverystatus`;
  const exchanges = axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response.data.data);
  return exchanges;
}

export function* deliveryConfirmation(action) {
  try {
    const { payload } = action;
    const response = yield call(deliveryConfirmationAPI, payload);
    if (response) {
      yield put(confirmDeliverySuccess(response));
    }
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function getInventoryAPI(payload) {
  const storeId = localStorage.getItem("storeId");
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/inventory/barcodeid`;
  const exchanges = axios
    .get(apiURL, {
      params: {
        storeId: storeId,
        barcodeId: payload,
      },
      headers: headers,
    })
    .then((response) => response.data.data);
  return exchanges;
}

export function* getInventory(action) {
  try {
    const { payload } = action;
    const response = yield call(getInventoryAPI, payload);
    yield put(setInventoryWithBarecode(response));
    yield put(setInventories(response));
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

async function saveExchangeAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  };
  const apiURL = `${API_URL}/client/stocktransfer/store`;
  const exchanges = axios
    .post(apiURL, payload, { headers: headers })
    .then((response) => response.data.data);
  return exchanges;
}

export function* saveExchange(action) {
  try {
    const { payload } = action;
    const response = yield call(saveExchangeAPI, payload);
    yield put(setSaveExchange());
  } catch (error) {
    yield put({ type: "ERRORS", payload: error });
  }
}

export function* exchangeSaga() {
  yield takeLatest(getExchangeData.type, fetchExchange);
  yield takeLatest(getShipmentUpdate.type, shipmentUpdate);
  yield takeLatest(confirmDelivery.type, deliveryConfirmation);
  yield takeLatest(getInventoryWithBarecode.type, getInventory);
  yield takeLatest(getSaveExchange.type, saveExchange);
}
