import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
  getReportData,
  getReportDataSuccess,
  getReportDataError,
  getReportDatav2,
  getReportDataSuccessv2,
  getReportDataErrorv2
} from "../reducers/reportSlice"
import { API_URL } from "../../utils/constants";

async function fetchReportDataAPI(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const storeId = localStorage.getItem("storeId");
  const apiURL = `${API_URL}/client/reports/eod`;
  const userData = await axios
    .get(apiURL, {
      params: {
        limit: 15,
        searchDate: payload.date,
        storeId: storeId,
      },
      headers: headers
    })
    .then((response) => response);
  return userData;
}

async function fetchReportDataAPIv2(payload) {
  const headers = {
    "oic-authorization": localStorage.getItem("eyePlay-auth-token"),
    "oic-refresh-token": localStorage.getItem("eyePlay-refresh-token"),
  }
  const storeId = localStorage.getItem("storeId");
  const apiURL = `${API_URL}/client/reports/eodv2`;
  const userData = await axios
    .get(apiURL, {
      params: {
        searchDate: new Date(),
        storeId,
      },
      headers: headers
    })
    .then((response) => response);
  return userData;
}

export function* fetchReportData(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchReportDataAPI, payload);
    yield put(getReportDataSuccess(response.data.data));
  } catch (error) {
    yield put(getReportDataError(error.response.data.message[0].msg));
  }
}

export function* fetchReportDatav2(action) {
  try {
    const { payload } = action;
    const response = yield call(fetchReportDataAPIv2, payload);
    yield put(getReportDataSuccessv2(response.data.data));
  } catch (error) {
    yield put(getReportDataErrorv2(error.response.data.message[0].msg));
  }
}

export function* reportSaga() {
  yield takeLatest(getReportData.type, fetchReportData);
  yield takeLatest(getReportDatav2.type, fetchReportDatav2);
}
